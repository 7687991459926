import { css, useTheme } from '@emotion/react';
import hexToRgba from 'hex-to-rgba';
import { useRef, useState, type PropsWithChildren } from 'react';
import { Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';

import Button from 'shared/components/Button';
import useElementWidth from 'shared/hooks/useElementWidth';
import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import Flex from 'shared/components/Flex';

type Item = {
  label: string;
  onAction: () => void;
};

type Props = PropsWithChildren<{
  isDisabled?: boolean;
  isMenuDisabled?: boolean;
  items: Item[];
  onPress: () => void;
}>;

const ButtonMenu = ({
  items,
  isDisabled,
  isMenuDisabled,
  onPress,
  children,
}: Props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const triggerElementRef = useRef<HTMLButtonElement>(null);
  const triggerWidth = useElementWidth(triggerElementRef);

  return (
    <Flex>
      <Button
        variant={'outlined'}
        onPress={onPress}
        isDisabled={isDisabled}
        css={css`
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `}
      >
        {children}
      </Button>

      <MenuTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
        <Button
          ref={triggerElementRef}
          variant={'outlined'}
          isDisabled={isMenuDisabled}
          hasPadding={false}
          css={css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;

            svg {
              width: 10px;
            }
          `}
        >
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>

        <Popover offset={4} placement={'bottom end'}>
          <Menu
            css={css`
              background-color: ${theme.color.white};
              border-radius: 8px;
              border: 1px solid ${theme.color.neutral3};
              overflow: hidden;
              box-shadow: 0 0 12px 8px ${hexToRgba(theme.color.black, 0.06)};
              min-width: ${triggerWidth}px;
            `}
          >
            {items.map((item, index) => (
              <MenuItem
                onAction={item.onAction}
                key={`${index}-${item.label}`}
                css={css`
                  padding: 0.625rem 1rem;
                  cursor: pointer;

                  &:hover {
                    background-color: ${theme.color.backgroundLight};
                  }
                `}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Popover>
      </MenuTrigger>
    </Flex>
  );
};

export default ButtonMenu;
