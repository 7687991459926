import type { TFunction } from 'react-i18next';

import type { StrategyAssessmentType } from 'types.graphql.generated';

export function getAssessmentTypeTitle(
  t: TFunction,
  type: StrategyAssessmentType,
): string {
  return t(`assessment.type.${type}.title`);
}

export function getAssessmentTypeDescription(
  t: TFunction,
  type: StrategyAssessmentType,
): string {
  return t(`assessment.type.${type}.description`);
}
