import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';

import { useDeactivateUserMutation } from './DeactivateUser.graphql.generated';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
};

const DeactivateUserDialog = ({ isOpen, onClose, userId }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleError();

  const [deactivateUser] = useDeactivateUserMutation({
    variables: { userId },
    onError,
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('userMenu.deactivate.success'),
      }),
  });

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={deactivateUser}
    >
      {t('userMenu.deactivate.cofirmation')}
    </ConfirmationDialog>
  );
};

export default DeactivateUserDialog;
