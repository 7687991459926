import { useTranslation } from 'react-i18next';

import { enumerate } from 'shared/utils/string.utils';
import { useDeleteObjectiveMutation } from 'objective/objective.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';
import Spinner from 'shared/spinner/Spinner';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';

import { useDeleteObjectiveConfirmationModalQuery } from './DeleteObjectiveConfirmationModal.graphql.generated';

type Props = {
  isOpen: boolean;
  objectiveId: string;
  onClose: () => void;
  onDelete?: () => void;
};

const DeleteObjectiveConfirmationModal = ({
  objectiveId,
  onDelete,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { data } = useDeleteObjectiveConfirmationModalQuery({
    variables: { objectiveId },
    skip: !isOpen,
  });

  const onError = useHandleError();

  const [deleteObjective] = useDeleteObjectiveMutation({
    variables: { objectiveId },
    update(cache) {
      setTimeout(() => cache.evict({ id: `Objective:${objectiveId}` }), 1000);
    },
    onCompleted: () => {
      onDelete?.();
      addToast({
        variant: 'success',
        children: t('strategy.toasts.removeSuccessToast'),
      });
    },
    onError,
  });

  const objective = data?.objective;

  const hasMetrics = objective && objective.metrics.length > 0;
  const hasInitiatives = objective && objective.initiatives.length > 0;
  const hasActions = objective && objective.actions.length > 0;

  const hasChildItems = hasMetrics || hasInitiatives || hasActions;

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={deleteObjective}
    >
      {objective ? (
        hasChildItems ? (
          t('objective.objectiveLayout.deleteConfirmation', {
            childElements: enumerate(t, [
              hasMetrics &&
                t('count.metric', {
                  count: objective.metrics.length,
                }),
              hasInitiatives &&
                t('count.initiative', {
                  count: objective.initiatives.length,
                }),
              hasActions &&
                t('count.action', {
                  count: objective.actions.length,
                }),
            ]).join(''),
          })
        ) : (
          t('objective.objectiveLayout.deleteConfirmationNoChildElements')
        )
      ) : (
        <Spinner.Circle />
      )}
    </ConfirmationDialog>
  );
};

export default DeleteObjectiveConfirmationModal;
