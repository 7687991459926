import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { startOfDay } from 'date-fns';
import { v4 as uuid } from 'uuid';

import Dialog from 'shared/components/Dialog';
import { useUser } from 'user/UserProvider';
import type {
  Objective,
  ObjectiveInput,
  OrgUnit,
  Theme,
} from 'types.graphql.generated';
import {
  StrategyObjectivesOrgDocument,
  StrategyObjectivesOrgUnitDocument,
} from 'strategy/StrategyObjectives/StrategyObjectivesProvider';
import { ObjectiveDocument } from 'objective/ObjectiveProvider';
import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import { resolveInitialValue as resolveSelectThemeInputInitialValue } from 'objective/SelectThemeInput';
import { StrategyRoomThemesManageObjectivesDocument } from 'strategy/StrategyRoomThemesManageObjectives/StrategyRoomThemesManageObjectives.graphql.generated';
import StrategyProfileAttributesProvider, {
  useStrategyProfileAttributes,
} from 'strategy/StrategyProfileAttributesProvider';
import Spinner from 'shared/spinner/Spinner';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import TextButton from 'shared/components/TextButton';
import ResetFormOnChange from 'shared/form/ResetFormOnChange';
import ObjectiveFormModal from 'objective/EditObjective/ObjectiveFormModal/ObjectiveFormModal';
import useHandleError from 'shared/errors/useHandleError';
import useDefaultEndDate from 'shared/hooks/useDefaultEndDate';
import { SelectObjectiveInputProviderDocument } from 'objective/SelectObjectiveInput/SelectObjectiveInputProvider/SelectObjectiveInputProvider.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { ObjectiveRelatedObjectivesDocument } from 'objective/ObjectiveRelatedObjectives/ObjectiveRelatedObjectivesProvider/ObjectiveRelatedObjectivesProvider.graphql.generated';
import StrategyProvider from 'strategy/StrategyProvider';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';
import useCanPerformOrgUnitAction from 'user/ability/useCanPerformOrgUnitAction';

import ObjectiveQuickFormFields from './ObjectiveQuickFormFields';
import { useAddObjectiveMutation } from './AddObjective.graphql.generated';
import type { ObjectiveFormValues } from '../../EditObjective/ObjectiveForm';
import ObjectiveForm from '../../EditObjective/ObjectiveForm';
import ObjectiveCreatedDialog from './ObjectiveCreatedDialog';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  parentObjective?: Pick<Objective, 'id' | 'name' | 'timeLine'> & {
    orgUnit?: Pick<OrgUnit, 'id' | 'name'>;
    theme?: Pick<Theme, 'id' | 'name'>;
  };
  showSuccessDialog?: boolean;
  theme?: Pick<Theme, 'id' | 'name'>;
};

const CreateObjective = ({
  isOpen,
  onClose,
  parentObjective,
  theme,
  showSuccessDialog,
}: Props) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const formId = useMemo(uuid, []);

  const { orgUnit } = useOrgUnit();
  const { user } = useUser();

  const [isMaximized, setIsMaximized] = useState(false);

  const [isObjectiveCreatedDialogOpen, setIsObjectiveCreatedDialogOpen] =
    useState(false);
  const [createdObjective, setCreatedObjective] = useState<
    Pick<Objective, 'id' | 'name'> | undefined
  >();

  const handleApolloError = useHandleError();

  const [addObjective, { loading }] = useAddObjectiveMutation({
    onError: handleApolloError,
    refetchQueries: [
      StrategyObjectivesOrgDocument,
      StrategyObjectivesOrgUnitDocument,
      ObjectiveDocument,
      StrategyRoomThemesManageObjectivesDocument,
      SelectObjectiveInputProviderDocument,
      ObjectiveRelatedObjectivesDocument,
    ],
  });

  const { attributes, isAttributesLoading } = useStrategyProfileAttributes();

  const userHasAccessToOrg = useCanPerformOrgAction('WRITE_STRATEGY_ELEMENT');

  const userHasAccessToOrgUnit = useCanPerformOrgUnitAction(
    'WRITE_STRATEGY_ELEMENT',
  );

  const initialOrgUnit = orgUnit
    ? userHasAccessToOrgUnit
      ? orgUnit
      : user.orgUnit
    : userHasAccessToOrg
    ? null
    : user.orgUnit;

  const defaultEndDate = useDefaultEndDate(parentObjective);

  const initialValues: ObjectiveFormValues = useMemo(
    () => ({
      id: null,
      name: '',
      description: '',
      owner: user.email,
      parentObjective: resolveSelectObjectiveInputInitialValue(parentObjective),
      theme: resolveSelectThemeInputInitialValue(
        theme || parentObjective?.theme,
      ),
      priority: null,
      timeLine: {
        startDate: startOfDay(new Date()),
        endDate: defaultEndDate,
      },
      attributes:
        attributes?.map((attribute) => ({
          name: attribute.name || '',
          title: attribute.title || '',
          description: '',
        })) || [],
      orgUnit: initialOrgUnit
        ? { value: { id: initialOrgUnit.id, name: initialOrgUnit.name } }
        : {},
    }),
    [attributes, defaultEndDate, initialOrgUnit, parentObjective, theme, user],
  );

  const handleClose = () => {
    onClose();
    setIsMaximized(false);
  };

  const handleSubmit = async (input: ObjectiveInput) => {
    const result = await addObjective({
      variables: { input },
    });

    if (result.data?.addObjective && !result.errors) {
      handleClose();
      setCreatedObjective(result.data?.addObjective);

      if (showSuccessDialog) {
        setIsObjectiveCreatedDialogOpen(true);
      } else {
        addToast({
          variant: 'success',
          children: t('objective.createObjectiveSuccessModal.heading'),
        });
      }
    }
  };

  if (isAttributesLoading) return <Spinner.Circle />;

  return (
    <>
      <ObjectiveForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        formId={formId}
      >
        <ResetFormOnChange value={isOpen} />

        <Dialog
          isOpen={isOpen && !isMaximized}
          onClose={handleClose}
          onMaximize={() => setIsMaximized(true)}
          headerTitle={t('objective.createObjective.heading')}
          confirmLabel={t('create')}
          cancelLabel={t('cancel')}
          formId={formId}
          isConfirmDisabled={loading}
        >
          <ObjectiveQuickFormFields />
          <TextButton onPress={() => setIsMaximized(true)}>
            {t('objective.objectiveForm.addMoreDetails')}
          </TextButton>
        </Dialog>

        <ObjectiveFormModal
          headerTitle={t('objective.createObjective.heading')}
          confirmLabel={t('create')}
          isOpen={isOpen && isMaximized}
          onClose={handleClose}
          animation={'zoom'}
          formId={formId}
          isConfirmDisabled={loading}
        />
      </ObjectiveForm>

      <ObjectiveCreatedDialog
        isOpen={isObjectiveCreatedDialogOpen}
        onClose={() => setIsObjectiveCreatedDialogOpen(false)}
        objective={createdObjective}
      />
    </>
  );
};

export default (props: Props) => (
  <StrategyProvider>
    <StrategyProfileAttributesProvider types={['OBJECTIVE']}>
      <CreateObjective {...props} />
    </StrategyProfileAttributesProvider>
  </StrategyProvider>
);
