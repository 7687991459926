import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import ThemeMultiSelect from 'themes/ThemeMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import Flex from 'shared/components/Flex';
import DatePicker from 'shared/components/DatePicker';
import CollaboratingTeamsFilter from 'shared/components/CollaboratingTeamsFilter';

import { useSelectableStrategyItems } from './SelectableStrategyItemsProvider/useSelectableStrategyItems';

const SelectableStrategyItemsFilters = () => {
  const { t } = useTranslation();

  const {
    filter: {
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
    },
  } = useSelectableStrategyItems();

  return (
    <>
      <ThemeMultiSelect />
      <StatusMultiSelect />
      <PriorityMultiSelect />
      <UserMultiSelect />
      <Flex gap={'1rem'}>
        <DatePicker
          value={startDateFilter}
          onChange={setStartDateFilter}
          labelPrefix={t('from')}
          placeholder={t('allDates')}
          maxDate={endDateFilter}
          css={css({ width: '100%', flexGrow: 1 })}
          grow={true}
        />
        <DatePicker
          value={endDateFilter}
          onChange={setEndDateFilter}
          labelPrefix={t('to')}
          placeholder={t('allDates')}
          minDate={startDateFilter}
          grow={true}
        />
      </Flex>
      <CollaboratingTeamsFilter />
    </>
  );
};

export default SelectableStrategyItemsFilters;
