import type { PropsWithChildren } from 'react';

import InfoIcon from 'shared/components/InfoIcon';

import FormContainer from './FormContainer';
import FormContainerHeader from './FormContainerHeader';
import FormContainerBody from './FormContainerBody';

type Props = PropsWithChildren<{
  infoContent: string;
  isOptional?: boolean;
  title: string;
}>;

const InfoFormContainer = ({
  title,
  infoContent,
  isOptional,
  children,
}: Props) => {
  return (
    <FormContainer>
      <FormContainerHeader title={title} isOptional={isOptional}>
        <InfoIcon content={infoContent} size={'big'} />
      </FormContainerHeader>

      <FormContainerBody $hasContentPadding={true}>
        {children}
      </FormContainerBody>
    </FormContainer>
  );
};

export default InfoFormContainer;
