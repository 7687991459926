import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import sample from 'lodash/sample';

import type { StrategyWizardProps } from 'strategy/StrategyWizard';
import StrategyWizard from 'strategy/StrategyWizard';
import { useToasts } from 'shared/toast/useToasts';
import Tag from 'shared/components/__DEPRECATED__/Tag';
import Space from 'shared/components/Space';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import type { Strategy } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';

import EditStrategyWizardProvider, {
  useEditStrategyWizard,
} from './EditStrategyWizardProvider';
import styles from './EditStrategyWizard.module.scss';
import {
  resolveInitialValues,
  resolveEditStrategyInput,
} from './EditStrategyWizard.utils';
import {
  useUpdateStrategyBehaviorsMutation,
  useUpdateStrategyChoicesMutation,
  useUpdateStrategyDetailsMutation,
  useUpdateStrategyGoalsMutation,
  useUpdateStrategyMissionMutation,
  useUpdateStrategyValuesMutation,
  useUpdateStrategyVisionMutation,
} from './EditStrategyWizard.graphql.generated';

export type EditStrategyWizardProps = Omit<
  StrategyWizardProps,
  'onSubmit' | 'renderHeading' | 'hasActiveCampaign'
> & {
  onSuccess: () => void;
  strategy: Pick<Strategy, 'id'>;
};

const EditStrategyWizard = ({
  onSuccess,
  onClose,
  ...restProps
}: EditStrategyWizardProps) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { strategy } = useEditStrategyWizard();

  const onError = useHandleError();

  const [updateStrategyDetails] = useUpdateStrategyDetailsMutation({ onError });
  const [updateStrategyMisson] = useUpdateStrategyMissionMutation({ onError });
  const [updateStrategyVision] = useUpdateStrategyVisionMutation({ onError });
  const [updateStrategyGoals] = useUpdateStrategyGoalsMutation({ onError });
  const [updateStrategyChoices] = useUpdateStrategyChoicesMutation({ onError });
  const [updateStrategyBehaviors] = useUpdateStrategyBehaviorsMutation({
    onError,
  });
  const [updateStrategyValues] = useUpdateStrategyValuesMutation({ onError });

  const mutationByStepId = useMemo(
    () => ({
      name: updateStrategyDetails,
      mission: updateStrategyMisson,
      vision: updateStrategyVision,
      goals: updateStrategyGoals,
      choices: updateStrategyChoices,
      behaviors: updateStrategyBehaviors,
      values: updateStrategyValues,
    }),
    [
      updateStrategyBehaviors,
      updateStrategyChoices,
      updateStrategyDetails,
      updateStrategyGoals,
      updateStrategyMisson,
      updateStrategyValues,
      updateStrategyVision,
    ],
  );

  const initialValues = useMemo<StrategyWizardProps['initialValues']>(
    () => resolveInitialValues(strategy),
    [strategy],
  );

  const [isCloseConfirmationModalOpened, setIsCloseConfirmationModalOpened] =
    useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleSubmit: StrategyWizardProps['onSubmit'] = async (
    stepId,
    values,
  ) => {
    if (hasUnsavedChanges) {
      const mutation = mutationByStepId[stepId];

      await mutation?.({
        variables: { input: resolveEditStrategyInput({ values, strategy }) },
        onCompleted: () => {
          addToast({
            variant: 'success',
            children: sample(
              t('strategy.editStrategyWizard.successToast', {
                returnObjects: true,
              }),
            ),
          });
          onSuccess();
        },
      });
    } else {
      onSuccess();
    }
  };

  const handleChangeStep = useCallback<
    NonNullable<StrategyWizardProps['onChangeStep']>
  >(
    async (stepId, values) => {
      if (hasUnsavedChanges) {
        const mutation = mutationByStepId[stepId];

        await mutation({
          variables: { input: resolveEditStrategyInput({ values, strategy }) },
          onCompleted: () => setHasUnsavedChanges(false),
        });
      }
    },
    [hasUnsavedChanges, mutationByStepId, strategy],
  );

  const handleOnUnsavedChanges = useCallback<
    NonNullable<StrategyWizardProps['onUnsavedChanges']>
  >((hasUnsavedChanges) => {
    setHasUnsavedChanges(hasUnsavedChanges);
  }, []);

  const tagContent = strategy.isComplete
    ? 'completed'
    : strategy.isLive
    ? 'live'
    : 'draft';
  const tagVariant =
    strategy.isComplete || strategy.isLive ? 'primary' : 'regular';

  const renderHeading: StrategyWizardProps['renderHeading'] = ({ name }) => (
    <Space isCentered={true} className={styles.heading}>
      {t('strategy.editStrategyWizard.heading', {
        name: name || strategy.name,
      })}
      <Tag variant={tagVariant}>{t(`strategy.${tagContent}`)}</Tag>
      <EditIcon
        title={'Unsaved changes'}
        className={cn(styles.headingIcon, {
          [styles.headingIconVisible]: hasUnsavedChanges,
        })}
      />
    </Space>
  );

  const hasActiveCampaign = strategy.campaigns.some(
    (campaign) => campaign.state.stage === 'ACTIVE',
  );

  return (
    <>
      <StrategyWizard
        {...restProps}
        onClose={() => {
          if (hasUnsavedChanges) {
            setIsCloseConfirmationModalOpened(true);
          } else {
            onClose();
          }
        }}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChangeStep={handleChangeStep}
        onUnsavedChanges={handleOnUnsavedChanges}
        renderHeading={renderHeading}
        hasActiveCampaign={hasActiveCampaign}
      />

      <ConfirmationDialog
        isOpen={isCloseConfirmationModalOpened}
        onClose={() => setIsCloseConfirmationModalOpened(false)}
        onConfirm={onClose}
      >
        {t('strategy.editStrategyWizard.confirmationModal.content')}
      </ConfirmationDialog>
    </>
  );
};

const EditStrategyWizardWithProvider = (props: EditStrategyWizardProps) => (
  <EditStrategyWizardProvider strategy={props.strategy}>
    <EditStrategyWizard {...props} />
  </EditStrategyWizardProvider>
);

export default EditStrategyWizardWithProvider;
