import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { contributionsRoutes } from 'contribution/contribution.routing';
import { authRoutes } from 'auth/auth.routing';
import { routesPaths } from 'App.routing.paths';
import { teamRoutes } from 'team/team.routing';
import { toolkitRoutes } from 'toolkit/toolkit.routing';
import AuthGuard from 'auth/AuthGuard';
import TeamsRoot from 'team/TeamsRoot';
import ErrorPageProvider from 'shared/errors/ErrorPage/ErrorPageProvider';
import UserTenantsProvider from 'user/UserTenantsProvider';
import InitialOrgKeyResolver from 'org/InitialOrgKeyResolver';
import AuthExpiration from 'auth/AuthExpiration';

import EnvPage from './EnvPage';
import AppErrorBoundary from './App.errorBoundary';

export const routes: RouteObject[] = [
  {
    path: '*',
    element: <Navigate to={routesPaths.teams.root()} replace={true} />,
  },
  {
    path: '/env',
    element: <EnvPage />,
  },
  {
    path: routesPaths.teams.root(),
    element: (
      <AuthGuard forLoggedInOnly={true}>
        <ErrorPageProvider>
          <UserTenantsProvider>
            <InitialOrgKeyResolver>
              <AuthExpiration>
                <TeamsRoot />
              </AuthExpiration>
            </InitialOrgKeyResolver>
          </UserTenantsProvider>
        </ErrorPageProvider>
      </AuthGuard>
    ),
    children: [...teamRoutes, ...contributionsRoutes, ...toolkitRoutes],
    errorElement: (
      <ErrorPageProvider>
        <AppErrorBoundary />
      </ErrorPageProvider>
    ),
  },
  ...authRoutes,
];
