import { Suspense } from 'react';
import { useToggle } from 'react-use';

import ActionsHeader from 'actions/ActionsLayout/ActionsHeader';
import ActionsLayoutProvider from 'actions/ActionsLayout/ActionsLayoutProvider';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import type { ActionsLayoutContext } from 'actions/actions.type';
import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import SortActionsControl from 'actions/ObjectiveActions/SortActionsControl';
import ActionsGrid from 'actions/ActionsGrid';
import ActionsList from 'actions/ActionsList';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/components/CalendarControls';
import CompletedFilterProvider from 'shared/components/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';
import CollaboratingTeamsFilterProvider from 'shared/components/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';

import styles from './ActionsLayout.module.scss';
import ActionsFiltersButton from './ActionsHeader/ActionsFiltersButton';

type ActionsLayoutProps = {
  context: ActionsLayoutContext;
  objectives: Maybe<ActionsObjectiveFragment[]>;
  showCalendarControls: boolean;
  showCollaboratingTeamsFilter: boolean;
  storeFiltersToSession: boolean;
};

const ActionsLayout = ({
  objectives,
  context,
  showCalendarControls,
  showCollaboratingTeamsFilter,
}: ActionsLayoutProps) => {
  const { layout, clearFilters } = useActionsLayout();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  return (
    <Layout.Content
      hasPadding={false}
      className={styles.layout}
      variant={'contained'}
    >
      <ActionsHeader
        objectives={objectives}
        context={context}
        areAllSectionsExpanded={areAllSectionsExpanded}
        toggleAreAllSectionsExpanded={toggleAreAllSectionsExpanded}
        isToggleExpandButtonDisplayed={layout === 'list'}
        controls={showCalendarControls && <CalendarControls />}
        filters={
          <>
            <CompletedFilterButton />
            <ActionsFiltersButton
              clearFilters={clearFilters}
              showCollaboratingTeamsFilter={showCollaboratingTeamsFilter}
            />
            <ToggleExpandIconButton
              isExpanded={areAllSectionsExpanded}
              onToggle={toggleAreAllSectionsExpanded}
              isDisabled={layout !== 'list'}
            />
            <SortActionsControl />
          </>
        }
      />
      <Suspense fallback={<Spinner.Circle />}>
        {layout === 'grid' ? (
          <ActionsGrid objectives={objectives} />
        ) : (
          <ActionsList
            objectives={objectives}
            areAllSectionsExpanded={areAllSectionsExpanded}
          />
        )}
      </Suspense>
    </Layout.Content>
  );
};

export default (props: ActionsLayoutProps) => (
  <CalendarControlsProvider
    initialCalendarInterval={props.showCalendarControls ? undefined : 'all'}
    storeToSession={props.showCalendarControls}
  >
    <CompletedFilterProvider storeToSession={props.showCalendarControls}>
      <CollaboratingTeamsFilterProvider
        storeToSession={props.showCalendarControls}
      >
        <ActionsLayoutProvider objectives={props.objectives}>
          <ActionsLayout {...props} />
        </ActionsLayoutProvider>
      </CollaboratingTeamsFilterProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
