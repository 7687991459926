import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import Flex from 'shared/components/Flex';
import FieldBox from 'shared/form/FieldBox';
import { DatePickerField } from 'shared/components/DatePicker';
import { TextInputField } from 'shared/components/TextInput';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import IconButton from 'shared/components/IconButton';

import type { MetricFormValues } from '../MetricForm';

type Props = {
  index: number;
  onRemove: (index: number) => void;
};

const MetricTargetsItemFormFields = ({ index, onRemove }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { values, getFieldMeta } = useFormikContext<MetricFormValues>();

  const hasError = (field: string) => getFieldMeta(field).error !== undefined;

  const getFieldName = (name: string) => `periodicTargets.${index}.${name}`;

  return (
    <Flex
      gap={'1rem'}
      css={css`
        padding: 20px 20px 5px 20px;
        border-bottom: 1px solid ${theme.color.strokeMedium};
      `}
    >
      <FieldBox
        name={getFieldName('targetDate')}
        label={t('metric.metricForm.periodicTargets.field.targetDate.label')}
        hasError={hasError(getFieldName('targetDate'))}
        hasMargin={false}
      >
        <Field
          name={getFieldName('targetDate')}
          component={DatePickerField}
          minDate={values.timeLine.startDate}
          maxDate={values.timeLine.endDate}
          triggerFullWidth={true}
        />
      </FieldBox>

      <FieldBox
        name={getFieldName('targetValue')}
        label={t('metric.metricForm.periodicTargets.field.targetValue.label')}
        hasError={hasError(getFieldName('targetValue'))}
        hasMargin={false}
      >
        <Field
          name={getFieldName('targetValue')}
          component={TextInputField}
          type={'number'}
        />
      </FieldBox>

      <Flex alignItems={'center'}>
        <IconButton icon={CloseIcon} onClick={() => onRemove(index)} />
      </Flex>
    </Flex>
  );
};

export default MetricTargetsItemFormFields;
