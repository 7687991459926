import { css } from '@emotion/react';

import { ReactComponent as Icon } from 'shared/static/icons/icon-info.svg';

import Tooltip from '../Tooltip';

type Size = 'regular' | 'big';

type Props = {
  className?: string;
  content: string;
  isInline?: boolean;
  size?: Size;
};

const styleBySize = {
  regular: css`
    width: 16px;
    height: 16px;
  `,
  big: css`
    width: 20px;
    height: 20px;
  `,
};

const InfoIcon = ({
  content,
  isInline,
  size = 'regular',
  className,
}: Props) => (
  <Tooltip
    content={content}
    delay={0}
    isInline={isInline}
    css={css({ cursor: 'help' })}
    className={className}
  >
    <Icon css={styleBySize[size]} />
  </Tooltip>
);

export default InfoIcon;
