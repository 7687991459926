import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import type { InitiativeInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { InitiativeOverviewDocument } from 'initiative/InitiativeOverview/InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import { resolvePriorityInitialValue } from 'strategy/PrioritySelect/resolvePriorityInitialValue';
import { InitiativeStatusWizardDocument } from 'initiative/InitiativeStatusWizard/InitiativeStatusWizard.graphql.generated';

import {
  useEditInitiativeQuery,
  useUpdateInitiativeMutation,
} from './EditInitiative.graphql.generated';
import type { InitiativeFormValues } from './InitiativeForm';
import InitiativeForm from './InitiativeForm';
import InitiativeFormModal from './InitiativeFormModal/InitiativeFormModal';

type Props = {
  initiativeId: string;
  isOpen: boolean;
  onClose: () => void;
};

const EditInitiative = ({ initiativeId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const handleApolloError = useHandleError();

  const { data } = useEditInitiativeQuery({
    variables: { initiativeId },
    onError: handleApolloError,
    skip: !isOpen,
  });

  const [updateInitiative, { loading }] = useUpdateInitiativeMutation({
    onError: handleApolloError,
    refetchQueries: [
      InitiativeOverviewDocument,
      InitiativeStatusWizardDocument,
    ],
  });

  const initiative = data?.initiative;

  const initialValues: InitiativeFormValues | undefined = useMemo(
    () =>
      initiative
        ? {
            id: initiative.id,
            name: initiative.name || '',
            description: initiative.description || '',
            owner: initiative.owner?.email || '',
            objective: resolveSelectObjectiveInputInitialValue(
              initiative.objective,
            ),
            priority: resolvePriorityInitialValue(initiative.priority),
            timeLine: {
              startDate: initiative.timeLine.startDate || null,
              endDate: initiative.timeLine.endDate || null,
            },
            milestones:
              initiative.milestones.map((milestone) => ({
                id: milestone.id,
                name: milestone.name || '',
                comment: milestone.comment || '',
                timeLine: {
                  endDate: milestone.timeLine?.endDate || null,
                },
              })) || [],
            attributes: initiative.attributes.map((attribute) => ({
              id: attribute.id,
              name: attribute.name || '',
              title: attribute.title || '',
              description: attribute.description || '',
            })),
          }
        : undefined,
    [initiative],
  );

  const handleSubmit = useCallback(
    async (input: InitiativeInput) => {
      const result = await updateInitiative({
        variables: { input },
      });

      const isSuccess = result.data?.updateInitiative.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('initiative.editInitiative.successToast'),
        });

        onClose();
      }
    },
    [addToast, onClose, t, updateInitiative],
  );

  if (!initialValues) return null;

  const formId = `metric_${initiative?.id}`;

  return (
    <InitiativeForm
      formId={formId}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <InitiativeFormModal
        formId={formId}
        headerTitle={t('initiative.editInitiative.heading')}
        confirmLabel={t('update')}
        isOpen={isOpen}
        onClose={onClose}
        isConfirmDisabled={loading}
      />
    </InitiativeForm>
  );
};

export default EditInitiative;
