import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import type {
  AddTeamMemberFormProps,
  AddTeamMemberFormValues,
} from 'user/AddTeamMemberForm';
import AddTeamMemberForm from 'user/AddTeamMemberForm';
import Dialog from 'shared/components/Dialog';

import styles from './AddTeamMemberModal.module.scss';

const addTeamMemberFormId = 'addTeamMemberForm';

export type AddTeamMemberModalProps = PropsWithChildren<
  Pick<ModalProps, 'onClose' | 'isOpen'> &
    Omit<AddTeamMemberFormProps, 'id' | 'className' | 'onSubmit'>
> & {
  isSubmitButtonDisabled: boolean;
  onSubmit: (data: AddTeamMemberFormValues) => void;
};

const AddTeamMemberModal = ({
  onClose,
  isOpen,
  children,
  onSubmit,
  isSubmitButtonDisabled,
  ...restProps
}: AddTeamMemberModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      headerTitle={t('user.addTeamMemberModal.heading')}
      headerSubtitle={t('user.addTeamMemberModal.info')}
      isOpen={isOpen}
      onClose={onClose}
      confirmLabel={t('save')}
      cancelLabel={t('cancel')}
      formId={addTeamMemberFormId}
      size={'small'}
      isConfirmDisabled={isSubmitButtonDisabled}
    >
      {children}

      <AddTeamMemberForm
        {...restProps}
        onSubmit={onSubmit}
        id={addTeamMemberFormId}
        className={styles.form}
      />
    </Dialog>
  );
};

export default AddTeamMemberModal;
