import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MetricStatusWizardQueryVariables = Types.Exact<{
  metricId: Types.Scalars['ID']['input'];
}>;


export type MetricStatusWizardQuery = { __typename: 'Query', metric: { __typename: 'Metric', id: string, name?: string | undefined, description?: string | undefined, startValue?: number | undefined, targetValue?: number | undefined, unitOfMeasure?: string | undefined, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, currentMetricStatus?: { __typename: 'MetricStatus', id: string, statusValue?: number | undefined, forecastValue?: number | undefined, statusDateTime: Date, statusIndicator: StatusIndicatorClass } | undefined, objective?: { __typename: 'Objective', id: string } | undefined, periodicTargets: Array<{ __typename: 'MetricTarget', targetDate: Date, targetValue: number }> } };

export type MetricStatusWizardMetricFragment = { __typename: 'Metric', id: string, name?: string | undefined, description?: string | undefined, startValue?: number | undefined, targetValue?: number | undefined, unitOfMeasure?: string | undefined, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, currentMetricStatus?: { __typename: 'MetricStatus', id: string, statusValue?: number | undefined, forecastValue?: number | undefined, statusDateTime: Date, statusIndicator: StatusIndicatorClass } | undefined, objective?: { __typename: 'Objective', id: string } | undefined, periodicTargets: Array<{ __typename: 'MetricTarget', targetDate: Date, targetValue: number }> };

export const MetricStatusWizardMetricFragmentDoc = gql`
    fragment metricStatusWizardMetric on Metric {
  id
  name
  description
  startValue
  targetValue
  unitOfMeasure
  timeLine {
    startDate
    endDate
  }
  currentMetricStatus {
    id
    statusValue
    forecastValue
    statusDateTime
    statusIndicator
  }
  objective {
    id
  }
  periodicTargets {
    targetDate
    targetValue
  }
}
    `;
export const MetricStatusWizardDocument = gql`
    query MetricStatusWizard($metricId: ID!) {
  metric(id: $metricId) {
    ...metricStatusWizardMetric
  }
}
    ${MetricStatusWizardMetricFragmentDoc}`;

/**
 * __useMetricStatusWizardQuery__
 *
 * To run a query within a React component, call `useMetricStatusWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricStatusWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricStatusWizardQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useMetricStatusWizardQuery(baseOptions: Apollo.QueryHookOptions<MetricStatusWizardQuery, MetricStatusWizardQueryVariables> & ({ variables: MetricStatusWizardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricStatusWizardQuery, MetricStatusWizardQueryVariables>(MetricStatusWizardDocument, options);
      }
export function useMetricStatusWizardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricStatusWizardQuery, MetricStatusWizardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricStatusWizardQuery, MetricStatusWizardQueryVariables>(MetricStatusWizardDocument, options);
        }
export function useMetricStatusWizardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MetricStatusWizardQuery, MetricStatusWizardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MetricStatusWizardQuery, MetricStatusWizardQueryVariables>(MetricStatusWizardDocument, options);
        }
export type MetricStatusWizardQueryHookResult = ReturnType<typeof useMetricStatusWizardQuery>;
export type MetricStatusWizardLazyQueryHookResult = ReturnType<typeof useMetricStatusWizardLazyQuery>;
export type MetricStatusWizardSuspenseQueryHookResult = ReturnType<typeof useMetricStatusWizardSuspenseQuery>;
export type MetricStatusWizardQueryResult = Apollo.QueryResult<MetricStatusWizardQuery, MetricStatusWizardQueryVariables>;