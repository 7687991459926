import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';

import { useActivateUserMutation } from './ActivateUser.graphql.generated';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
};

const ActivateUserDialog = ({ isOpen, onClose, userId }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleError();

  const [activateUser] = useActivateUserMutation({
    variables: { userId },
    onError,
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('userMenu.activate.success'),
      }),
  });

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={activateUser}
    >
      {t('userMenu.activate.cofirmation')}
    </ConfirmationDialog>
  );
};

export default ActivateUserDialog;
