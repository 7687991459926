import { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import type { ActionWithObjective } from 'actions/actions.type';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-check-square.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import useDialogState from 'shared/hooks/useDialogState';
import ActionStatusModal from 'actions/ActionStatusModal';
import ActionContextMenu from 'actions/ActionContextMenu/ActionContextMenu';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import { transientOptions } from 'shared/utils/emotion.utils';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import LockedLink from 'shared/components/LockedLink';
import Flex from 'shared/components/Flex';

type ActionCardProps = {
  action: ActionWithObjective;
};

const Card = styled('div', transientOptions)<{ $isLocked: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.strokeMedium};
  border-radius: 20px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0%);
  transition: all 0.3s cubic-bezier(0, 0.7, 0.3, 1);
  width: 100%;
`;

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.strokeMedium};
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 20px 16px 40px;
`;

const Name = styled.div`
  overflow-wrap: anywhere;
  position: relative;
`;

const NameIcon = styled(CheckSquareIcon)`
  color: ${({ theme }) => theme.color.typoSecondary};
  left: -20px;
  position: absolute;
  top: 4px;
  width: 13px;
`;

const NameText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${({ theme }) => theme.color.typoSecondary};
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

const Footer = styled.footer`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 16px 20px 14px 20px;
`;

const Timeline = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

const TimelineIcon = styled(DateIcon)`
  color: ${({ theme }) => theme.color.typoSecondary};
  display: inline-block;
  flex-basis: 24px;
  min-height: 24px;
  min-width: 24px;
`;

const TimelineText = styled('div', transientOptions)<{ $isOverdue: boolean }>`
  display: inline-block;
  white-space: nowrap;
  color: ${({ theme, $isOverdue }) =>
    $isOverdue ? theme.color.error : theme.color.black};
`;

const ActionCard = ({ action }: ActionCardProps) => {
  const { name, description, timeLine, owner } = action;

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  const { isOpen, onOpen, onClose } = useDialogState();

  const isLocked = !canPerformStrategyElementAction(action, 'READ');

  return (
    <>
      <Card
        $isLocked={isLocked}
        onClick={isLocked ? undefined : onOpen}
        css={
          !isLocked &&
          css`
            &:hover {
              cursor: pointer;
              box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
            }
          `
        }
      >
        <Content>
          <Name>
            <NameIcon />
            <LockedLink isActive={!isLocked}>
              <NameText>{name}</NameText>
            </LockedLink>
          </Name>
          {description && <Description>{description}</Description>}
        </Content>
        <Footer>
          <Timeline>
            <TimelineIcon />
            <TimelineText $isOverdue={isActionOverdue}>
              {timeLine.endDate && date.format(timeLine.endDate, 'dd MMM')}
            </TimelineText>
          </Timeline>
          {isActionOverdue && <ActionChipOverdue />}
          <CompletionRate
            complete={action.currentStatus?.complete}
            completionRate={action.currentStatus?.completionRate}
          />
          <Flex gap={4}>
            <TeamAvatar orgUnit={action.orgUnit} size={'micro'} />
            <UserAvatar user={owner} />
          </Flex>
          <ActionContextMenu
            action={action}
            triggerStyle={css({ padding: 0, width: 21 })}
          />
        </Footer>
      </Card>

      <ActionStatusModal
        actionId={action.id}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default ActionCard;
