import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-use';
import type { FetchResult } from '@apollo/client';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import type { InitialStep } from 'shared/components/Steps';
import Steps, { useSteps } from 'shared/components/Steps';
import type {
  Objective,
  ObjectiveStatus,
  ObjectiveStatusInput,
} from 'types.graphql.generated';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import { useToasts } from 'shared/toast/useToasts';
import { useTeamAdapter } from 'team/TeamAdapter';
import { withSteps } from 'shared/components/Steps/withSteps';
import { initializeComments } from 'shared/utils/textItem.utils';
import PreviousInsightsProvider from 'status/PreviousInsightsSidecard/PreviousInsightsProvider';

import ObjectiveStatusWizardComments from './ObjectiveStatusWizardComments';
import ObjectiveStatusWizardStatus from './ObjectiveStatusWizardStatus';
import ObjectiveStatusWizardControls from './ObjectiveStatusWizardControls';
import type {
  ObjectiveStatusWizardStepId,
  ObjectiveStatusValues,
} from './ObjectiveStatusWizard.type';
import { resolveObjectiveStatusInput } from './ObjectiveStatusWizard.utils';

const steps: Record<ObjectiveStatusWizardStepId, InitialStep> = {
  status: {
    id: 'status',
    nameTranslationKey: 'objective.objectiveStatusWizard.steps.status.name',
    form: 'objectiveStatusWizardStatusForm',
  },
  comments: {
    id: 'comments',
    nameTranslationKey: 'objective.objectiveStatusWizard.steps.comments.name',
    form: 'objectiveStatusWizardCommentsForm',
  },
};

export type ObjectiveStatusForEdit = Pick<
  ObjectiveStatus,
  | 'id'
  | 'statusDateTime'
  | 'statusIndicator'
  | 'comment'
  | 'complete'
  | 'successComments'
  | 'challengeComments'
  | 'actionComments'
>;

type Props = {
  buttonLabel: string;
  completeStatus?: boolean;
  headerTitle: string;
  objective: Pick<Objective, 'id' | 'name' | 'description'>;
  onSubmit: (input: ObjectiveStatusInput) => Promise<FetchResult>;
  status?: ObjectiveStatusForEdit;
} & Pick<ModalProps, 'onClose' | 'isOpen'>;

const ObjectiveStatusWizard = ({
  status,
  objective,
  onSubmit,
  onClose,
  isOpen,
  completeStatus = false,
  headerTitle,
  buttonLabel,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { teamAdapter } = useTeamAdapter();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { goToNextStep, submitSteps, resetSteps } = useSteps();

  const initialValues: ObjectiveStatusValues = {
    status: {
      statusIndicator: status?.statusIndicator.value || '',
      comment: status?.comment || '',
      complete: status?.complete || completeStatus,
      date: status?.statusDateTime || new Date(),
    },
    comments: {
      successes: initializeComments(status?.successComments),
      challenges: initializeComments(status?.challengeComments),
      actions: initializeComments(status?.actionComments),
    },
  };

  const [values, { set: setValues, setAll: setAllValues }] =
    useMap<ObjectiveStatusValues>(initialValues);

  useEffect(() => {
    resetSteps();
    setAllValues(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChangeStatus = useCallback(
    (values: ObjectiveStatusValues['status']) => setValues('status', values),
    [setValues],
  );

  const handleChangeComments = useCallback(
    (values: ObjectiveStatusValues['comments']) =>
      setValues('comments', values),
    [setValues],
  );

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);

    const input = resolveObjectiveStatusInput({
      status,
      values,
      objective,
      teamAdapter,
    });

    submitSteps(
      async () => {
        try {
          const result = await onSubmit(input);

          if (!result.errors) {
            addToast({
              children: t('objective.objectiveStatusWizard.successToast'),
              variant: 'success',
            });
          }
        } finally {
          setIsSubmitting(false);
        }
      },
      () => setIsSubmitting(false),
    );
  }, [
    addToast,
    objective,
    onSubmit,
    status,
    submitSteps,
    t,
    teamAdapter,
    values,
  ]);

  return (
    <Modal
      heading={headerTitle}
      size={'full'}
      isOpen={isOpen}
      onClose={onClose}
      hasBackground={false}
      renderHeaderContent={() => <Steps.Navigation />}
      renderFooterContent={() => (
        <ObjectiveStatusWizardControls
          buttonLabel={buttonLabel}
          isLoading={isSubmitting}
        />
      )}
      scrollType={'outer'}
    >
      <Steps.Item id={'status'}>
        {({ onStepComplete, onStepError }) => (
          <PreviousInsightsProvider objectiveId={objective.id}>
            <ObjectiveStatusWizardStatus
              id={steps.status.form}
              initialValues={values.status}
              onChange={handleChangeStatus}
              onSubmit={(_values, _formikHelpers, submitOptions) => {
                onStepComplete();
                if (submitOptions.goToNextStep) {
                  goToNextStep();
                }
              }}
              onError={onStepError}
              objective={objective}
              completeStatus={completeStatus}
            />
          </PreviousInsightsProvider>
        )}
      </Steps.Item>
      <Steps.Item id={'comments'}>
        {({ onStepComplete, onStepError }) => (
          <PreviousInsightsProvider
            objectiveId={objective.id}
            formId={steps.comments.form}
          >
            <ObjectiveStatusWizardComments
              id={steps.comments.form}
              initialValues={values.comments}
              onChange={handleChangeComments}
              onSubmit={(_values, _formikHelpers, submitOptions) => {
                onStepComplete();
                if (submitOptions.submitWizard) {
                  handleSubmit();
                }
              }}
              onError={onStepError}
              objective={{
                ...objective,
                ...(values.status?.statusIndicator && {
                  currentObjectiveStatus: {
                    statusIndicator: new StatusIndicator(
                      values.status.statusIndicator,
                    ),
                  },
                }),
              }}
            />
          </PreviousInsightsProvider>
        )}
      </Steps.Item>
    </Modal>
  );
};

export default withSteps<Props, ObjectiveStatusWizardStepId>(
  ObjectiveStatusWizard,
  Object.values(steps),
);
