import { testStatusIndicatorAgainstId } from 'shared/status/StatusMultiSelect';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import { testPriorityAgainstId } from 'shared/priority/PriorityMultiSelect';
import type { CalendarCheck } from 'shared/components/CalendarControls/CalendarControls.context';

import type {
  StrategyObjectivesStatusFilter,
  StrategyObjectivesObjective,
  StrategyObjectivesUser,
  StrategyObjectivesUserFilter,
  StrategyObjectivesStartDateFilter,
  StrategyObjectivesEndDateFilter,
  StrategyObjectivesPriorityFilter,
  StrategyObjectivesThemeFilter,
} from '../StrategyObjectives.type';

export const filterObjectivesByTheme = (
  objectives: StrategyObjectivesObjective[],
  filter: StrategyObjectivesThemeFilter,
): StrategyObjectivesObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      (filter.length === 0 ||
        (!!objective.theme && filter.includes(objective.theme.id))),
  }));

export const filterObjectivesByStatusIndicator = (
  objectives: StrategyObjectivesObjective[],
  filter: StrategyObjectivesStatusFilter,
): StrategyObjectivesObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      filter.some((filterValue) =>
        testStatusIndicatorAgainstId(
          filterValue,
          objective.currentObjectiveStatus?.statusIndicator,
        ),
      ),
  }));

export const filterObjectivesByPriority = (
  objectives: StrategyObjectivesObjective[],
  filter: StrategyObjectivesPriorityFilter,
): StrategyObjectivesObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      filter.some((filterValue) =>
        testPriorityAgainstId(filterValue, objective.priority),
      ),
  }));

export const filterObjectivesByUser = (
  objectives: StrategyObjectivesObjective[],
  filter: StrategyObjectivesUserFilter,
) =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      (!filter.length || filter.some((value) => value === objective.owner?.id)),
  }));

export const filterObjectivesByDates = (
  objectives: StrategyObjectivesObjective[],
  startDate: StrategyObjectivesStartDateFilter,
  endDate: StrategyObjectivesEndDateFilter,
  check?: CalendarCheck,
) => {
  if (!startDate && !endDate) return objectives;

  const interval = { start: startDate, end: endDate };

  return objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testStrategyItemAgainstInterval(objective, interval, check),
  }));
};

export const filterObjectivesByCollaboratingTeams = (
  objectives: StrategyObjectivesObjective[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const getObjectivesOwners = (
  objectives: StrategyObjectivesObjective[],
): StrategyObjectivesUser[] =>
  Object.values(
    objectives.reduce<
      Record<StrategyObjectivesUser['id'], StrategyObjectivesUser>
    >((accumulator, objective) => {
      const user = objective.owner;

      if (user && !accumulator[user.id]) accumulator[user.id] = user;

      return accumulator;
    }, {}),
  );
