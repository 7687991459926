import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Text from 'shared/components/Text';

type Props = {
  date?: Date | null;
  label: string;
  value?: string | number | null;
};

const FieldValue = ({ label, value, date }: Props) => {
  const { t } = useTranslation();

  if (value === undefined || value === null || value === '') return null;

  return (
    <div>
      <Text>
        {label}
        {date ? ` ${t('for')} ${format(date, 'P')}` : ''}
        {': '}
      </Text>
      {value}
    </div>
  );
};

export default FieldValue;
