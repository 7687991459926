import type { PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';

import { useDefinedContext } from 'shared/utils/context.utils';

import type { SummaryForEdit } from './AskSophieChat.type';

type Value = {
  actionIds?: string[];
  initiativeIds?: string[];
  metricIds?: string[];
  objectiveIds?: string[];
  strategyId?: string;
  summaryFooter?: (
    summary: SummaryForEdit,
    regenerate: () => void,
  ) => React.ReactNode;
};

const AskSophieContext = createContext<Value | undefined>(undefined);

export const useAskSophie = () =>
  useDefinedContext(AskSophieContext, {
    hookName: useAskSophie.name,
    providerName: AskSophieContext.Provider.name,
  });

type Props = PropsWithChildren<Value>;

const AskSophieProvider = ({
  objectiveIds,
  metricIds,
  initiativeIds,
  actionIds,
  strategyId,
  summaryFooter,
  children,
}: Props) => {
  const value = useMemo(
    () => ({
      objectiveIds,
      metricIds,
      initiativeIds,
      actionIds,
      strategyId,
      summaryFooter,
    }),
    [
      actionIds,
      initiativeIds,
      metricIds,
      objectiveIds,
      strategyId,
      summaryFooter,
    ],
  );

  return (
    <AskSophieContext.Provider value={value}>
      {children}
    </AskSophieContext.Provider>
  );
};

export default AskSophieProvider;
