import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import type { Merge, SetOptional } from 'type-fest';
import type { PropsWithChildren, ReactNode } from 'react';
import { useCallback, useId, useMemo } from 'react';

import type { FormProps, OnSubmitFn } from 'shared/form/Form';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import Heading from 'shared/components/Heading';
import { DatePickerField } from 'shared/components/DatePicker';
import Checkbox from 'shared/components/Checkbox';

import StatusIndicatorField from './StatusFormStatusIndicatorField';
import { createInitialValues } from './StatusForm.utils';
import { createValidationSchema } from './StatusForm.schema';
import type {
  StatusFormSubmitValues,
  StatusFormValues,
} from './StatusForm.type';

const statusFormId = 'statusForm';

export type StatusFormProps = PropsWithChildren<
  Merge<
    SetOptional<FormProps<StatusFormValues>, 'initialValues'>,
    {
      completeLabel?: string;
      completeStatus?: boolean;
      heading?: ReactNode;
      onChange?: (values: StatusFormSubmitValues) => void;
      onSubmit?: OnSubmitFn<StatusFormSubmitValues>;
      showStatusDateField: boolean;
    }
  >
>;

const StatusForm = ({
  onSubmit,
  initialValues: initialValuesProp,
  id = statusFormId,
  onChange,
  children,
  heading,
  completeStatus,
  completeLabel,
  showStatusDateField,
  ...restProps
}: StatusFormProps) => {
  const { t } = useTranslation();

  const accessibleId = useId();
  const getFieldId = (name: string) => `${accessibleId}${name}`;

  const initialValues = useMemo<StatusFormValues>(
    () => initialValuesProp || createInitialValues(completeStatus),
    [initialValuesProp, completeStatus],
  );

  const validationSchema = useMemo(
    () => createValidationSchema({ t, showStatusDateField }),
    [showStatusDateField, t],
  );

  const handleChange = useCallback<(values: StatusFormValues) => void>(
    (values) => {
      onChange?.(values as StatusFormSubmitValues);
    },
    [onChange],
  );

  const handleSubmit = useCallback<OnSubmitFn<StatusFormValues>>(
    (...args) => {
      onSubmit?.(
        ...(args as unknown as Parameters<OnSubmitFn<StatusFormSubmitValues>>),
      );
    },
    [onSubmit],
  );

  return (
    <Form<StatusFormValues>
      {...restProps}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onChange={handleChange}
      id={id}
    >
      {({ errors, touched, values }) => (
        <>
          {heading && <Heading level={3}>{heading}</Heading>}
          {children}
          <StatusIndicatorField />
          <FieldBox
            name={'comment'}
            label={t('status.form.comment.label')}
            isOptional={true}
            htmlFor={getFieldId('comment')}
          >
            <Field
              hasError={touched.comment && errors.comment}
              name={'comment'}
              component={TextInputField}
              placeholder={t('status.form.comment.placeholder')}
              maxLength={250}
              id={getFieldId('comment')}
            />
          </FieldBox>
          {showStatusDateField && (
            <FieldBox name={'date'} label={t('status.form.date.label')}>
              <Field
                name={'date'}
                component={DatePickerField}
                size={'small'}
                maxDate={new Date()}
              />
            </FieldBox>
          )}
          <FieldBox name={'complete'}>
            <Field
              name={'complete'}
              type={'checkbox'}
              as={Checkbox}
              checked={values.complete}
            >
              {completeLabel || t('status.form.complete.label')}
            </Field>
          </FieldBox>
        </>
      )}
    </Form>
  );
};

export default StatusForm;
