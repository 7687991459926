import { signOut } from 'firebase/auth';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from './AuthProvider';
import { authRoutesPaths } from './auth.routing.paths';

export default function useSignOutWithErrorMessage() {
  const navigate = useNavigate();

  return useCallback(
    (errorMessage: string) =>
      signOut(auth).then(() =>
        setTimeout(
          () =>
            navigate(
              authRoutesPaths.signUpWithError({
                query: { errorMessage },
              }),
            ),
          100,
        ),
      ),
    [navigate],
  );
}
