import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import type { MetricInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { resolvePriorityInitialValue } from 'strategy/PrioritySelect/resolvePriorityInitialValue';
import { MetricOverviewDocument } from 'metric/MetricOverview/MetricOverviewProvider/MetricOverviewProvider.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';

import {
  useEditMetricQuery,
  useUpdateMetricMutation,
} from './EditMetric.graphql.generated';
import type { MetricFormValues } from './MetricForm';
import MetricForm from './MetricForm';
import MetricFormModal from './MetricFormModal/MetricFormModal';

type Props = {
  isOpen: boolean;
  metricId: string;
  onClose: () => void;
};

const EditMetric = ({ metricId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const handleApolloError = useHandleError();

  const { data } = useEditMetricQuery({
    variables: { metricId },
    onError: handleApolloError,
    skip: !isOpen,
  });

  const [updateMetric, { loading }] = useUpdateMetricMutation({
    refetchQueries: [MetricOverviewDocument],
    onError: handleApolloError,
  });

  const metric = data?.metric;

  const initialValues: MetricFormValues | undefined = useMemo(
    () =>
      metric
        ? {
            id: metric.id,
            name: metric.name || '',
            description: metric.description || '',
            owner: metric.owner?.email || '',
            objective: resolveSelectObjectiveInputInitialValue(
              metric.objective,
            ),
            priority: resolvePriorityInitialValue(metric.priority),
            startValue:
              metric.startValue === undefined ? '' : metric.startValue,
            targetValue:
              metric.targetValue === undefined ? '' : metric.targetValue,
            unitOfMeasure: metric.unitOfMeasure || '',
            timeLine: {
              startDate: metric.timeLine.startDate,
              endDate: metric.timeLine.endDate,
            },
            attributes: metric.attributes.map((attribute) => ({
              id: attribute.id,
              name: attribute.name || '',
              title: attribute.title || '',
              description: attribute.description || '',
            })),
            periodicTargets: metric.periodicTargets,
          }
        : undefined,
    [metric],
  );

  const handleSubmit = useCallback(
    async (input: MetricInput) => {
      const result = await updateMetric({ variables: { input } });

      const isSuccess = result.data?.updateMetric.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('metric.editMetricModal.successToast'),
        });

        onClose();
      }
    },
    [addToast, onClose, t, updateMetric],
  );

  if (!initialValues) return null;

  const formId = `metric_${metric?.id}`;

  return (
    <MetricForm
      formId={formId}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <MetricFormModal
        formId={formId}
        headerTitle={t('metric.editMetricModal.heading')}
        confirmLabel={t('update')}
        isOpen={isOpen}
        onClose={onClose}
        isConfirmDisabled={loading}
      />
    </MetricForm>
  );
};

export default EditMetric;
