import type { Merge } from 'type-fest';
import { useMemo } from 'react';

import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import type { ActionWithObjective } from 'actions/actions.type';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import {
  filterActionsByCollaboratingTeams,
  filterActionsByDates,
  filterActionsByPriority,
  filterActionsByStatus,
  filterActionsByUser,
  sortActions,
} from 'actions/action.utils';
import type { ActionStatusMultiSelectItemId } from 'actions/ActionStatusMultiSelect';
import { ReactComponent as FlagIcon } from 'shared/static/icons/icon-flag.svg';
import CollapsibleList from 'shared/components/CollapsibleList';
import { useCalendarControls } from 'shared/components/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/components/CollaboratingTeamsFilter';

import ActionRow from '../ActionRow';

type ActionsListSectionProps = {
  expanded?: boolean;
  hasHeader?: boolean;
  objective: Merge<
    ActionsObjectiveFragment,
    { actions: ActionWithObjective[] }
  >;
};

const ActionsListSection = ({
  objective,
  hasHeader = true,
  expanded,
}: ActionsListSectionProps) => {
  const { orgUnit } = useOrgUnit();

  const {
    statusFilter,
    priorityFilter,
    userFilter,
    actionsSort,
    startDateFilter,
    endDateFilter,
  } = useActionsLayout();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  const filteredActions = useMemo(
    () =>
      filterActionsByCollaboratingTeams(
        filterActionsByStatus(
          filterActionsByDates(
            filterActionsByDates(
              filterActionsByPriority(
                filterActionsByUser(objective.actions, userFilter),
                priorityFilter,
              ),
              startDateFilter,
              endDateFilter,
            ),
            calendarIntervalStartDate,
            calendarIntervalEndDate,
            calendarCheck,
          ),
          [
            ...statusFilter,
            showCompleted && ('DONE' as ActionStatusMultiSelectItemId),
          ].filter(Boolean),
        ),
        showCollaboratingTeams,
        orgUnit?.id,
      ),
    [
      calendarCheck,
      calendarIntervalEndDate,
      calendarIntervalStartDate,
      endDateFilter,
      objective.actions,
      orgUnit,
      priorityFilter,
      showCollaboratingTeams,
      showCompleted,
      startDateFilter,
      statusFilter,
      userFilter,
    ],
  );

  const sortedActions = useMemo(
    () => sortActions(filteredActions, actionsSort),
    [filteredActions, actionsSort],
  );

  return (
    <CollapsibleList
      title={objective.name}
      titleIcon={FlagIcon}
      type={'action'}
      hasHeader={hasHeader}
      expanded={expanded}
    >
      {sortedActions?.map((action) => (
        <ActionRow action={action} key={action.id} />
      ))}
    </CollapsibleList>
  );
};

export default ActionsListSection;
