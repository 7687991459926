import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import type { UnlaunchStrategyParams } from 'strategy/useUnlaunchStrategy';
import { useUnlaunchStrategy } from 'strategy/useUnlaunchStrategy';

type UnlaunchStrategyConfirmationModalProps = Pick<
  UnlaunchStrategyParams,
  'strategyId' | 'teamAdapter'
> & {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const UnlaunchStrategyConfirmationModal = ({
  isOpen,
  onClose,
  strategyId,
  teamAdapter,
  onSuccess,
}: UnlaunchStrategyConfirmationModalProps) => {
  const { t } = useTranslation();

  const { unlaunchStrategy, canUnlaunchStrategy } = useUnlaunchStrategy({
    strategyId,
    teamAdapter,
  });

  const handleConfirm = useCallback(() => {
    if (canUnlaunchStrategy) {
      unlaunchStrategy({ onSuccess });
    }
  }, [canUnlaunchStrategy, unlaunchStrategy, onSuccess]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      {t('strategy.unlaunchStrategyConfirmationModal.content')}
    </ConfirmationDialog>
  );
};

export default UnlaunchStrategyConfirmationModal;
