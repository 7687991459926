import type { TFunction } from 'react-i18next';
import * as yup from 'yup';

import { dateBefore1900Validation } from 'shared/utils/timeLine.utils';

export const createValidationSchema = (params: {
  showStatusDateField: boolean;
  t: TFunction;
}) => {
  const { showStatusDateField, t } = params;

  return yup.object({
    ...(showStatusDateField
      ? { date: dateBefore1900Validation(t) }
      : undefined),
    statusIndicator: yup
      .string()
      .required(t('status.form.statusIndicator.missing')),
  });
};
