import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { uniqBy } from 'lodash';

import type { User } from 'types.graphql.generated';
import type { TeamAdapter } from 'team/TeamAdapter';
import { getNodesFromConnection } from 'shared/graphql/utils';
import useHandleError from 'shared/errors/useHandleError';

import type { UserEmailInputItem } from '../UserEmailInput.type';
import {
  getUserEmailInputItems,
  sortUsersAlphabetically,
} from './UserEmailInputProvider.utils';
import type { UserEmailInputProviderContextValue } from './UserEmailInputProvider.context';
import { UserEmailInputProviderContext } from './UserEmailInputProvider.context';
import {
  useUserEmailInputOrgEmployeesQuery,
  useUserEmailInputOrgUnitMembersQuery,
} from './UserEmailInputProvider.graphql.generated';

export type UserEmailInputProviderProps = PropsWithChildren<{
  teamAdapter: TeamAdapter;
  usersToExclude?: Array<Pick<User, 'id'>>;
}>;

const UserEmailInputProvider = ({
  children,
  usersToExclude,
  teamAdapter,
}: UserEmailInputProviderProps) => {
  const onError = useHandleError();

  const { data: orgEmployeesData } = useUserEmailInputOrgEmployeesQuery({
    skip: teamAdapter.isOrgUnit,
    onError,
  });

  const { data: orgUnitMembersData } = useUserEmailInputOrgUnitMembersQuery({
    variables: { orgUnitId: teamAdapter.keyArg },
    skip: teamAdapter.isOrg,
    onError,
  });

  const userEmailInputItems = useMemo<UserEmailInputItem[]>(() => {
    if (orgEmployeesData) {
      return getUserEmailInputItems({
        usersToExclude,
        users: sortUsersAlphabetically(
          getNodesFromConnection(orgEmployeesData.activeOrg.employees),
        ),
      });
    } else if (orgUnitMembersData) {
      return getUserEmailInputItems({
        usersToExclude,
        users: sortUsersAlphabetically(
          uniqBy(
            [
              ...getNodesFromConnection(orgUnitMembersData.activeOrg.employees),
              ...getNodesFromConnection(
                orgUnitMembersData.orgUnit.orgUnitMembers,
              ),
            ],
            (user) => user.id,
          ),
        ),
      });
    }
    return [];
  }, [orgEmployeesData, orgUnitMembersData, usersToExclude]);

  const contextValue = useMemo<UserEmailInputProviderContextValue>(
    () => ({
      items: userEmailInputItems,
    }),
    [userEmailInputItems],
  );

  return (
    <UserEmailInputProviderContext.Provider value={contextValue}>
      {children}
    </UserEmailInputProviderContext.Provider>
  );
};

export default UserEmailInputProvider;
