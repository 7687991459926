import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import AiLoadingIcon from 'ai/AiLoadingIcon';
import useHandleError from 'shared/errors/useHandleError';
import AiSophieIcon from 'ai/AiSophieIcon';
import Flex from 'shared/components/Flex';
import {
  getAssessmentTypeDescription,
  getAssessmentTypeTitle,
} from 'assessment/assessment.utils';

import { useAskSophieSidePanelSubscription } from '../AskSohpieButton/AskSophieSidePanel/AskSophieSidePanel.graphql.generated';
import type { SummaryMessage } from './AskSophieChat.type';
import { useAskSophie } from './AskSophieProvider';
import ChatBubble from './ChatBubble';

const IconContainer = styled.div`
  align-self: flex-start;
  margin-top: 14px;
  width: 28px;
`;

export const Spacer = styled.div`
  flex-shrink: 0;
  width: 28px;
`;

type Props = {
  setIsLoading: (isLoading: boolean) => void;
  summary: SummaryMessage;
};

const SummaryMessageItem = ({ summary, setIsLoading }: Props) => {
  const { t } = useTranslation();
  const { activeOrg } = useActiveOrg();
  const [content, setContent] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [tempIncrement, setTempIncrement] = useState(0);

  const { summaryFooter } = useAskSophie();

  const handleError = useHandleError();

  useEffect(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const temperature = (summary.promptMessage.temperature || 1) + tempIncrement;

  const { error } = useAskSophieSidePanelSubscription({
    variables: {
      input: {
        languageLocaleCode: activeOrg.languageLocaleCode,
        maxNumberOfCharacters: summary.promptMessage.numberOfCharacters,
        mainPrompt: summary.promptMessage.mainPrompt,
        mainPromptKey: summary.promptMessage.mainPromptKey,
        elements: {
          objectiveIds: summary.context.objectiveIds,
          metricIds: summary.context.metricIds,
          initiativeIds: summary.context.initiativeIds,
          actionIds: summary.context.actionIds,
          strategyIds: summary.context.strategyId
            ? [summary.context.strategyId]
            : undefined,
        },
        hints: [
          ...(summary.promptMessage.hints || []),
          'Use markdown to format your response',
        ],
        model: { temperature },
      },
    },
    onSubscriptionData: (result) => {
      setContent(
        (prevData) => prevData + result.subscriptionData.data?.aiStream,
      );
    },
    onSubscriptionComplete: () => {
      setIsComplete(true);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (error) {
      handleError(error);
      setIsLoading(false);
    }
  }, [error, handleError, setIsLoading]);

  const hasContent = content !== '';
  const shouldShow = !error && (!isComplete || hasContent);

  const regenerate = useCallback(() => {
    setContent('');
    setIsComplete(false);
    setTempIncrement((currentTemp) => currentTemp + 0.01);
  }, []);

  if (!shouldShow) return null;

  return (
    <>
      <IconContainer>
        <AiSophieIcon />
      </IconContainer>
      <ChatBubble $messageType={'SummaryMessage'}>
        {hasContent && <Markdown>{content}</Markdown>}
        {!isComplete && (
          <div css={css({ marginTop: hasContent ? '2rem' : 0 })}>
            <AiLoadingIcon />
          </div>
        )}
        {isComplete && summary.promptMessage.assessmentType && (
          <Flex justifyContent={'center'} css={css({ marginTop: '2rem' })}>
            {summaryFooter?.(
              {
                type: summary.promptMessage.assessmentType,
                title: getAssessmentTypeTitle(
                  t,
                  summary.promptMessage.assessmentType,
                ),
                description: getAssessmentTypeDescription(
                  t,
                  summary.promptMessage.assessmentType,
                ),
                content,
              },
              regenerate,
            )}
          </Flex>
        )}
      </ChatBubble>
      <Spacer />
    </>
  );
};

export default SummaryMessageItem;
