import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyInsightsAssessmentsQueryVariables = Types.Exact<{
  filter: Types.StrategyAssessmentFilterInput;
}>;


export type StrategyInsightsAssessmentsQuery = { __typename: 'Query', allStrategyAssessments: { __typename: 'StrategyAssessmentConnection', edges: Array<{ __typename: 'StrategyAssessmentEdge', node: { __typename: 'StrategyAssessment', id: string, type: Types.StrategyAssessmentType, title?: string | undefined, description?: string | undefined, createDateTime: Date, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean } } }> } };


export const StrategyInsightsAssessmentsDocument = gql`
    query StrategyInsightsAssessments($filter: StrategyAssessmentFilterInput!) {
  allStrategyAssessments(filter: $filter) {
    edges {
      node {
        id
        type
        title
        description
        createDateTime
        owner {
          ...userLimitedProfile
        }
      }
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;

/**
 * __useStrategyInsightsAssessmentsQuery__
 *
 * To run a query within a React component, call `useStrategyInsightsAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyInsightsAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyInsightsAssessmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStrategyInsightsAssessmentsQuery(baseOptions: Apollo.QueryHookOptions<StrategyInsightsAssessmentsQuery, StrategyInsightsAssessmentsQueryVariables> & ({ variables: StrategyInsightsAssessmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyInsightsAssessmentsQuery, StrategyInsightsAssessmentsQueryVariables>(StrategyInsightsAssessmentsDocument, options);
      }
export function useStrategyInsightsAssessmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyInsightsAssessmentsQuery, StrategyInsightsAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyInsightsAssessmentsQuery, StrategyInsightsAssessmentsQueryVariables>(StrategyInsightsAssessmentsDocument, options);
        }
export function useStrategyInsightsAssessmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StrategyInsightsAssessmentsQuery, StrategyInsightsAssessmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StrategyInsightsAssessmentsQuery, StrategyInsightsAssessmentsQueryVariables>(StrategyInsightsAssessmentsDocument, options);
        }
export type StrategyInsightsAssessmentsQueryHookResult = ReturnType<typeof useStrategyInsightsAssessmentsQuery>;
export type StrategyInsightsAssessmentsLazyQueryHookResult = ReturnType<typeof useStrategyInsightsAssessmentsLazyQuery>;
export type StrategyInsightsAssessmentsSuspenseQueryHookResult = ReturnType<typeof useStrategyInsightsAssessmentsSuspenseQuery>;
export type StrategyInsightsAssessmentsQueryResult = Apollo.QueryResult<StrategyInsightsAssessmentsQuery, StrategyInsightsAssessmentsQueryVariables>;