import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MetricStatusWizard from 'metric/MetricStatusWizard';
import type { MetricStatusInput } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';

import { useAddMetricStatusMutation } from './AddMetricStatus.graphql.generated';

type Props = {
  completeStatus?: boolean;
  isOpen: boolean;
  metricId: string;
  onClose: () => void;
};

const CreateMetricStatusDialog = ({
  metricId,
  completeStatus,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const onError = useHandleError();

  const [addMetricStatus] = useAddMetricStatusMutation({
    onCompleted: onClose,
    onError,
    refetchQueries: [ContributionMyContributionDocument],
  });

  const onSubmit = useCallback(
    (input: MetricStatusInput) => addMetricStatus({ variables: { input } }),
    [addMetricStatus],
  );

  return (
    <MetricStatusWizard
      headerTitle={t('metric.createMetricStatus.heading')}
      buttonLabel={t('submit')}
      metricId={metricId}
      completeStatus={completeStatus}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default CreateMetricStatusDialog;
