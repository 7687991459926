import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { OrgUnitForTeamImageFragmentDoc } from '../../orgUnit/OrgUnit.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditAssessmentQueryVariables = Types.Exact<{
  assessmentId: Types.Scalars['ID']['input'];
}>;


export type EditAssessmentQuery = { __typename: 'Query', strategyAssessment: { __typename: 'StrategyAssessment', id: string, type: Types.StrategyAssessmentType, title?: string | undefined, description?: string | undefined, content: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } };

export type UpdateAssessmentMutationVariables = Types.Exact<{
  input: Types.StrategyAssessmentInput;
}>;


export type UpdateAssessmentMutation = { __typename: 'Mutation', saveStrategyAssessment: { __typename: 'StrategyAssessment', id: string, type: Types.StrategyAssessmentType, title?: string | undefined, description?: string | undefined, content: string } };


export const EditAssessmentDocument = gql`
    query EditAssessment($assessmentId: ID!) {
  strategyAssessment(id: $assessmentId) {
    id
    type
    title
    description
    content
    orgUnit {
      ...orgUnitForTeamImage
    }
  }
}
    ${OrgUnitForTeamImageFragmentDoc}`;

/**
 * __useEditAssessmentQuery__
 *
 * To run a query within a React component, call `useEditAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditAssessmentQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useEditAssessmentQuery(baseOptions: Apollo.QueryHookOptions<EditAssessmentQuery, EditAssessmentQueryVariables> & ({ variables: EditAssessmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditAssessmentQuery, EditAssessmentQueryVariables>(EditAssessmentDocument, options);
      }
export function useEditAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditAssessmentQuery, EditAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditAssessmentQuery, EditAssessmentQueryVariables>(EditAssessmentDocument, options);
        }
export function useEditAssessmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EditAssessmentQuery, EditAssessmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditAssessmentQuery, EditAssessmentQueryVariables>(EditAssessmentDocument, options);
        }
export type EditAssessmentQueryHookResult = ReturnType<typeof useEditAssessmentQuery>;
export type EditAssessmentLazyQueryHookResult = ReturnType<typeof useEditAssessmentLazyQuery>;
export type EditAssessmentSuspenseQueryHookResult = ReturnType<typeof useEditAssessmentSuspenseQuery>;
export type EditAssessmentQueryResult = Apollo.QueryResult<EditAssessmentQuery, EditAssessmentQueryVariables>;
export const UpdateAssessmentDocument = gql`
    mutation UpdateAssessment($input: StrategyAssessmentInput!) {
  saveStrategyAssessment(input: $input) {
    id
    type
    title
    description
    content
  }
}
    `;
export type UpdateAssessmentMutationFn = Apollo.MutationFunction<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>;

/**
 * __useUpdateAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentMutation, { data, loading, error }] = useUpdateAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>(UpdateAssessmentDocument, options);
      }
export type UpdateAssessmentMutationHookResult = ReturnType<typeof useUpdateAssessmentMutation>;
export type UpdateAssessmentMutationResult = Apollo.MutationResult<UpdateAssessmentMutation>;
export type UpdateAssessmentMutationOptions = Apollo.BaseMutationOptions<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>;