import { testStatusIndicatorAgainstId } from 'shared/status/StatusMultiSelect';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import { testPriorityAgainstId } from 'shared/priority/PriorityMultiSelect';
import type { CalendarCheck } from 'shared/components/CalendarControls/CalendarControls.context';

import type { StrategyInitiativesObjectiveFragment } from './StrategyInitiativesProvider.graphql.generated';
import type {
  StrategyInitiativesStatusFilter,
  StrategyInitiativesUser,
  StrategyInitiativesUserFilter,
  StrategyInitiativesStartDateFilter,
  StrategyInitiativesEndDateFilter,
  StrategyInitiativesPriorityFilter,
} from '../StrategyInitiatives.type';

export const filterObjectivesByStatusIndicator = (
  objectives: StrategyInitiativesObjectiveFragment[],
  filter: StrategyInitiativesStatusFilter,
) =>
  objectives.map((objective) => ({
    ...objective,
    initiatives: objective.initiatives.filter((initiative) =>
      filter.some((statusMultiSelectItemId) =>
        testStatusIndicatorAgainstId(
          statusMultiSelectItemId,
          initiative.currentInitiativeStatus?.statusIndicator,
        ),
      ),
    ),
  }));

export const filterObjectiveInitiativesByPriority = (
  objectives: StrategyInitiativesObjectiveFragment[],
  filter: StrategyInitiativesPriorityFilter,
) =>
  objectives.map((objective) => ({
    ...objective,
    initiatives: objective.initiatives.filter((initiative) =>
      filter.some((priorityMultiSelectItemId) =>
        testPriorityAgainstId(priorityMultiSelectItemId, initiative.priority),
      ),
    ),
  }));

export const filterObjectivesByUser = (
  objectives: StrategyInitiativesObjectiveFragment[],
  filter: StrategyInitiativesUserFilter,
) => {
  if (!filter.length) {
    return objectives;
  }
  return objectives
    .map((objective) => ({
      ...objective,
      initiatives: objective.initiatives.filter((initiative) =>
        filter.some((value) => value === initiative.owner?.id),
      ),
    }))
    .filter((objective) => objective.initiatives.length);
};

export const filterObjectivesByDates = (
  objectives: StrategyInitiativesObjectiveFragment[],
  start: StrategyInitiativesStartDateFilter,
  end: StrategyInitiativesEndDateFilter,
  check?: CalendarCheck,
) => {
  if (!start && !end) return objectives;

  return objectives
    .map((objective) => ({
      ...objective,
      initiatives: objective.initiatives.filter((initiative) =>
        testStrategyItemAgainstInterval(initiative, { start, end }, check),
      ),
    }))
    .filter((objective) => objective.initiatives.length);
};

export const filterObjectivesByCollaboratingTeams = (
  objectives: StrategyInitiativesObjectiveFragment[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const getObjectivesOwners = (
  objectives: StrategyInitiativesObjectiveFragment[],
): StrategyInitiativesUser[] =>
  Object.values(
    objectives.reduce<
      Record<StrategyInitiativesUser['id'], StrategyInitiativesUser>
    >((accumulator, objective) => {
      objective.initiatives.forEach((metric) => {
        const user = metric.owner;
        if (user && !accumulator[user.id]) {
          accumulator[user.id] = user;
        }
      });
      return accumulator;
    }, {}),
  );
