import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import type { UseDeleteReportParams } from 'report/useDeleteReport';
import { useDeleteReport } from 'report/useDeleteReport';

type DeleteReportConfirmationModalProps = Pick<
  UseDeleteReportParams,
  'report'
> & {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

const DeleteReportConfirmationModal = ({
  isOpen,
  onClose,
  report,
  onSuccess,
}: DeleteReportConfirmationModalProps) => {
  const { canDeleteReport, deleteReport } = useDeleteReport({ report });

  const handleConfirm = () => {
    if (canDeleteReport) {
      deleteReport({ onSuccess });
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export default DeleteReportConfirmationModal;
