import type { ChartOptions } from 'chart.js';
import {
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  isValid,
  isWithinInterval,
  max,
  min,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { sortBy } from 'lodash';
import type { TFunction } from 'i18next';
import type { Theme } from '@emotion/react';

import type {
  MetricInsightsChartMetric,
  ChartData,
  MetricInsightsChartTimeUnit,
} from './MetricInsightsChart.type';

export const getChartOptions = (
  theme: Theme,
  todayLabel: string,
): ChartOptions<'bar' | 'line'> => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        font: {
          size: 14,
        },
      },
    },
    x: {
      stacked: true,
      offset: true,
      ticks: {
        font: {
          size: 14,
        },
      },
    },
  },
  plugins: {
    legend: { display: false },
    tooltip: {
      enabled: true,
      mode: 'nearest',
      callbacks: {
        label: (context: any) =>
          `${context.dataset.label}: ${context.formattedValue}`,
      },
    },
    annotation: {
      annotations: {
        line1: {
          type: 'line',
          xMin: todayLabel,
          xMax: todayLabel,
          borderColor: theme.color.strokeMedium,
          borderWidth: 2,
          borderDash: [5, 5],
          drawTime: 'beforeDatasetsDraw',
        },
      },
    },
    datalabels: {
      display: true,
      clamp: true,
      anchor: 'end',
      align: 'top',
      color: 'black',
      formatter: (_value, context) =>
        Object.entries(context.dataset.data)[context.dataIndex][1],
      labels: {
        title: {
          font: {
            weight: 'bold',
          },
        },
      },
    },
  },
});

export const getChartUnit = (metric: MetricInsightsChartMetric) => {
  const { startDate, endDate } = getDateBoundaries(metric);

  const threshold = 3;

  if (startDate && endDate) {
    if (differenceInYears(endDate, startDate) >= threshold) {
      return 'year';
    } else if (differenceInQuarters(endDate, startDate) >= threshold) {
      return 'quarter';
    } else if (differenceInMonths(endDate, startDate) >= threshold) {
      return 'month';
    } else if (differenceInWeeks(endDate, startDate) >= threshold) {
      return 'week';
    } else if (differenceInDays(endDate, startDate) >= threshold) {
      return 'day';
    }
  }

  return 'day';
};

export const getDateBoundaries = (metric: MetricInsightsChartMetric) => {
  const sortedStatuses = sortBy(
    metric.metricStatusListAll,
    (status) => status.statusDateTime,
  );

  const metricStartDate =
    metric.timeLine.startDate || metric.auditRecord.createDateTime;
  const startDate = min(
    [sortedStatuses[0]?.statusDateTime, metricStartDate].filter(Boolean),
  );

  const lastStatusDate = sortedStatuses.at(-1)?.statusDateTime;
  const endDate = max(
    [metric.timeLine.endDate, lastStatusDate].filter(Boolean),
  );

  return {
    startDate,
    endDate,
  };
};

export const getChartData = (
  t: TFunction,
  theme: Theme,
  metric: MetricInsightsChartMetric,
  unit: MetricInsightsChartTimeUnit,
): ChartData => {
  const sortedStatuses = sortBy(
    metric.metricStatusListAll,
    (status) => status.statusDateTime,
  );

  const { startDate, endDate } = getDateBoundaries(metric);

  const dateRange = isValid(endDate)
    ? getDateRange(startDate, endDate, unit)
    : [];

  const metricStartDate =
    metric.timeLine.startDate || metric.auditRecord.createDateTime;

  const lastStatusDate = sortedStatuses.at(-1)?.statusDateTime;
  const metricStartDateLabel = metricStartDate
    ? formatDateLabel(metricStartDate, unit)
    : undefined;
  const endDateOrLastStatusDate = metric.timeLine.endDate || lastStatusDate;

  const dateRangeStatuses = dateRange
    .map((date) => {
      const sortedPeriodStatuses = sortedStatuses.filter((status) =>
        isWithinInterval(status.statusDateTime, {
          start: date,
          end: endOfPeriod(date, unit),
        }),
      );

      const lastStatusInPeriod = sortedPeriodStatuses.at(-1);
      const statusWithForecastValue = sortedPeriodStatuses
        .reverse()
        .find((status) => hasValue(status.forecastValue));

      const dateLabel = formatDateLabel(date, unit);

      if (lastStatusInPeriod) {
        return {
          dateLabel,
          currentValue: lastStatusInPeriod.statusValue,
          forecastValue: statusWithForecastValue?.forecastValue,
        };
      } else if (
        dateLabel === metricStartDateLabel &&
        hasValue(metric.startValue)
      ) {
        return {
          dateLabel,
          currentValue: metric.startValue,
        };
      }
    })
    .filter(Boolean);

  const currentData: Record<string, number> = dateRangeStatuses.reduce(
    (result, { dateLabel, currentValue }) => ({
      ...result,
      [dateLabel]: currentValue,
    }),
    {},
  );

  const periodicTargetsData = Object.fromEntries(
    metric.periodicTargets.map((target) => [
      formatDateLabel(target.targetDate, unit),
      target.targetValue,
    ]),
  );
  const targetData =
    hasValue(metric.targetValue) && endDateOrLastStatusDate
      ? {
          [formatDateLabel(endDateOrLastStatusDate, unit)]: metric.targetValue,
          ...periodicTargetsData,
        }
      : periodicTargetsData;

  const forecastData = dateRangeStatuses.reduce(
    (result, { dateLabel, forecastValue }) =>
      hasValue(forecastValue)
        ? { ...result, [dateLabel]: forecastValue }
        : result,
    {},
  );

  const labels = dateRange.map((date) => formatDateLabel(date, unit));

  return {
    datasets: {
      current: {
        label: t('metric.insights.chart.current.label'),
        data: currentData,
        color: theme.color.primary,
      },
      target: {
        label: t('metric.insights.chart.target.label'),
        data: targetData,
        color: theme.legacyColor.colorHawkesBlue,
      },
      forecast: {
        label: t('metric.insights.chart.forecast.label'),
        data: forecastData,
        color: theme.legacyColor.colorBoulder,
      },
    },
    labels,
  };
};

const hasValue = (value?: Maybe<number>): value is number => {
  return value !== undefined && value !== null;
};

const getDateRange = (
  start: Date,
  end: Date,
  period: MetricInsightsChartTimeUnit,
) => {
  switch (period) {
    case 'day':
      return eachDayOfInterval({ start, end });
    case 'week':
      return eachWeekOfInterval({ start, end });
    case 'month':
      return eachMonthOfInterval({ start, end });
    case 'quarter':
      return eachQuarterOfInterval({ start, end });
    case 'year':
      return eachYearOfInterval({ start, end });
  }
};

export const formatDateLabel = (
  date: Date,
  unit: MetricInsightsChartTimeUnit,
) => {
  const periodStartDate = startOfPeriod(date, unit);

  switch (unit) {
    case 'day':
      return format(periodStartDate, 'P');
    case 'week':
      return format(periodStartDate, 'ww yyyy');
    case 'month':
      return format(periodStartDate, 'MMM yyyy');
    case 'quarter':
      return format(periodStartDate, 'QQQ yyyy');
    case 'year':
      return format(periodStartDate, 'yyyy');
  }
};

const startOfPeriod = (date: Date, period: MetricInsightsChartTimeUnit) => {
  switch (period) {
    case 'day':
      return startOfDay(date);
    case 'week':
      return startOfWeek(date);
    case 'month':
      return startOfMonth(date);
    case 'quarter':
      return startOfQuarter(date);
    case 'year':
      return startOfYear(date);
  }
};

const endOfPeriod = (date: Date, period: MetricInsightsChartTimeUnit) => {
  switch (period) {
    case 'day':
      return endOfDay(date);
    case 'week':
      return endOfWeek(date);
    case 'month':
      return endOfMonth(date);
    case 'quarter':
      return endOfQuarter(date);
    case 'year':
      return endOfYear(date);
  }
};
