import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { useToasts } from 'shared/toast/useToasts';
import type { CampaignStage } from 'types.graphql.generated';
import Text from 'shared/components/Text';
import useHandleError from 'shared/errors/useHandleError';

import { useDeleteCampaignMutation } from './DeleteCampaign.graphql.generated';
import { WalkOfStrategyCampaignsDocument } from './WalkOfStrategyCampaigns.graphql.generated';

type Props = {
  campaignId: string;
  campaignStage: CampaignStage;
  onClose: () => void;
};

const DeleteCampaignDialog = ({
  campaignId,
  campaignStage,
  onClose,
}: Props) => {
  const theme = useTheme();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const handleApolloError = useHandleError();

  const [deleteCampaign] = useDeleteCampaignMutation({
    variables: { campaignId },
    onError: handleApolloError,
    refetchQueries: [WalkOfStrategyCampaignsDocument],
    onCompleted: () =>
      addToast({
        children: t(
          'toolkit.toolPage.walkOfStrategy.campaigns.deleteDialog.success',
        ),
        variant: 'success',
      }),
  });

  const message =
    campaignStage === 'ACTIVE'
      ? t(
          'toolkit.toolPage.walkOfStrategy.campaigns.deleteDialog.confirmationActive',
        )
      : t(
          'toolkit.toolPage.walkOfStrategy.campaigns.deleteDialog.confirmation',
        );

  return (
    <ConfirmationDialog
      isOpen={true}
      onClose={onClose}
      onConfirm={deleteCampaign}
    >
      <Text css={css({ color: theme.color.black, whiteSpace: 'pre-wrap' })}>
        {message}
      </Text>
    </ConfirmationDialog>
  );
};

export default DeleteCampaignDialog;
