import { useCallback, useMemo } from 'react';
import { useSessionStorage } from 'react-use';

import { useBatchedSearchParams } from './useBatchedSearchParams';

export default function useSearchParamState<T>(
  paramName: string,
  initialValue: T,
  storeToSession?: boolean,
  parseValue?: (serializedValue: string) => T,
  serializeValue?: (value: T) => string,
): [T, (value: T) => void, () => void] {
  const [sessionValue, setSessionValue] = useSessionStorage<string | undefined>(
    paramName,
    undefined,
  );

  const { searchParams, setSearchParam, clearSearchParam } =
    useBatchedSearchParams();

  const searchParamValue = searchParams.get(paramName);

  const value = useMemo(() => {
    if (storeToSession && sessionValue !== undefined) {
      return parseValue ? parseValue(sessionValue) : (sessionValue as T);
    } else if (searchParamValue === null) {
      return initialValue;
    } else {
      return parseValue
        ? parseValue(searchParamValue)
        : (searchParamValue as T);
    }
  }, [
    initialValue,
    parseValue,
    searchParamValue,
    sessionValue,
    storeToSession,
  ]);

  const clearValue = useCallback(() => {
    clearSearchParam(paramName);

    if (storeToSession) {
      setSessionValue(undefined);
    }
  }, [clearSearchParam, paramName, setSessionValue, storeToSession]);

  const setValue = useCallback(
    (newValue: T) => {
      if (newValue === initialValue) {
        clearValue();
        return;
      }

      const serializedValue = serializeValue
        ? serializeValue(newValue)
        : (newValue as string);

      if (serializedValue !== searchParamValue) {
        setSearchParam(paramName, serializedValue);
      }

      if (storeToSession && serializedValue !== sessionValue) {
        setSessionValue(serializedValue);
      }
    },
    [
      clearValue,
      initialValue,
      paramName,
      searchParamValue,
      serializeValue,
      sessionValue,
      setSessionValue,
      storeToSession,
      setSearchParam,
    ],
  );

  return [value, setValue, clearValue];
}
