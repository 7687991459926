import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExportReportMutation = { __typename: 'Mutation', updateReport: { __typename: 'Report', id: string, name?: string | undefined, reportExports: Array<{ __typename: 'ReportExport', id: string, emailSentTime?: Date | undefined, emailSentTo?: string | undefined, exportFormat?: Types.ExportFormat | undefined, fileName?: string | undefined }> } };


export const ExportReportDocument = gql`
    mutation ExportReport($id: ID!) {
  updateReport(input: {idToUpdate: $id, exportReport: {exportFormat: PPTX}}) {
    id
    name
    reportExports {
      id
      emailSentTime
      emailSentTo
      exportFormat
      fileName
    }
  }
}
    `;
export type ExportReportMutationFn = Apollo.MutationFunction<ExportReportMutation, ExportReportMutationVariables>;

/**
 * __useExportReportMutation__
 *
 * To run a mutation, you first call `useExportReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportReportMutation, { data, loading, error }] = useExportReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportReportMutation, ExportReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportReportMutation, ExportReportMutationVariables>(ExportReportDocument, options);
      }
export type ExportReportMutationHookResult = ReturnType<typeof useExportReportMutation>;
export type ExportReportMutationResult = Apollo.MutationResult<ExportReportMutation>;
export type ExportReportMutationOptions = Apollo.BaseMutationOptions<ExportReportMutation, ExportReportMutationVariables>;