/* eslint-disable @typescript-eslint/no-unused-vars */
const colorBlack = '#000';
const colorWhite = '#fff';
const colorPorcelain = '#f0f2f4';
const colorGhostWhite = '#f8f9f9';
const colorAlabaster = '#f7f7f7';
const colorGallery = '#ededed';
const colorPlatinum = '#e1e4ea';
const colorMercury = '#e1e5ea';
const colorLightGray = '#cfd2d8';
const colorAthensGray = '#e1e3e9';
const colorAlto = '#e0e0e0';
const colorIron = '#d8d8d9';
const colorSilver = '#c2c2c2';
const colorQuickSilver = '#a3a6ab';
const colorAshGray = '#b3b8be';
const colorAluminium = '#a9afb7';
const colorNickel = '#6c727b';
const colorBoulder = '#7a7a7a';
const colorDoveGray = '#666';
const colorMineShaft = '#1f1f1f';
const colorDarkGrey = '#DEDEDE';
const colorTextGrey = '#666666';

const colorNavyBlue = '#080066';
const colorBlueRibbon = '#015ef4';
const colorMalibu = '#89aaff';
const colorAnakiwa = '#aecdfe';
const colorHawkesBlue = '#e7f0ff';
const colorEggWhite = '#fff0bf';

const colorMagicMint = '#9effcb';
const colorCaribbeanGreen = '#02ca7f';
const colorBlond = '#ffedba';
const colorLightningYellow = '#fbc122';
const colorPastelOrange = '#f6ab51';
const colorCarnation = '#f9606a';
const colorMandy = '#ed4f59';
const colorMistyRose = '#ffe6e7';
const colorBridesmaid = '#feedef';

const colorLightOragne = '#fff2e3';

const colorAiPurple = '#903EFA';
const colorAiPurpleButtonPressed = '#F1E5FF';
const colorAiPurpleLightBackground = '#C79DFC33';
const colorAiPurpleDarkBackground = '#903EFA4D';

export const theme = {
  color: {
    white: colorWhite,
    black: colorBlack,

    typoPrimary: colorBlack,
    typoSecondary: colorNickel,

    neutral1: colorNickel,
    neutral2: colorAluminium,
    neutral3: colorLightGray,

    primary: colorBlueRibbon,
    primaryVariant: colorHawkesBlue,
    secondary: colorNavyBlue,
    secondaryVariant: colorPastelOrange,

    success: colorCaribbeanGreen,
    successLight: colorMagicMint,
    alert: colorLightningYellow,
    alertLight: colorBlond,
    error: colorMandy,
    errorLight: colorMistyRose,

    strokeLight: colorPlatinum,
    strokeMedium: colorLightGray,
    strokeDark: colorQuickSilver,

    hoverLight: colorGhostWhite,
    hoverDark: colorMercury,

    backgroundDark: colorPorcelain,
    backgroundLight: colorGhostWhite,
  },
  legacyColor: {
    colorBridesmaid,
    colorDoveGray,
    colorSilver,
    colorAshGray,
    colorAlabaster,
    colorGallery,
    colorBoulder,
    colorAlto,
    colorMineShaft,
    colorLightOragne,
    colorEggWhite,
    colorAiPurple,
    colorAiPurpleButtonPressed,
    colorAiPurpleLightBackground,
    colorAiPurpleDarkBackground,
    colorDarkGrey,
    colorTextGrey,
    colorHawkesBlue,
    colorMalibu,
  },
};

export type Theme = typeof theme;
