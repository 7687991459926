import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveUserFromOrgMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type RemoveUserFromOrgMutation = { __typename: 'Mutation', removeUsers: boolean };


export const RemoveUserFromOrgDocument = gql`
    mutation RemoveUserFromOrg($userId: ID!) {
  removeUsers(idsToDelete: [$userId])
}
    `;
export type RemoveUserFromOrgMutationFn = Apollo.MutationFunction<RemoveUserFromOrgMutation, RemoveUserFromOrgMutationVariables>;

/**
 * __useRemoveUserFromOrgMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromOrgMutation, { data, loading, error }] = useRemoveUserFromOrgMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromOrgMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromOrgMutation, RemoveUserFromOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromOrgMutation, RemoveUserFromOrgMutationVariables>(RemoveUserFromOrgDocument, options);
      }
export type RemoveUserFromOrgMutationHookResult = ReturnType<typeof useRemoveUserFromOrgMutation>;
export type RemoveUserFromOrgMutationResult = Apollo.MutationResult<RemoveUserFromOrgMutation>;
export type RemoveUserFromOrgMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromOrgMutation, RemoveUserFromOrgMutationVariables>;