import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserProfileFragmentDoc, UserLimitedProfileFragmentDoc } from '../../userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserEmailInputOrgEmployeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserEmailInputOrgEmployeesQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, employees: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean } }> } } };

export type UserEmailInputOrgUnitMembersQueryVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID']['input'];
}>;


export type UserEmailInputOrgUnitMembersQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, employees: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean } }> } }, orgUnit: { __typename: 'OrgUnit', id: string, orgUnitMembers: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean } }> } } };


export const UserEmailInputOrgEmployeesDocument = gql`
    query UserEmailInputOrgEmployees {
  activeOrg {
    id
    employees(filter: {isActive: true}) {
      edges {
        node {
          ...userProfile
        }
      }
    }
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useUserEmailInputOrgEmployeesQuery__
 *
 * To run a query within a React component, call `useUserEmailInputOrgEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailInputOrgEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailInputOrgEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEmailInputOrgEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<UserEmailInputOrgEmployeesQuery, UserEmailInputOrgEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEmailInputOrgEmployeesQuery, UserEmailInputOrgEmployeesQueryVariables>(UserEmailInputOrgEmployeesDocument, options);
      }
export function useUserEmailInputOrgEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEmailInputOrgEmployeesQuery, UserEmailInputOrgEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEmailInputOrgEmployeesQuery, UserEmailInputOrgEmployeesQueryVariables>(UserEmailInputOrgEmployeesDocument, options);
        }
export function useUserEmailInputOrgEmployeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserEmailInputOrgEmployeesQuery, UserEmailInputOrgEmployeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEmailInputOrgEmployeesQuery, UserEmailInputOrgEmployeesQueryVariables>(UserEmailInputOrgEmployeesDocument, options);
        }
export type UserEmailInputOrgEmployeesQueryHookResult = ReturnType<typeof useUserEmailInputOrgEmployeesQuery>;
export type UserEmailInputOrgEmployeesLazyQueryHookResult = ReturnType<typeof useUserEmailInputOrgEmployeesLazyQuery>;
export type UserEmailInputOrgEmployeesSuspenseQueryHookResult = ReturnType<typeof useUserEmailInputOrgEmployeesSuspenseQuery>;
export type UserEmailInputOrgEmployeesQueryResult = Apollo.QueryResult<UserEmailInputOrgEmployeesQuery, UserEmailInputOrgEmployeesQueryVariables>;
export const UserEmailInputOrgUnitMembersDocument = gql`
    query UserEmailInputOrgUnitMembers($orgUnitId: ID!) {
  activeOrg {
    id
    employees(filter: {isActive: true}) {
      edges {
        node {
          ...userProfile
        }
      }
    }
  }
  orgUnit(id: $orgUnitId) {
    id
    orgUnitMembers {
      edges {
        node {
          ...userProfile
        }
      }
    }
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useUserEmailInputOrgUnitMembersQuery__
 *
 * To run a query within a React component, call `useUserEmailInputOrgUnitMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailInputOrgUnitMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailInputOrgUnitMembersQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useUserEmailInputOrgUnitMembersQuery(baseOptions: Apollo.QueryHookOptions<UserEmailInputOrgUnitMembersQuery, UserEmailInputOrgUnitMembersQueryVariables> & ({ variables: UserEmailInputOrgUnitMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEmailInputOrgUnitMembersQuery, UserEmailInputOrgUnitMembersQueryVariables>(UserEmailInputOrgUnitMembersDocument, options);
      }
export function useUserEmailInputOrgUnitMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEmailInputOrgUnitMembersQuery, UserEmailInputOrgUnitMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEmailInputOrgUnitMembersQuery, UserEmailInputOrgUnitMembersQueryVariables>(UserEmailInputOrgUnitMembersDocument, options);
        }
export function useUserEmailInputOrgUnitMembersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserEmailInputOrgUnitMembersQuery, UserEmailInputOrgUnitMembersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEmailInputOrgUnitMembersQuery, UserEmailInputOrgUnitMembersQueryVariables>(UserEmailInputOrgUnitMembersDocument, options);
        }
export type UserEmailInputOrgUnitMembersQueryHookResult = ReturnType<typeof useUserEmailInputOrgUnitMembersQuery>;
export type UserEmailInputOrgUnitMembersLazyQueryHookResult = ReturnType<typeof useUserEmailInputOrgUnitMembersLazyQuery>;
export type UserEmailInputOrgUnitMembersSuspenseQueryHookResult = ReturnType<typeof useUserEmailInputOrgUnitMembersSuspenseQuery>;
export type UserEmailInputOrgUnitMembersQueryResult = Apollo.QueryResult<UserEmailInputOrgUnitMembersQuery, UserEmailInputOrgUnitMembersQueryVariables>;