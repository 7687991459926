import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Spinner from 'shared/spinner/Spinner';
import InitiativesListThemes from 'initiative/StrategyInitiatives/InitiativesListThemes';

import { useInitiativesListObjectives } from './InitiativesContentProvider/useInitiativesContent';
import { initiativesContentRoutesPaths } from './InitiativesContent.routing';
import InitiativesContentList from './InitiativesContentList';
import InitiativesContentCalendar from './InitiativesContentCalendar/InitiativesContentCalendar';
import styles from './InitiativesContent.module.scss';

type InitiativesContentProps = {
  areAllSectionsExpanded: boolean;
  hasHeaders?: boolean;
  sortListByTheme: boolean;
};

const InitiativesContent = (_props: InitiativesContentProps) => {
  const { t } = useTranslation();

  const { filteredInitiativesActive, isInitiativesListObjectivesLoading } =
    useInitiativesListObjectives();

  const hasInitiatives = useMemo(
    () => !!filteredInitiativesActive?.length,
    [filteredInitiativesActive],
  );

  if (isInitiativesListObjectivesLoading)
    return (
      <div className={styles.spinnerContainer}>
        <Spinner.Circle />
      </div>
    );

  if (!hasInitiatives)
    return (
      <Space isCentered={true}>
        <Result
          description={t('initiatives.empty')}
          illustration={EmptyIllustration}
          hasBackground={true}
        />
      </Space>
    );

  return <Outlet />;
};

const InitiativesContentWithRouting = (props: InitiativesContentProps) => (
  <Routes>
    <Route
      path={initiativesContentRoutesPaths.root()}
      element={<InitiativesContent {...props} />}
    >
      <Route
        index={true}
        element={
          <Navigate to={initiativesContentRoutesPaths.list()} replace={true} />
        }
      />
      <Route
        path={initiativesContentRoutesPaths.list()}
        element={
          props.sortListByTheme ? (
            <InitiativesListThemes
              areAllSectionsExpanded={props.areAllSectionsExpanded}
            />
          ) : (
            <InitiativesContentList
              hasHeaders={props.hasHeaders}
              areAllSectionsExpanded={props.areAllSectionsExpanded}
            />
          )
        }
      />
      <Route
        path={initiativesContentRoutesPaths.calendar()}
        element={<InitiativesContentCalendar />}
      />
    </Route>
  </Routes>
);

export default InitiativesContentWithRouting;
