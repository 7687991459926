import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
export type UserProfileFragment = { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean };

export type UserLimitedProfileFragment = { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean };

export const UserProfileFragmentDoc = gql`
    fragment userProfile on User {
  id
  email
  displayName
  photoUrl
  isActive
  isInActiveOrg
}
    `;
export const UserLimitedProfileFragmentDoc = gql`
    fragment userLimitedProfile on UserLimited {
  id
  email
  displayName
  photoUrl
  isActive
  isInActiveOrg
}
    `;