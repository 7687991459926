import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import useCanPerformOrgUnitAction from 'user/ability/useCanPerformOrgUnitAction';

import Checkbox from '../Checkbox';
import Flex from '../Flex';
import { useCollaboratingTeamsFilter } from './useCollaboratingTeamsFilter';

const Container = styled(Flex)`
  padding: 0.75rem;
  border: 1px solid ${(props) => props.theme.color.strokeDark};
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.color.backgroundLight};
  }
`;

const CollaboratingTeamsFilter = () => {
  const { t } = useTranslation();

  const { showCollaboratingTeams, toggleShowCollaboratingTeams } =
    useCollaboratingTeamsFilter();

  const showFilter = useCanPerformOrgUnitAction('WRITE');

  if (!showFilter) return null;

  return (
    <Container
      justifyContent={'space-between'}
      alignItems={'center'}
      onClick={toggleShowCollaboratingTeams}
    >
      {t('filters.showCollaboratingTeams.label')}
      <Checkbox
        checked={showCollaboratingTeams}
        onClick={(e) => e.stopPropagation()}
      />
    </Container>
  );
};

export default CollaboratingTeamsFilter;
