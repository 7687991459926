import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import ContextMenu from 'shared/components/ContextMenu';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import useHandleError from 'shared/errors/useHandleError';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import type { StrategyAssessment } from 'types.graphql.generated';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';
import { useToasts } from 'shared/toast/useToasts';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import EditAssessment from 'assessment/EditAssessment';
import useDialogState from 'shared/hooks/useDialogState';

import type {
  AssessmentContextMenuItem,
  AssessmentContextMenuItemId,
} from './AssessmentContextMenu.type';
import { useDeleteAssessmentMutation } from './DeleteAssessment.graphql.generated';

export type Props = {
  assessment: Pick<StrategyAssessment, 'id'> & {
    orgUnit?: OrgUnitWithAuthorizedActions;
  };
  onDelete?: () => void;
};

const ObjectiveContextMenu = ({ assessment, onDelete }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [
    isDeleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ] = useToggle(false);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const onError = useHandleError();

  const [deleteAssessment] = useDeleteAssessmentMutation({
    variables: { assessmentId: assessment.id },
    onError,
    update(cache) {
      setTimeout(
        () => cache.evict({ id: `StrategyAssessment:${assessment.id}` }),
        100,
      );
    },
    onCompleted: () => {
      onDelete?.();
      addToast({
        variant: 'success',
        children: t('assessment.contextMenu.delete.toast'),
      });
    },
  });

  const canManage = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  const items = useMemo(
    (): AssessmentContextMenuItem[] =>
      [
        canManage && {
          id: 'edit' as AssessmentContextMenuItemId,
          title: t('edit'),
          icon: EditIcon,
        },
        canManage && {
          id: 'delete' as AssessmentContextMenuItemId,
          title: t('assessment.contextMenu.delete.label'),
          icon: TrashIcon,
        },
      ].filter(Boolean),
    [canManage, t],
  );

  const onAction = useCallback(
    (id: AssessmentContextMenuItemId) => {
      switch (id) {
        case 'edit': {
          onEditOpen();
          break;
        }
        case 'delete': {
          toggleIsDeleteConfirmationModalOpened();
          break;
        }
      }
    },
    [onEditOpen, toggleIsDeleteConfirmationModalOpened],
  );

  return (
    <>
      <ContextMenu<AssessmentContextMenuItem>
        items={items}
        onAction={onAction}
        testId={'assessment'}
      />

      <ConfirmationDialog
        isOpen={isDeleteConfirmationModalOpened}
        onClose={toggleIsDeleteConfirmationModalOpened}
        onConfirm={deleteAssessment}
      >
        {t('assessment.contextMenu.delete.prompt')}
      </ConfirmationDialog>

      <EditAssessment
        assessmentId={assessment.id}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
    </>
  );
};

export default ObjectiveContextMenu;
