import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EditObjectiveStatusDialog from 'objective/EditObjectiveStatusDialog';
import ContextMenu from 'shared/components/ContextMenu';
import useDialogState from 'shared/hooks/useDialogState';
import type { Objective } from 'types.graphql.generated';
import type { ObjectiveStatusForEdit } from 'objective/ObjectiveStatusWizard/ObjectiveStatusWizard';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import type { ObjectiveStatusWizardStepId } from 'objective/ObjectiveStatusWizard/ObjectiveStatusWizard.type';

export type ObjeciveForStatusContextMenu = Pick<
  Objective,
  'id' | 'name' | 'description' | 'isCurrentUserOwner'
> &
  StrategyElementWithAuthorizedActions;

type Props = {
  objective: ObjeciveForStatusContextMenu;
  startEditFromStep?: ObjectiveStatusWizardStepId;
  status: ObjectiveStatusForEdit;
};

const ObjectiveStatusContextMenu = ({
  objective,
  status,
  startEditFromStep,
}: Props) => {
  const { t } = useTranslation();
  const {
    isOpen: isEditOpen,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDialogState();

  const canWrite = canPerformStrategyElementAction(objective, 'WRITE');

  const items = useMemo(
    () => [canWrite && { id: 'edit', title: t('edit') }].filter(Boolean),
    [canWrite, t],
  );

  const handleAction = useCallback(
    (itemId: string) => {
      switch (itemId) {
        case 'edit':
          onOpenEdit();
      }
    },
    [onOpenEdit],
  );

  if (items.length === 0) return null;

  return (
    <>
      <ContextMenu items={items} onAction={handleAction} />

      <EditObjectiveStatusDialog
        isOpen={isEditOpen}
        objective={objective}
        status={status}
        onClose={onCloseEdit}
        startFromStep={startEditFromStep}
      />
    </>
  );
};

export default ObjectiveStatusContextMenu;
