import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

import useSearchParamState from 'shared/hooks/useSearchParamState';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';

import type { CollaboratingTeamsFilterValue } from './CollaboratingTeamsFilter.context';
import CollaboratingTeamsFilterContext from './CollaboratingTeamsFilter.context';

const collaboratingTeamsSearchParamName = 'collaboratingTeams';

type Props = PropsWithChildren<{
  storeToSession: boolean;
}>;

const CollaboratingTeamsFilterProvider = ({
  storeToSession,
  children,
}: Props) => {
  const { orgUnit } = useOrgUnit();

  const [
    showCollaboratingTeams,
    setShowCollaboratingTeams,
    clearShowCollaboratingTeams,
  ] = useSearchParamState<boolean>(
    collaboratingTeamsSearchParamName,
    false,
    storeToSession,
    (serializedValue) => serializedValue === 'true',
    (value) => (value ? 'true' : 'false'),
  );

  const toggleShowCollaboratingTeams = useCallback(() => {
    setShowCollaboratingTeams(!showCollaboratingTeams);
  }, [setShowCollaboratingTeams, showCollaboratingTeams]);

  const value = useMemo<CollaboratingTeamsFilterValue>(
    () => ({
      showCollaboratingTeams: !!orgUnit && showCollaboratingTeams,
      toggleShowCollaboratingTeams,
      clearShowCollaboratingTeams,
    }),
    [
      clearShowCollaboratingTeams,
      orgUnit,
      showCollaboratingTeams,
      toggleShowCollaboratingTeams,
    ],
  );

  return (
    <CollaboratingTeamsFilterContext.Provider value={value}>
      {children}
    </CollaboratingTeamsFilterContext.Provider>
  );
};

export default CollaboratingTeamsFilterProvider;
