import { useTranslation } from 'react-i18next';

import type { Strategy } from 'types.graphql.generated';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { useUpdateIsStrategyComplete } from 'strategy/useUpdateIsStrategyComplete';
import { useToasts } from 'shared/toast/useToasts';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  strategy: Pick<Strategy, 'id'>;
};

const ArchiveStrategyConfirmationModal = ({
  isOpen,
  onClose,
  strategy,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { updateIsStrategyComplete } = useUpdateIsStrategyComplete();

  const handleConfirm = () => {
    updateIsStrategyComplete({
      strategyId: strategy.id,
      isComplete: true,
      onCompleted: () =>
        addToast({
          children: t('strategy.archiveStrategyConfirmationModal.success'),
          variant: 'success',
        }),
    });
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      {t('strategy.archiveStrategyConfirmationModal.content')}
    </ConfirmationDialog>
  );
};

export default ArchiveStrategyConfirmationModal;
