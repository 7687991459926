import { createContext } from 'react';

export type CollaboratingTeamsFilterValue = {
  clearShowCollaboratingTeams: () => void;
  showCollaboratingTeams: boolean;
  toggleShowCollaboratingTeams: () => void;
};

const CollaboratingTeamsFilterContext = createContext<
  CollaboratingTeamsFilterValue | undefined
>(undefined);

export default CollaboratingTeamsFilterContext;
