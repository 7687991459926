import { forwardRef, useId } from 'react';
import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import { css } from '@emotion/react';

import type { AvatarImageProps } from './AvatarImage';
import AvatarImage from './AvatarImage';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
`;

const Label = styled.span`
  flex: 1;
  margin-left: 0.75em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => hexToRgba(props.theme.color.typoPrimary)};
`;

export type AvatarProps = Omit<AvatarImageProps, 'children'> & {
  containerClassName?: string;
  displayName?: Maybe<string>;
  email?: Maybe<string>;
  hasLabel?: boolean;
  isDisabled?: boolean;
  isLinkDisabled?: boolean;
  photoUrl?: Maybe<string>;
  testId?: Maybe<string>;
};

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      displayName,
      email,
      photoUrl,
      isDisabled,
      isLinkDisabled,
      hasLabel,
      containerClassName,
      testId,
      onClick,
      ...restProps
    },
    ref,
  ) => {
    const id = useId();

    const avatarLetter = displayName
      ? displayName?.[0]?.toUpperCase()
      : email?.[0]?.toUpperCase();
    const label = displayName || email;

    return (
      <Container
        className={containerClassName}
        css={!isLinkDisabled && css({ cursor: 'pointer' })}
      >
        <AvatarImage
          {...restProps}
          aria-labelledby={id}
          ref={ref}
          photoUrl={photoUrl}
          isDisabled={isDisabled}
          onClick={isLinkDisabled ? undefined : onClick}
          data-testid={testId}
        >
          {avatarLetter}
        </AvatarImage>
        {hasLabel && label && <Label id={id}>{label}</Label>}
      </Container>
    );
  },
);

export default Avatar;
