import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import StrategyOperationalItemRow from 'strategy/StrategyOperationalItemRow';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import MetricScore from 'metric/MetricScore';

import type { TeamInsightsOverviewObjective } from '../TeamInsightsOverviewProvider';
import styles from './TeamInsightsOverviewoperationalItemsNestedRows.module.scss';

type TeamInsightsOverviewOperationalItemsNestedRowsProps = {
  objective: TeamInsightsOverviewObjective;
};

const TeamInsightsOverviewOperationalItemsNestedRows = ({
  objective,
}: TeamInsightsOverviewOperationalItemsNestedRowsProps) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const metrics = useMemo(
    () => (
      <>
        {objective.metrics.map((metric) => {
          const linkToMetric = objectiveRoutesPaths.metrics.metric.root({
            params: {
              teamSlug: teamAdapter.toParam(),
              metricId: metric.id,
              objectiveId: objective.id,
            },
          });

          return (
            <StrategyOperationalItemRow
              date={metric.currentMetricStatus?.statusDateTime}
              dateTooltip={t('status.form.date.label')}
              statusIndicator={metric.currentMetricStatus?.statusIndicator}
              titleItem={{
                linkTo: linkToMetric,
                text: metric.name,
                isLocked: !canPerformStrategyElementAction(metric, 'READ'),
              }}
              typename={metric.__typename}
              user={metric.owner}
              key={metric.id}
              className={styles.row}
              isLink={metric.objective?.id !== objective.id}
              orgUnit={metric.orgUnit}
              extraInfo={<MetricScore metric={metric} />}
            />
          );
        })}
      </>
    ),
    [objective.id, objective.metrics, t, teamAdapter],
  );

  const initiatives = useMemo(
    () => (
      <>
        {objective.initiatives.map((initiative) => {
          const linkToInitiative =
            objectiveRoutesPaths.initiatives.initiative.root({
              params: {
                teamSlug: teamAdapter.toParam(),
                initiativeId: initiative.id,
                objectiveId: objective.id,
              },
            });

          return (
            <StrategyOperationalItemRow
              date={initiative.currentInitiativeStatus?.statusDateTime}
              dateTooltip={t('status.form.date.label')}
              statusIndicator={
                initiative.currentInitiativeStatus?.statusIndicator
              }
              titleItem={{
                linkTo: linkToInitiative,
                text: initiative.name,
                isLocked: !canPerformStrategyElementAction(initiative, 'READ'),
              }}
              typename={initiative.__typename}
              user={initiative.owner}
              key={initiative.id}
              className={styles.row}
              isLink={initiative.objective?.id !== objective.id}
              orgUnit={initiative.orgUnit}
            />
          );
        })}
      </>
    ),
    [objective.id, objective.initiatives, t, teamAdapter],
  );

  const actions = useMemo(
    () => (
      <>
        {objective.actions.map((action) => {
          const linkToAction = objectiveRoutesPaths.actions.action.root({
            params: {
              teamSlug: teamAdapter.toParam(),
              actionId: action.id,
              objectiveId: objective.id,
            },
          });

          return (
            <StrategyOperationalItemRow
              date={action.currentStatus?.statusDateTime}
              dateTooltip={t('status.form.date.label')}
              statusIndicator={action.currentStatus?.statusIndicator}
              titleItem={{
                linkTo: linkToAction,
                text: action.name,
                isLocked: !canPerformStrategyElementAction(action, 'READ'),
              }}
              typename={action.__typename}
              user={action.owner}
              key={action.id}
              className={styles.row}
              isLink={false}
              orgUnit={action.orgUnit}
            />
          );
        })}
      </>
    ),
    [objective.actions, objective.id, t, teamAdapter],
  );

  return (
    <>
      {metrics}
      {initiatives}
      {actions}
    </>
  );
};

export default TeamInsightsOverviewOperationalItemsNestedRows;
