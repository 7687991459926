import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { ChartProps } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { Tooltip, Chart as ChartJSChart } from 'chart.js';
import { useTranslation } from 'react-i18next';
import Annotation from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useCallback, useRef } from 'react';
import { merge } from 'lodash';
import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useSessionStorage } from 'react-use';

import { transientOptions } from 'shared/utils/emotion.utils';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ChartContainer = styled.div`
  max-height: 320px;
  min-height: 240px;
  width: 100%;
`;

const Legend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Entry = styled.li(
  ({ theme }: { theme: Theme }) => css`
    align-items: center;
    background-color: ${theme.color.backgroundLight};
    border-radius: 8px;
    color: ${theme.color.typoSecondary};
    display: flex;
    gap: 8px;
    padding: 8px 20px;
  `,
);

const Strong = styled.span(
  ({ theme }: { theme: Theme }) => css`
    color: ${theme.color.typoPrimary};
    font-weight: 700;
  `,
);

const Indicator = styled('div', transientOptions)<{
  $backgroundColor?: string;
  $border?: string;
}>`
  border: ${(props) => props.$border || '1px solid white'};
  border-radius: 1px;
  height: 9px;
  width: 9px;
  background-color: ${(props) => props.$backgroundColor};
`;

ChartJSChart.register(Tooltip, Annotation, ChartDataLabels);

const showValuesStorageKey = 'show-chart-values';

const ChartWithCustomLegend = (props: ChartProps) => {
  const chartRef = useRef<ChartJSOrUndefined>(null);

  const { t } = useTranslation();
  const { options, ...restProps } = props;
  const {
    data: { datasets },
  } = restProps;

  const [showValues, setShowValues] = useSessionStorage(
    showValuesStorageKey,
    false,
  );

  const finalOptions = merge(options, {
    plugins: { datalabels: { display: showValues } },
  });

  const onShowValues = useCallback(() => {
    setShowValues(true);
    setTimeout(() => chartRef.current?.update(), 0);
  }, [setShowValues]);

  const onHideValues = useCallback(() => {
    setShowValues(false);
    setTimeout(() => chartRef.current?.update(), 0);
  }, [setShowValues]);

  return (
    <Container>
      <ChartContainer>
        <Chart
          ref={chartRef}
          {...restProps}
          options={finalOptions}
          updateMode={'default'}
        />
      </ChartContainer>

      <Flex justifyContent={'space-between'} gap={8}>
        <Legend>
          {datasets.map((dataset) => {
            const value = Object.values(dataset.data).at(-1) as
              | number
              | undefined;

            const valueLabel = value === undefined ? t('none') : value;

            return (
              <Entry key={dataset.label}>
                <Indicator
                  $backgroundColor={dataset.backgroundColor?.toString()}
                  $border={
                    dataset.backgroundColor ? undefined : `2px solid #6C727B`
                  }
                />
                <div>
                  {dataset.label}
                  {`: `}
                  <Strong>{valueLabel}</Strong>
                </div>
              </Entry>
            );
          })}
        </Legend>

        {showValues ? (
          <Button variant={'simple'} onPress={onHideValues}>
            {t('chart.hideValues')}
          </Button>
        ) : (
          <Button variant={'simple'} onPress={onShowValues}>
            {t('chart.showValues')}
          </Button>
        )}
      </Flex>
    </Container>
  );
};

export default ChartWithCustomLegend;
