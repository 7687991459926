import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { useToasts } from 'shared/toast/useToasts';
import Text from 'shared/components/Text';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import useHandleError from 'shared/errors/useHandleError';

import { useCloseOneThingCampaignMutation } from './CloseOneThingCampaign.graphql.generated';
import { OneThingCampaignsDocument } from './OneThingCampaigns.graphql.generated';

type Props = {
  campaignId: string;
  onClose: () => void;
};

const CloseCampaignDialog = ({ campaignId, onClose }: Props) => {
  const theme = useTheme();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const org = useActiveOrg();

  const handleApolloError = useHandleError();

  const [deleteCampaign] = useCloseOneThingCampaignMutation({
    variables: {
      orgKey: org.activeOrg.orgKey,
      campaignId,
    },
    onError: handleApolloError,
    refetchQueries: [OneThingCampaignsDocument],
    onCompleted: () =>
      addToast({
        children: t('toolkit.toolPage.oneThing.campaigns.closeDialog.success'),
        variant: 'success',
      }),
  });

  const message = t(
    'toolkit.toolPage.oneThing.campaigns.closeDialog.confirmation',
  );

  return (
    <ConfirmationDialog
      isOpen={true}
      onClose={onClose}
      onConfirm={deleteCampaign}
    >
      <Text css={css({ color: theme.color.black, whiteSpace: 'pre-wrap' })}>
        {message}
      </Text>
    </ConfirmationDialog>
  );
};

export default CloseCampaignDialog;
