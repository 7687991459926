import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { compact } from 'lodash';

import { useUserMultiSelect } from 'user/UserMultiSelect';
import { useStatusMultiSelect } from 'shared/status/StatusMultiSelect';
import { usePriorityMultiSelect } from 'shared/priority/PriorityMultiSelect';
import { useCollaboratingTeamsFilter } from 'shared/components/CollaboratingTeamsFilter';

import { useStrategyMetrics } from '../StrategyMetricsProvider';
import type { StrategyMetricsFiltersChipGroupItem } from './StrategyMetricsFiltersChipGroup.type';

export function useChipGroupItems() {
  const {
    filters: { startDateFilter, endDateFilter },
  } = useStrategyMetrics();

  const { state: userMultiSelectState, items: userMultiSelectItems } =
    useUserMultiSelect();
  const { state: statusMultiSelectState, items: statusMultiSelectItems } =
    useStatusMultiSelect();
  const { state: priorityMultiSelectState, items: priorityMultiSelectItems } =
    usePriorityMultiSelect();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const statusItems = useMemo<StrategyMetricsFiltersChipGroupItem[]>(
    () =>
      statusMultiSelectItems
        .filter(
          (item) =>
            item.statusIndicator &&
            statusMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          status: item,
        })),
    [statusMultiSelectState, statusMultiSelectItems],
  );

  const priorityItems = useMemo<StrategyMetricsFiltersChipGroupItem[]>(
    () =>
      priorityMultiSelectItems
        .filter(
          (item) =>
            item.id !== 'ALL' &&
            priorityMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          priority: item,
        })),
    [priorityMultiSelectItems, priorityMultiSelectState.selectionManager],
  );

  const userItems = useMemo<StrategyMetricsFiltersChipGroupItem[]>(
    () =>
      userMultiSelectItems
        .filter((item) =>
          userMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          user: item,
        })),
    [userMultiSelectState, userMultiSelectItems],
  );

  const chipGroupItems = useMemo<StrategyMetricsFiltersChipGroupItem[]>(
    () =>
      compact([
        ...statusItems,
        ...priorityItems,
        ...userItems,
        startDateFilter
          ? {
              id: uuid(),
              startDate: startDateFilter,
            }
          : undefined,
        endDateFilter
          ? {
              id: uuid(),
              endDate: endDateFilter,
            }
          : undefined,
        showCollaboratingTeams
          ? { id: uuid(), showCollaboratingTeams }
          : undefined,
      ]),
    [
      endDateFilter,
      priorityItems,
      showCollaboratingTeams,
      startDateFilter,
      statusItems,
      userItems,
    ],
  );

  return chipGroupItems;
}
