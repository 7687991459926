import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import compact from 'lodash/compact';

import { useStatusMultiSelect } from 'shared/status/StatusMultiSelect';
import { useThemeMultiSelect } from 'themes/ThemeMultiSelect';
import { useUserMultiSelect } from 'user/UserMultiSelect';
import type { TeamInsightsOverviewFiltersChipGroupItem } from 'team/TeamInsights/TeamInsightsOverviewFiltersChipGroup/TeamInsightsOverviewFiltersChipGroup.type';
import { usePriorityMultiSelect } from 'shared/priority/PriorityMultiSelect';
import { useCollaboratingTeamsFilter } from 'shared/components/CollaboratingTeamsFilter';

import { useStrategyObjectives } from '../StrategyObjectivesProvider';
import type { StrategyObjectivesFiltersChipGroupItem } from './StrategyObjectivesFiltersChipGroup.type';

export default function useChipGroupItems() {
  const {
    filters: { startDateFilter, endDateFilter },
  } = useStrategyObjectives();

  const { state: statusMultiSelectState, items: statusMultiSelectItems } =
    useStatusMultiSelect();
  const { state: themeMultiSelectState, items: themeMultiSelectItems } =
    useThemeMultiSelect();
  const { state: userMultiSelectState, items: userMultiSelectItems } =
    useUserMultiSelect();
  const { state: priorityMultiSelectState, items: priorityMultiSelectItems } =
    usePriorityMultiSelect();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const statusItems = useMemo<StrategyObjectivesFiltersChipGroupItem[]>(
    () =>
      statusMultiSelectItems
        .filter(
          (item) =>
            item.statusIndicator &&
            statusMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          status: item,
        })),
    [statusMultiSelectState, statusMultiSelectItems],
  );

  const themeItems = useMemo<StrategyObjectivesFiltersChipGroupItem[]>(
    () =>
      themeMultiSelectItems
        .filter((item) =>
          themeMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          theme: item,
        })),
    [themeMultiSelectItems, themeMultiSelectState],
  );

  const userItems = useMemo<TeamInsightsOverviewFiltersChipGroupItem[]>(
    () =>
      userMultiSelectItems
        .filter((item) =>
          userMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          user: item,
        })),
    [userMultiSelectState, userMultiSelectItems],
  );

  const priorityItems = useMemo<StrategyObjectivesFiltersChipGroupItem[]>(
    () =>
      priorityMultiSelectItems
        .filter(
          (item) =>
            item.id !== 'ALL' &&
            priorityMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          priority: item,
        })),
    [priorityMultiSelectItems, priorityMultiSelectState.selectionManager],
  );

  const chipGroupItems = useMemo<StrategyObjectivesFiltersChipGroupItem[]>(
    () =>
      compact([
        ...themeItems,
        ...statusItems,
        ...priorityItems,
        ...userItems,
        startDateFilter
          ? {
              id: uuid(),
              startDate: startDateFilter,
            }
          : undefined,
        endDateFilter
          ? {
              id: uuid(),
              endDate: endDateFilter,
            }
          : undefined,
        showCollaboratingTeams
          ? { id: uuid(), showCollaboratingTeams }
          : undefined,
      ]),
    [
      endDateFilter,
      priorityItems,
      showCollaboratingTeams,
      startDateFilter,
      statusItems,
      themeItems,
      userItems,
    ],
  );

  return chipGroupItems;
}
