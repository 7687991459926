import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserMultiSelect } from 'user/UserMultiSelect';
import {
  useActionStatusMultiSelect,
  getActionMultiSelectItemLabel,
} from 'actions/ActionStatusMultiSelect';
import type { ChipGroupProps } from 'shared/components/ChipGroup';
import ChipGroup from 'shared/components/ChipGroup';
import { date } from 'shared/services/date.service';
import { usePriorityMultiSelect } from 'shared/priority/PriorityMultiSelect';
import { useCollaboratingTeamsFilter } from 'shared/components/CollaboratingTeamsFilter';

import { useActionsLayout } from '../ActionsLayoutProvider';
import type { ActionsLayoutFiltersChipGroupItem } from './ActionsLayoutFiltersChipGroup.type';
import { useChipGroupItems } from './useChipGroupItems';

const ActionsLayoutFiltersChipGroup = () => {
  const { t } = useTranslation();

  const { clearFilters, setStartDateFilter, setEndDateFilter } =
    useActionsLayout();

  const { state: userMultiSelectState } = useUserMultiSelect();
  const { state: actionStatusMultiSelectState } = useActionStatusMultiSelect();
  const { state: priorityMultiSelectState } = usePriorityMultiSelect();

  const { clearShowCollaboratingTeams } = useCollaboratingTeamsFilter();

  const chipGroupItems = useChipGroupItems();

  const renderLabel = useCallback<
    NonNullable<
      ChipGroupProps<ActionsLayoutFiltersChipGroupItem>['renderLabel']
    >
  >(
    (item) => {
      if (item.status) {
        return getActionMultiSelectItemLabel(item.status.id, t);
      } else if (item.user) {
        return item.user.displayName || item.user.email;
      } else if (item.startDate) {
        return `${t('from')}: ${date.formatShort(item.startDate)}`;
      } else if (item.endDate) {
        return `${t('to')}: ${date.formatShort(item.endDate)}`;
      } else if (item.priority) {
        return item.priority.label;
      } else if (item.showCollaboratingTeams) {
        return t('filters.showCollaboratingTeams.chip');
      }
    },
    [t],
  );

  const isRemoveAllEnabled = chipGroupItems.length > 1;

  const onRemove = useCallback<
    NonNullable<ChipGroupProps<ActionsLayoutFiltersChipGroupItem>['onRemove']>
  >(
    (item) => {
      if (item.status) {
        actionStatusMultiSelectState.selectionManager.toggleSelection(
          item.status.id,
        );
      } else if (item.user) {
        userMultiSelectState.selectionManager.toggleSelection(item.user.id);
      } else if (item.endDate) {
        setEndDateFilter(null);
      } else if (item.startDate) {
        setStartDateFilter(null);
      } else if (item.priority) {
        priorityMultiSelectState.selectionManager.toggleSelection(
          item.priority.id,
        );
      } else if (item.showCollaboratingTeams) {
        clearShowCollaboratingTeams();
      }
    },
    [
      actionStatusMultiSelectState.selectionManager,
      clearShowCollaboratingTeams,
      priorityMultiSelectState.selectionManager,
      setEndDateFilter,
      setStartDateFilter,
      userMultiSelectState.selectionManager,
    ],
  );

  if (chipGroupItems.length === 0) return null;

  return (
    <ChipGroup<ActionsLayoutFiltersChipGroupItem>
      items={chipGroupItems}
      onRemove={onRemove}
      onRemoveAll={isRemoveAllEnabled ? clearFilters : undefined}
      renderLabel={renderLabel}
    />
  );
};

export default ActionsLayoutFiltersChipGroup;
