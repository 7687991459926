import { useTranslation } from 'react-i18next';

import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';

import { useRemoveUserFromOrgMutation } from './RemoveUserFromOrg.graphql.generated';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onRemove?: () => void;
  userId: string;
};

const RemoveOrgMemberConfirmationModal = ({
  isOpen,
  onClose,
  onRemove,
  userId,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleError();

  const [removeUserFromOrg] = useRemoveUserFromOrgMutation({
    variables: { userId },
    update(cache) {
      setTimeout(() => cache.evict({ id: `User:${userId}` }), 1000);
    },
    onCompleted: () => {
      onRemove?.();
      addToast({
        variant: 'success',
        children: t('userMenu.removeFromOrg.success'),
      });
    },
    onError,
  });

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={removeUserFromOrg}
    >
      {t('userMenu.removeFromOrg.confirmation')}
    </ConfirmationDialog>
  );
};

export default RemoveOrgMemberConfirmationModal;
