import { Trans, useTranslation } from 'react-i18next';
import { useSet } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';

import { ReactComponent as ThemeIcon } from 'shared/static/icons/icon-theme.svg';
import { ReactComponent as ObjectiveIcon } from 'shared/static/icons/icon-flag.svg';
import { ReactComponent as MetricIcon } from 'shared/static/icons/icon-chart.svg';
import { ReactComponent as InitiativeIcon } from 'shared/static/icons/icon-initiative.svg';
import type {
  Initiative,
  Metric,
  Objective,
  Theme,
} from 'types.graphql.generated';
import Checkbox from 'shared/components/Checkbox';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import { collapseAndExpandAnimation } from 'shared/animations/collapseAndExpand.animation';
import type { UserAvatarProps } from 'user/UserAvatar/UserAvatar';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import MaybeLinkIcon from 'objective/LinkIcon/MaybeLinkIcon';
import type { OrgUnitForTeamImageFragment } from 'orgUnit/OrgUnit.graphql.generated';
import TeamImage from 'team/TeamImage';
import TeamTooltip from 'team/TeamTooltip';

import { getAllIds } from '../SelectableStrategyItems.utils';
import type { SelectableStrategyItemsObjectiveFragment } from '../SelectableStrategyItemsProvider';
import styles from './SelectableStrategyItemsSection.module.scss';

const areAllIdsSelected = (params: {
  allIds: ReturnType<typeof getAllIds>;
  selectedInitiativesIds: Initiative['id'][];
  selectedMetricsIds: Metric['id'][];
  selectedObjectivesIds: Objective['id'][];
}) => {
  const {
    allIds,
    selectedInitiativesIds,
    selectedMetricsIds,
    selectedObjectivesIds,
  } = params;
  const areAllObjectivesSelected = selectedObjectivesIds.length
    ? allIds.objectivesIds.every((objectiveId) =>
        selectedObjectivesIds.includes(objectiveId),
      )
    : false;
  const areAllMetricsSelected = selectedMetricsIds.length
    ? allIds.metricsIds.every((metricId) =>
        selectedMetricsIds.includes(metricId),
      )
    : false;
  const areAllInitiativesSelected = selectedInitiativesIds.length
    ? allIds.initiativesIds.every((initiativeId) =>
        selectedInitiativesIds.includes(initiativeId),
      )
    : false;
  return (
    areAllObjectivesSelected &&
    areAllMetricsSelected &&
    areAllInitiativesSelected
  );
};

const isNoneIdSelected = (params: {
  allIds: ReturnType<typeof getAllIds>;
  selectedInitiativesIds: Initiative['id'][];
  selectedMetricsIds: Metric['id'][];
  selectedObjectivesIds: Objective['id'][];
}) => {
  const {
    allIds,
    selectedInitiativesIds,
    selectedMetricsIds,
    selectedObjectivesIds,
  } = params;
  const isNoObjectiveSelected = selectedObjectivesIds.length
    ? !allIds.objectivesIds.some((objectiveId) =>
        selectedObjectivesIds.includes(objectiveId),
      )
    : true;
  const isNoMetricSelected = selectedMetricsIds.length
    ? !allIds.metricsIds.some((metricId) =>
        selectedMetricsIds.includes(metricId),
      )
    : true;
  const isNoInitiativeSelected = selectedInitiativesIds.length
    ? !allIds.initiativesIds.some((initiativeId) =>
        selectedInitiativesIds.includes(initiativeId),
      )
    : true;
  return isNoObjectiveSelected && isNoMetricSelected && isNoInitiativeSelected;
};

export type SelectableStrategyItemsSectionProps = {
  objectives: SelectableStrategyItemsObjectiveFragment[];
  onDeselectAll: () => void;
  onSelectAll: () => void;
  onToggleSelectedInitiative: (
    objectiveId: Objective['id'],
    initiativeId: Initiative['id'],
  ) => void;
  onToggleSelectedMetric: (
    objectiveId: Objective['id'],
    metricId: Metric['id'],
  ) => void;
  onToggleSelectedObjective: (objectiveId: Objective['id']) => void;
  selectedInitiativesIds: Initiative['id'][];
  selectedMetricsIds: Metric['id'][];
  selectedObjectivesIds: Objective['id'][];
  theme?: Pick<Theme, 'id' | 'name'>;
};

const SelectableStrategyItemsSection = ({
  objectives,
  selectedInitiativesIds,
  selectedMetricsIds,
  selectedObjectivesIds,
  onToggleSelectedObjective,
  onToggleSelectedInitiative,
  onToggleSelectedMetric,
  onDeselectAll,
  onSelectAll,
  theme,
}: SelectableStrategyItemsSectionProps) => {
  const { t } = useTranslation();

  const allIds = getAllIds(objectives);

  const isNoneSelected = isNoneIdSelected({
    allIds,
    selectedObjectivesIds,
    selectedMetricsIds,
    selectedInitiativesIds,
  });

  const areAllSelected = areAllIdsSelected({
    allIds,
    selectedObjectivesIds,
    selectedMetricsIds,
    selectedInitiativesIds,
  });

  const [, { toggle: toggleExpandObjective, has: isObjectiveExpanded }] =
    useSet<Objective['id']>();

  if (objectives.length === 0) return null;

  return (
    <section>
      <Space
        className={styles.theme}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();

          if (isNoneSelected) {
            onSelectAll();
          } else {
            onDeselectAll();
          }
        }}
      >
        <Checkbox
          checked={areAllSelected}
          isIndeterminate={!isNoneSelected && !areAllSelected}
          readOnly={true}
        />
        <ThemeIcon />
        <Text isBold={true}>{theme ? theme.name : t('theme.noTheme')}</Text>
      </Space>
      <div className={styles.objectives}>
        {objectives.map((objective) => {
          const isObjectiveSelected = !!selectedObjectivesIds.find(
            (objectiveId) => objective.id === objectiveId,
          );
          const isExpanded = isObjectiveExpanded(objective.id);
          const hasItems = !!(
            objective.metrics.length + objective.initiatives.length
          );
          return (
            <div className={styles.objective} key={objective.id}>
              <Space className={styles.objectiveDetails}>
                <Checkbox
                  checked={isObjectiveSelected}
                  onChange={() => onToggleSelectedObjective(objective.id)}
                />
                <ObjectiveIcon className={styles.elementIcon} />
                <StatusMark
                  statusIndicator={
                    objective.currentObjectiveStatus?.statusIndicator
                  }
                />
                <Text
                  variant={'emphasis'}
                  onClick={() =>
                    hasItems
                      ? toggleExpandObjective(objective.id)
                      : onToggleSelectedObjective(objective.id)
                  }
                  className={styles.objectiveName}
                >
                  {objective.name}
                  {hasItems && (
                    <IconButton
                      icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                      size={'small'}
                      className={styles.expandButton}
                    >
                      {t(isExpanded ? 'collapse' : 'expand')}
                    </IconButton>
                  )}
                </Text>
                <ElementStatus
                  orgUnit={objective.orgUnit}
                  owner={objective.owner}
                  updateDateTime={
                    objective.currentObjectiveStatus?.auditRecord.updateDateTime
                  }
                />
              </Space>
              <AnimatePresence>
                {isExpanded && (
                  <motion.div {...collapseAndExpandAnimation}>
                    <div className={styles.objectiveItems}>
                      {objective.metrics.map((metric) => {
                        const isMetricSelected = !!selectedMetricsIds.find(
                          (metricId) => metric.id === metricId,
                        );

                        return (
                          <Space
                            className={styles.objectiveItem}
                            key={metric.id}
                            onClick={(event) => {
                              event.preventDefault();
                              onToggleSelectedMetric(objective.id, metric.id);
                            }}
                          >
                            <Checkbox checked={isMetricSelected} />
                            <StatusMark
                              statusIndicator={
                                metric.currentMetricStatus?.statusIndicator
                              }
                            />
                            <MetricIcon className={styles.elementIcon} />
                            <Text variant={'emphasis'}>{metric.name}</Text>
                            <MaybeLinkIcon
                              strategyElement={metric}
                              contextObjective={objective}
                            />
                            <ElementStatus
                              owner={metric.owner}
                              updateDateTime={
                                metric.currentMetricStatus?.auditRecord
                                  .updateDateTime
                              }
                            />
                          </Space>
                        );
                      })}
                      {objective.initiatives.map((initiative) => {
                        const isInitiativeSelected =
                          !!selectedInitiativesIds.find(
                            (initiativeId) => initiative.id === initiativeId,
                          );

                        return (
                          <Space
                            className={styles.objectiveItem}
                            key={initiative.id}
                            onClick={(event) => {
                              event.preventDefault();
                              onToggleSelectedInitiative(
                                objective.id,
                                initiative.id,
                              );
                            }}
                          >
                            <Checkbox checked={isInitiativeSelected} />
                            <StatusMark
                              statusIndicator={
                                initiative.currentInitiativeStatus
                                  ?.statusIndicator
                              }
                            />
                            <InitiativeIcon className={styles.elementIcon} />
                            <Text variant={'emphasis'}>{initiative.name}</Text>
                            <MaybeLinkIcon
                              strategyElement={initiative}
                              contextObjective={objective}
                            />
                            <ElementStatus
                              owner={initiative.owner}
                              updateDateTime={
                                initiative.currentInitiativeStatus?.auditRecord
                                  .updateDateTime
                              }
                            />
                          </Space>
                        );
                      })}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SelectableStrategyItemsSection;

const ElementStatus = ({
  orgUnit,
  owner,
  updateDateTime,
}: {
  orgUnit?: Maybe<OrgUnitForTeamImageFragment>;
  owner?: UserAvatarProps['user'];
  updateDateTime?: Date;
}) => (
  <div className={styles.rowEnd}>
    {orgUnit !== undefined && (
      <TeamTooltip orgUnit={orgUnit}>
        <TeamImage orgUnit={orgUnit} size={'micro'} />
      </TeamTooltip>
    )}
    {owner && (
      <div className={styles.ownerDetails}>
        <UserAvatar user={owner} size={'small'} />
        {owner.displayName || owner.email}
      </div>
    )}
    <div className={styles.elementStatus}>
      {updateDateTime && (
        <>
          <Trans i18nKey={'status.status'} />
          {': '}
          {format(updateDateTime, 'P')}
        </>
      )}
    </div>
  </div>
);
