import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';
import Tooltip from 'shared/components/Tooltip';
import type { Metric, MetricStatus } from 'types.graphql.generated';

type Props = {
  metric: Pick<Metric, 'unitOfMeasure' | 'startValue' | 'targetValue'> & {
    currentMetricStatus?: Pick<MetricStatus, 'statusValue'>;
  };
};

const MetricScore = ({ metric }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const currentValue =
    metric.currentMetricStatus?.statusValue !== undefined &&
    metric.currentMetricStatus.statusValue !== null
      ? metric.currentMetricStatus.statusValue
      : metric.startValue;

  const hasCurrentValue = currentValue !== undefined && currentValue !== null;

  const currentValueLabel = hasCurrentValue ? currentValue : '-';

  const hasTargetValue =
    metric.targetValue !== undefined && metric.targetValue !== null;

  const targetValueLabel = hasTargetValue ? metric.targetValue : '-';

  if (!hasCurrentValue && !hasTargetValue) return null;

  return (
    <Tooltip
      content={
        <Flex direction={'column'}>
          <Flex>
            {t('metric.currentScore')}
            {': '}
            {currentValueLabel}
          </Flex>
          {hasTargetValue && (
            <Flex>
              {t('metric.targetValue')}
              {': '}
              {targetValueLabel}
            </Flex>
          )}
        </Flex>
      }
    >
      <Flex
        gap={4}
        css={css`
          color: ${theme.color.typoSecondary};
        `}
      >
        <div>{metric.unitOfMeasure}</div>

        <Flex>
          {'('}
          {currentValueLabel}

          {hasTargetValue && (
            <>
              {'/'}
              {targetValueLabel}
            </>
          )}
          {')'}
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export default MetricScore;
