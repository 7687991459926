import { css, useTheme } from '@emotion/react';

import type { Objective, OrgUnit } from 'types.graphql.generated';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import { ReactComponent as ChevronRightIcon } from 'shared/static/icons/icon-chevron-right.svg';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { TeamAdapter } from 'team/TeamAdapter';
import LockedLink from 'shared/components/LockedLink';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Flex from 'shared/components/Flex';
import { useActiveOrg } from 'org/ActiveOrgProvider';

type ObjectiveLinkProps = {
  isLink: boolean;
  objective: Pick<Objective, 'id' | 'name' | 'currentUserAuthorizedActions'> & {
    orgUnit?: Maybe<Pick<OrgUnit, 'id'>>;
  };
};

const ObjectiveLink = ({ objective, isLink }: ObjectiveLinkProps) => {
  const theme = useTheme();
  const { activeOrg } = useActiveOrg();

  const to = objectiveRoutesPaths.root({
    params: {
      objectiveId: objective.id,
      teamSlug: TeamAdapter.fromOrgUnitOrOrg(
        activeOrg,
        objective.orgUnit,
      ).toParam(),
    },
  });

  const Icon = isLink ? LinkIcon : ChevronRightIcon;

  return (
    <Flex
      gap={8}
      css={css`
        background-color: ${theme.color.white};
        border: 1px solid ${theme.color.strokeMedium};
        border-radius: 12px;
        min-height: 48px;
        padding: 8px;
      `}
    >
      <Flex css={css({ height: 30 })}>
        <Icon width={16} css={css({ flexShrink: 0 })} />
      </Flex>
      <div css={css({ marginTop: 5, marginBottom: 5 })}>
        <LockedLink
          to={to}
          isActive={canPerformStrategyElementAction(objective, 'READ')}
        >
          {objective.name}
        </LockedLink>
      </div>
    </Flex>
  );
};

export default ObjectiveLink;
