import { useTranslation } from 'react-i18next';

import type { UseDeleteOrgUnitParams } from 'orgUnit/useDeleteOrgUnit';
import { useDeleteOrgUnit } from 'orgUnit/useDeleteOrgUnit';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';

type DeleteOrgUnitConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
} & UseDeleteOrgUnitParams;

const DeleteOrgUnitConfirmationModal = ({
  isOpen,
  onClose,
  onDelete,
  orgUnit,
}: DeleteOrgUnitConfirmationModalProps) => {
  const { t } = useTranslation();

  const { deleteOrgUnit } = useDeleteOrgUnit({
    orgUnit,
    onDelete,
  });

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={deleteOrgUnit}
    >
      {t('orgUnit.deleteOrgUnitConfirmation.content')}
    </ConfirmationDialog>
  );
};

export default DeleteOrgUnitConfirmationModal;
