import { useDefinedContext } from 'shared/utils/context.utils';

import CollaboratingTeamsFilterContext from './CollaboratingTeamsFilter.context';
import CollaboratingTeamsFilterProvider from './CollaboratingTeamsFilterProvider';

export const useCollaboratingTeamsFilter = () =>
  useDefinedContext(CollaboratingTeamsFilterContext, {
    hookName: useCollaboratingTeamsFilter.name,
    providerName: CollaboratingTeamsFilterProvider.name,
  });
