import { lazy, Suspense } from 'react';

import type { RichTextInputProps } from './RichTextInput.type';

const RichTextInput = lazy(() => import('./RichTextInput'));

const LazyRichTextInput = (props: RichTextInputProps) => {
  return (
    <Suspense>
      <RichTextInput {...props} />
    </Suspense>
  );
};

export default LazyRichTextInput;
