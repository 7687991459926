import { useTranslation } from 'react-i18next';

import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import DatePicker from 'shared/components/DatePicker';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import Flex from 'shared/components/Flex';
import CollaboratingTeamsFilter from 'shared/components/CollaboratingTeamsFilter';

import { useStrategyInitiatives } from '../StrategyInitiativesProvider';

const StrategyInitiativesFilters = () => {
  const { t } = useTranslation();

  const {
    filters: {
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
    },
  } = useStrategyInitiatives();

  return (
    <>
      <StatusMultiSelect />
      <PriorityMultiSelect />
      <UserMultiSelect />
      <Flex gap={8}>
        <DatePicker
          value={startDateFilter}
          onChange={setStartDateFilter}
          labelPrefix={t('from')}
          placeholder={t('allDates')}
          maxDate={endDateFilter}
          grow={true}
        />
        <DatePicker
          value={endDateFilter}
          onChange={setEndDateFilter}
          labelPrefix={t('to')}
          placeholder={t('allDates')}
          minDate={startDateFilter}
          grow={true}
        />
      </Flex>
      <CollaboratingTeamsFilter />
    </>
  );
};

export default StrategyInitiativesFilters;
