import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { datadogRum } from '@datadog/browser-rum';
import type { User } from 'firebase/auth';
import { createClient } from 'graphql-ws';

import env from 'environment';
import { withBearerToken } from 'shared/utils/apollo.utils';
import { getActingOrgLink } from 'shared/graphql/getActingOrgLink';
import { typePolicies } from 'App.typePolicies';
import { getActingOrgHeaders } from 'shared/graphql/getActingOrgHeaders';

const httpLink = new HttpLink({
  uri: env.REACT_APP_API_URL,
});

const subscriptionsUrl = env.REACT_APP_API_URL.replace(
  'https://',
  'wss://',
).replace('/graphql', '/subscriptions');

const wsLink = (firebaseUser?: User, actingOrgKey?: string) =>
  new GraphQLWsLink(
    createClient({
      url: subscriptionsUrl,
      connectionParams: async () => ({
        ...(firebaseUser ? { authToken: await firebaseUser.getIdToken() } : {}),
        ...(actingOrgKey ? getActingOrgHeaders(actingOrgKey) : {}),
      }),
    }),
  );

const splitLink = (firebaseUser?: User, actingOrgKey?: string) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(firebaseUser, actingOrgKey),
    httpLink,
  );

const authLink = (firebaseUser?: User) =>
  setContext(async (_, previousContext) => {
    const idToken = await firebaseUser?.getIdToken();

    return idToken && !previousContext.headers?.authorization
      ? withBearerToken(previousContext, idToken)
      : previousContext;
  });

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  graphQLErrors?.forEach((error) => datadogRum.addError(error));

  if (networkError) {
    datadogRum.addError(networkError);
  }

  response?.errors?.forEach((error) => datadogRum.addError(error));
});

export const createApolloClient = (params: {
  actingOrgKey?: string;
  firebaseUser?: User;
}) => {
  const { firebaseUser, actingOrgKey } = params;

  return new ApolloClient({
    link: from(
      [
        actingOrgKey && getActingOrgLink(actingOrgKey),
        authLink(firebaseUser),
        errorLink,
        splitLink(firebaseUser, actingOrgKey),
      ].filter(Boolean),
    ),
    cache: new InMemoryCache({ typePolicies: typePolicies }),
    connectToDevTools: true,
  });
};
