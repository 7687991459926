import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// Batch updates within the same event loop
let batchedUpdates: Map<string, string | null | undefined> | null = null;
let batchTimeout: NodeJS.Timeout | null = null;

export function useBatchedSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const flushBatchedUpdates = useCallback(() => {
    if (batchedUpdates) {
      setSearchParams((currentSearchParams) => {
        for (const [param, value] of batchedUpdates!) {
          if (value === null || value === undefined) {
            currentSearchParams.delete(param);
          } else {
            currentSearchParams.set(param, value);
          }
        }
        return currentSearchParams;
      });
      batchedUpdates = null;
    }
  }, [setSearchParams]);

  const setSearchParam = useCallback(
    (param: string, value: string | null | undefined) => {
      if (!batchedUpdates) {
        batchedUpdates = new Map();
      }
      batchedUpdates.set(param, value);

      if (batchTimeout) {
        clearTimeout(batchTimeout);
      }
      batchTimeout = setTimeout(flushBatchedUpdates, 0);
    },
    [flushBatchedUpdates],
  );

  const clearSearchParam = useCallback(
    (param: string) => {
      setSearchParam(param, null);
    },
    [setSearchParam],
  );

  // Clean up batch timeout on unmount
  useEffect(() => {
    return () => {
      if (batchTimeout) {
        clearTimeout(batchTimeout);
      }
    };
  }, []);

  return {
    searchParams,
    setSearchParam,
    clearSearchParam,
  };
}
