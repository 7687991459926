import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';

import Dialog from '../Dialog';

type Props = PropsWithChildren<{
  isOpen: boolean;
  onCancel?: () => void | Promise<unknown>;
  onClose: () => void;
  onConfirm?: () => void | Promise<unknown>;
}>;

const ConfirmationDialog = ({
  isOpen,
  onConfirm,
  onClose,
  onCancel,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
      onCancel={onCancel}
      size={'tiny'}
      confirmLabel={t('confirm')}
      cancelLabel={t('cancel')}
    >
      {children}
    </Dialog>
  );
};

export default ConfirmationDialog;
