import { useDefinedContext } from 'shared/utils/context.utils';

import CalendarControlsContext from './ShowChildrenFilter.context';
import ShowChildrenFilterProvider from './ShowChildrenFilterProvider';

export const useShowChildrenFilter = () =>
  useDefinedContext(CalendarControlsContext, {
    hookName: useShowChildrenFilter.name,
    providerName: ShowChildrenFilterProvider.name,
  });
