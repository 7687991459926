import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';

import TeamInsightsLayout from 'team/TeamInsights/TeamInsightsLayout';
import useHandleError from 'shared/errors/useHandleError';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import Spinner from 'shared/spinner/Spinner';
import Flex from 'shared/components/Flex';
import { getNodesFromConnection } from 'shared/graphql/utils';
import UserAvatar from 'user/UserAvatar';
import Heading from 'shared/components/Heading';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';
import { assessmentRoutesPaths } from 'assessment/assessment.routing.paths';
import {
  getAssessmentTypeDescription,
  getAssessmentTypeTitle,
} from 'assessment/assessment.utils';
import AssessmentContextMenu from 'assessment/AssessmentContextMenu/AssessmentContextMenu';
import CreateAssessmentButton from 'assessment/CreateAssessmentButton';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Result from 'shared/components/Result';
import Text from 'shared/components/Text';
import AssessmentImage from 'assessment/AssessmentImage';

import { useStrategyInsightsAssessmentsQuery } from './StrategyInsightsAssessments.graphql.generated';

const Item = styled(Flex)`
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
  border-radius: 1rem;
  padding: 20px;
  cursor: pointer;
  min-width: 373px;
  max-width: 373px;
  justify-content: space-between;
  height: 275px;
`;

const ImageContainer = styled.div`
  height: 100px;
`;

const Description = styled.div`
  min-height: 45px;
  max-height: 45px;
  overflow: hidden;
`;

const ContextMenuContainer = styled(Flex)`
  margin-right: -14px;
  margin-top: -4px;
  height: 32px;
`;

const StrategyInsightsAssessments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orgUnit } = useOrgUnit();
  const { teamSlug } = useTeamSlug();

  const onError = useHandleError();

  const { data } = useStrategyInsightsAssessmentsQuery({
    variables: {
      filter: orgUnit
        ? { orgUnitIds: { operator: 'IN', value: [orgUnit.id] } }
        : { orgUnitIds: { operator: 'IS_NULL' } },
    },
    onError,
  });

  const assessments = data
    ? getNodesFromConnection(data.allStrategyAssessments)
    : undefined;

  if (!assessments) {
    return (
      <TeamInsightsLayout controls={<CreateAssessmentButton />}>
        <Spinner.Circle />
      </TeamInsightsLayout>
    );
  }

  if (assessments.length === 0) {
    return (
      <TeamInsightsLayout controls={<CreateAssessmentButton />}>
        <Flex justifyContent={'center'} css={css({ margin: '2rem' })}>
          <Result
            description={t('strategy.assessments.empty')}
            illustration={EmptyIllustration}
            hasBackground={true}
          />
        </Flex>
      </TeamInsightsLayout>
    );
  }

  return (
    <TeamInsightsLayout controls={<CreateAssessmentButton />}>
      <Flex gap={'1rem'} wrap={'wrap'}>
        {assessments.map((assessment) => (
          <Item
            key={assessment.id}
            direction={'column'}
            gap={'1rem'}
            onClick={() =>
              navigate(
                assessmentRoutesPaths.view({
                  params: { teamSlug, assessmentId: assessment.id },
                }),
              )
            }
          >
            <ImageContainer>
              <AssessmentImage type={assessment.type} />
            </ImageContainer>

            <Flex justifyContent={'space-between'}>
              <Heading level={4} hasMargin={false} ellipsis={true}>
                {assessment.title || getAssessmentTypeTitle(t, assessment.type)}
              </Heading>

              <ContextMenuContainer alignItems={'center'}>
                <AssessmentContextMenu assessment={assessment} />
              </ContextMenuContainer>
            </Flex>

            <Description>
              {assessment.description ||
                getAssessmentTypeDescription(t, assessment.type)}
            </Description>

            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={4}
            >
              <Text>
                {t('strategy.assessments.createdOn', {
                  date: format(assessment.createDateTime, 'P'),
                })}
              </Text>

              <UserAvatar user={assessment.owner} />
            </Flex>
          </Item>
        ))}
      </Flex>
    </TeamInsightsLayout>
  );
};

export default StrategyInsightsAssessments;
