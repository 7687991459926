import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditReportNameDialogQueryVariables = Types.Exact<{
  reportId: Types.Scalars['ID']['input'];
}>;


export type EditReportNameDialogQuery = { __typename: 'Query', report: { __typename: 'Report', id: string, name?: string | undefined } };


export const EditReportNameDialogDocument = gql`
    query EditReportNameDialog($reportId: ID!) {
  report(id: $reportId) {
    id
    name
  }
}
    `;

/**
 * __useEditReportNameDialogQuery__
 *
 * To run a query within a React component, call `useEditReportNameDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditReportNameDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditReportNameDialogQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useEditReportNameDialogQuery(baseOptions: Apollo.QueryHookOptions<EditReportNameDialogQuery, EditReportNameDialogQueryVariables> & ({ variables: EditReportNameDialogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditReportNameDialogQuery, EditReportNameDialogQueryVariables>(EditReportNameDialogDocument, options);
      }
export function useEditReportNameDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditReportNameDialogQuery, EditReportNameDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditReportNameDialogQuery, EditReportNameDialogQueryVariables>(EditReportNameDialogDocument, options);
        }
export function useEditReportNameDialogSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EditReportNameDialogQuery, EditReportNameDialogQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditReportNameDialogQuery, EditReportNameDialogQueryVariables>(EditReportNameDialogDocument, options);
        }
export type EditReportNameDialogQueryHookResult = ReturnType<typeof useEditReportNameDialogQuery>;
export type EditReportNameDialogLazyQueryHookResult = ReturnType<typeof useEditReportNameDialogLazyQuery>;
export type EditReportNameDialogSuspenseQueryHookResult = ReturnType<typeof useEditReportNameDialogSuspenseQuery>;
export type EditReportNameDialogQueryResult = Apollo.QueryResult<EditReportNameDialogQuery, EditReportNameDialogQueryVariables>;