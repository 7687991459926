import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOneThingCampaignQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetOneThingCampaignQuery = { __typename: 'Query', campaign: { __typename: 'OneThingCampaign', id: string, name: string, deadline?: Date | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean } } };


export const GetOneThingCampaignDocument = gql`
    query GetOneThingCampaign($id: ID!) {
  campaign: oneThingCampaign(id: $id) {
    id
    name
    orgUnit {
      id
      name
    }
    deadline
    timeLine {
      startDate
      endDate
    }
    owner {
      ...userLimitedProfile
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;

/**
 * __useGetOneThingCampaignQuery__
 *
 * To run a query within a React component, call `useGetOneThingCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneThingCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneThingCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneThingCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetOneThingCampaignQuery, GetOneThingCampaignQueryVariables> & ({ variables: GetOneThingCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneThingCampaignQuery, GetOneThingCampaignQueryVariables>(GetOneThingCampaignDocument, options);
      }
export function useGetOneThingCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneThingCampaignQuery, GetOneThingCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneThingCampaignQuery, GetOneThingCampaignQueryVariables>(GetOneThingCampaignDocument, options);
        }
export function useGetOneThingCampaignSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneThingCampaignQuery, GetOneThingCampaignQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneThingCampaignQuery, GetOneThingCampaignQueryVariables>(GetOneThingCampaignDocument, options);
        }
export type GetOneThingCampaignQueryHookResult = ReturnType<typeof useGetOneThingCampaignQuery>;
export type GetOneThingCampaignLazyQueryHookResult = ReturnType<typeof useGetOneThingCampaignLazyQuery>;
export type GetOneThingCampaignSuspenseQueryHookResult = ReturnType<typeof useGetOneThingCampaignSuspenseQuery>;
export type GetOneThingCampaignQueryResult = Apollo.QueryResult<GetOneThingCampaignQuery, GetOneThingCampaignQueryVariables>;