import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import type { SetOptional } from 'type-fest';
import { css } from '@emotion/react';
import * as yup from 'yup';

import type { FormProps } from 'shared/form/Form';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import { DatePickerField } from 'shared/components/DatePicker';
import { dateBefore1900Validation } from 'shared/utils/timeLine.utils';

const metricScoreFormId = 'metricScoreForm';

export type MetricScoreFormValues = {
  date: Date | null;
  forecast: number | null;
  status: number | null;
};

const createInitialValues = (): MetricScoreFormValues => ({
  status: null,
  forecast: null,
  date: new Date(),
});

export type MetricScoreFormProps = SetOptional<
  FormProps<MetricScoreFormValues>,
  'initialValues' | 'onSubmit'
>;

const MetricScoreForm = ({
  onSubmit,
  onError,
  initialValues = createInitialValues(),
  id = metricScoreFormId,
  ...restProps
}: MetricScoreFormProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    date: dateBefore1900Validation(t, true),
    status: yup
      .number()
      .required(t('metric.metricScoreForm.status.missing'))
      .typeError(t('metric.metricScoreForm.status.notANumber')),
    forecast: yup
      .number()
      .typeError(t('metric.metricScoreForm.forecast.notANumber'))
      .nullable(),
  });

  return (
    <Form<MetricScoreFormValues>
      {...restProps}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onError={onError}
      validationSchema={validationSchema}
      id={id}
      enableReinitialize={true}
    >
      {({ errors, touched }) => (
        <div css={css({ maxWidth: 240 })}>
          <FieldBox name={'date'} label={t('status.form.date.label')}>
            <Field
              name={'date'}
              component={DatePickerField}
              size={'small'}
              maxDate={new Date()}
              required={true}
            />
          </FieldBox>
          <FieldBox name={'status'} label={`${t('metric.currentScore')}:`}>
            <Field
              hasError={touched.status && !!errors.status}
              name={'status'}
              component={TextInputField}
              type={'number'}
              size={'small'}
              placeholder={t('metric.metricScoreForm.status.placeholder')}
            />
          </FieldBox>
          <FieldBox
            name={'forecast'}
            label={t('metric.metricScoreForm.forecast.label')}
          >
            <Field
              hasError={touched.forecast && !!errors.forecast}
              name={'forecast'}
              component={TextInputField}
              type={'number'}
              size={'small'}
              placeholder={t('metric.metricScoreForm.forecast.placeholder')}
            />
          </FieldBox>
        </div>
      )}
    </Form>
  );
};

export default MetricScoreForm;
