import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Field } from 'formik';
import * as yup from 'yup';

import Form from 'shared/form/Form';
import type { ReportInput } from 'types.graphql.generated';
import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';

type ReportNameFormValues = {
  id: string;
  name: string;
};

export const userFormId = 'user';

type Props = {
  initialValues: ReportNameFormValues;
  onSubmit: (values: ReportInput) => void;
};

const ReportNameForm = ({ initialValues, onSubmit }: Props) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (values: ReportNameFormValues) =>
      onSubmit({
        idToUpdate: values.id,
        name: values.name,
      }),
    [onSubmit],
  );

  const validationSchema = yup.object({
    name: yup.string().required(),
  });

  return (
    <Form<ReportNameFormValues>
      id={userFormId}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ hasError }) => (
        <Field
          component={OpenTextInputField}
          name={'name'}
          hasError={hasError('name')}
          placeholder={t('objective.objectiveForm.name.placeholder')}
          fontSize={'big'}
          maxLength={100}
          autoFocus={true}
        />
      )}
    </Form>
  );
};

export default ReportNameForm;
