import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { useLaunchStrategy } from 'strategy/useLaunchStrategy';
import type { Strategy } from 'types.graphql.generated';
import type { TeamAdapter } from 'team/TeamAdapter';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  strategyId: Strategy['id'];
  teamAdapter: TeamAdapter;
};

const LaunchStrategyConfirmationModal = ({
  isOpen,
  onClose,
  strategyId,
  teamAdapter,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();

  const { launchStrategy } = useLaunchStrategy({ strategyId, teamAdapter });

  const handleConfirm = useCallback(
    () => launchStrategy({ onSuccess }),
    [launchStrategy, onSuccess],
  );

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      {t('strategy.launchStrategyConfirmationModal.content')}
    </ConfirmationDialog>
  );
};

export default LaunchStrategyConfirmationModal;
