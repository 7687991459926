export const pestel = `
Do a PESTEL analysis.

As an intro, briefly explain what a PESTEL is and what it is used for (purpose). Max two sentences.

Create table 1 called PESTEL with three columns: i) Category, ii) Questions, iii) External factor

For i) Category, use: 🏛️ Political 💰 Economic 👥 Social 💻 Technological 🌍  Environmental ⚖️ Legal

For ii) questions, identify 2-6 external factors for each category that is relevant for {company name} and its strategy.

To identify external factors, use information from The top 10 ranked MBA business schools in the world (e.g. Harvard, MIT, Insead, LBS), top-tier strategy consultancies (e.g. McKinsey, BCG, Bain & Company), big four consultancies (e.g. PwC, EY, KPMG, Deloitte), highly ranked management publications (e.g. HBR, Forbes) and government agencies responsible for official statistics and relevant ministries.

For the external factors, use a combination of a) generic external factor topics, b) external factor topics specifically related to the company’s industry and c) external factor topics specifically related to the company and the company’s strategy. 

Generic external factor topics are:
Political Factors: Government stability and changes, Tax policies and tariffs, Trade policies and customs duties, Labor laws and regulations, Environmental laws and regulations, Level of corruption
Economic Factors: Economic growth and trends, Interest and inflation rates, Exchange rates, Unemployment rates, Consumer disposable income, Credit availability
Social Factors: Demographic changes, Cultural norms and values, Education level, Health awareness, Lifestyle trends, Income distribution
Technological Factors: Technological innovation and development, Automation and digitalization, Research and development (R&D), Technological infrastructure, Internet and mobile technology, Intellectual property protection
Environmental Factors: Climate change, Sustainability and environmental protection, Waste management, Energy consumption and sources, Natural resource management, Environmental laws and regulations
Legal Factors: Consumer protection laws, Labor laws, Health and safety regulations, Competition law, Intellectual property rights, Data protection laws

External factor topics specifically related to the company’s industry are:

Political Factors:
Potential new or changed policies related to the industry
Potential new or changed regulations related to the industry
Potential increase or decrease in public investments related to the industry discussed by government and politicians

Economic Factors:
Economic growth or decline in the company’s industry
Improved or worsen financial situation of select customer segments in the industry
Improved or worsen financial situation of select targeted customer segments of the company

Social Factors:
Changes in buying pattern and behaviours of the customer segments in the industry
Changes in buying pattern and behaviours of the targeted customer segments of the company

Technological Factors:
Technological innovation and development, automation and digitalization related to core processes, products or services related to the industry

Environmental Factors:
Changes in environmental laws and regulations specific to this industry

Legal Factors:
Changes in laws specific to this industry

Examples of potential external factor topics specifically related to the company and the company’s strategy are:
Political Factors: Politicians or political parties mentioning topics the last year that are specifically addressed in the company’s strategy
Economic Factors: Significant economic developments (bankruptancies or profit warnings) from top 5 major competitors or suppliers of the company
Social Factors: Changes in behaviors of stakeholders specifically mentioned in the company’s strategy
Technological Factors: New technologies announced by competitors related to a specific topic in the company’s strategy
Environmental Factors: Recent (last year) major environmental happenings in the geography where the company operates and that are related to a topic in the company’s strategy
Legal Factors: Recent (last year) outcomes of major, large law cases where involved parties have been top 5 competitors, top 5 customers or top 5 supplies of the company and the topics in the law case is related to the company´s strategy

For iii) External factor, answer each question with 1-3 specific external factors and phrase them as external trends including a brief description on how it can impact the company and mention concrete facts, numbers or happenings in () at the end of the sentence (max one sentence for each) including source.

Towards the end of the assessment based on how the external factors can impact the company, draft the following:

Strategy elements reaffirmed by the assessment:
1-3 long term goals, 1-3 key strategic choices they should make, and lastly list 3 potential strategic objectives for the next 3 years with 2 suggested metrics for each of these objectives - all closely aligned to existing strategy. 

Strategy elements to consider:
1-3 new long term goals, 1-3 key new strategic choices they should make, and lastly list 3 potential strategic objectives for the next 3 years with 2 suggested metrics for each of these objectives - all new and not mentioned in the existing strategy. 

Present the results in three tables: 1) PESTEL, 2) Strategy elements reaffirmed by the assessment, and 3) Strategy elements to consider
`;

export const porters = `
Do a PORTERS FIVE FORCES analysis.

As an intro, briefly explain what  PORTERS FIVE FORCES is and what it is used for (purpose). Max two sentences.
Industry players
1. Identify 5 new entrants in the industry the company operates that might be a threat and A brief description of why they are a potential threat to the company.
2. Identify 5 major suppliers in the industry the company operates that might have high bargaining power and why they have high bargaining power. List also 5 alternative suppliers of these major suppliers in the industry the company operates that potentially have lower bargaining power or have a low cost/low price strategy.
3. Identify 5 companies that are potential customers of the company and, both with high and lower bargaining power and describe briefly why each of them are relevant customers.
4. Identify 10 products or services from other companies that might substitute the products or services the company offers. Briefly describe each product or service with one sentence.
5. Assess the industry rivalry and exemplify with a list of 10 competitors and describe how each of them contribute to the intensity of competition.
Illustrate in table and use these emojis: for Threat of New Entrants use🚪🚶‍, for Bargaining Power of Suppliers use 🤝📦, for Bargaining Power of Buyers use 🛍️💪, for Threat of Substitute Products or Services use 🔄🧩, for Industry Rivalry use 🥊.
Then briefly summarise the competitive environment, copetition intensitivity and attractiveness of the market the company operates in.
`;

export const customerSegmentation = `
Do a CUSTOMER SEGMENTATION FRAMEWORK analysis. 

As an intro, briefly explain what  CUSTOMER SEGMENTATION FRAMEWORK is and what it is used for (purpose) - max two sentences.

Table 1) Customer Segments
1) Segments: Identify 1 to max 5 customer segments that are relevant customers for {company name}’s strategy. 
2) Characteristics: For each customer segment describe the following characteristics if customer segments are individuals (b2c): demographics, psychographics, behavior, and geography. If customer segments are companies (b2b) then describe characteristics such as typical company size (turnover and no. of employees), industry sectors and geography, 
3) Example “personas”: For b2c describe “personas” with name and a brief description of this persona or for b2b exemplify with real company names (1 to max 5) that are examples in this segment,
4) Needs: describe needs, pain points, motivations, jobs to be done (JTBD theory), and potential unique value proposition to each segment. 

Illustrate in table and use these emojis: for segments use 👥, for characteristics use 📋, for personas use 🙂, for needs use 💬 . 

Table 2) Strategy elements to consider
1-3 new long term goals 🚀, 1-3 key new strategic choices 🔀 they should make, and lastly list 3 potential strategic objectives 🎯 for the next 3 years with 2 suggested metrics for each of these objectives - all new and focusing on effective strategies to address the described customer segments. Create a table “Strategy elements to consider” with “Strategy” and “Consider the following” as headings of the columns. The elements in the rows of the “Consider the following” heading should be listed as bullet points.
`;

export const doubleMateriality = `
Do a  DOUBLE MATERIALITY ASSESSMENT. 
As an intro, briefly explain what  DOUBLE MATERIALITY ASSESSMENT is and what it is used for (purpose). Max two sentences. 
In a table, list the categories and then the relevant factors written in the terms of the company for each category. Use primarily existing strategy information, secondary information from the company’s annual reports or webpage, do not fill in the blanks, to identify top 5 factors written in the terms of the company for each category. To identify the top 5 factors for the categories, use the following:
The Environmental Factors category in a double materiality assessment includes key areas such as Climate Change, Pollution, Water and Marine Resources, Biodiversity and Ecosystems, and Resource Use and Circular Economy. Climate Change factors cover transition and physical risks, along with mitigation and adaptation efforts. Pollution encompasses the impact of air, water, and soil emissions, as well as waste management practices. Water and Marine Resources factors focus on water management and protecting marine ecosystems. Biodiversity and Ecosystems involve ecosystem impact and biodiversity protection initiatives, while Resource Use and Circular Economy emphasize resource efficiency and circular economy practices.
Social Factors are assessed through categories related to the company’s Own Workforce, Workers in the Value Chain, Affected Communities, and Consumers and End-users. For the Own Workforce, key factors include working conditions, diversity, equity, inclusion, and employee development. Workers in the Value Chain are evaluated on labor rights, fair compensation, and human rights adherence. Affected Communities considerations include community engagement, human rights impacts, and social contributions. Consumers and End-users are evaluated based on product safety and quality, customer privacy, and access and inclusivity.
The Governance Factors category addresses Business Conduct, Governance Structure, and Stakeholder Engagement. Business Conduct factors include anti-corruption and anti-bribery practices, fair competition, and tax compliance. Governance Structure is assessed by examining board diversity and functionality, executive compensation linked to ESG goals, and risk management practices. Stakeholder Engagement focuses on transparency and communication, stakeholder inclusion in decision-making, and alignment of sustainability strategy and targets with stakeholder expectations.
Lastly, Economic Factors in a double materiality assessment cover Economic Sustainability and Market Position and Competitiveness. Economic Sustainability factors include financial resilience, resource dependence, and investment in sustainable innovation. Market Position and Competitiveness factors evaluate adaptation to changing consumer demands and the company's market positioning related to sustainability. Together, these categories provide a comprehensive view of both financial and impact materiality, allowing companies to address risks and opportunities that are critical to their long-term sustainability and stakeholder value.
For each factor identified, add sections in the table that considers “financial” materiality and “impact” materiality. Use the information provided and industry relevant information to determine “financial” materiality and “impact” materiality on a scale from 1 low to 5 high and then add a section that describes why it was scored at this level.
Based on the findings suggest 3 key strategic choices and 5 potential strategic objectives and metrics with targets the company should consider to be included in the strategy based on the findings from the double materiality assessment (e.g. resources that should be prioritize, risks that should be managed, and opportunities that the company should capitalize on). 
`;

export const stakeholderAnalysis = `
Do a  STAKEHOLDER analysis. 

As an intro, briefly explain what a STAKEHOLDER is and what it is used for (purpose). Max 2 sentences. 

Table 1: Stakeholder Analysis
Do the stakeholder analysis and present it in a table, organized by stakeholder segments. For content of the table, use the following:

1) Segments: List all stakeholders, including internal groups (e.g., employees, management, the board) and external groups (e.g., owners, customers, suppliers, regulators, communities, and investors). Group stakeholders internal vs. external stakeholder. For external stakeholders list 1-5 specific organizations. Use emojis to illustrate the each stakeholder group.
2) Needs: Assess Stakeholder Interests and Needs, for each stakeholder group, document what they care about, how they are affected by the organization’s activities, and what they expect from the strategy.
3) Influence: Evaluate Stakeholder Influence and Impact: Determine each stakeholder's level of power and interest in the strategy.
4) Pri: Prioritize Stakeholders: Prioritise stakeholders based on high, medium or low for influence and high, medium and low for interests and based on this identify which stakeholders need active engagement, monitoring, or occasional updates based on their influence and interest.
5) Engagement Strategies: Develop Engagement Strategies: Design strategies for each stakeholder group to build alignment, manage expectations, or mitigate risks. 

Towards the end, define one strategic objective for stakeholder management (using max 4 words, starting with a verb). Include a description of the objective explaining why it is important, what we want to achieve in 3 years time and critical success factors to achieve the objective. Also suggest 2 metrics and 2 initiatives for this objective. 
`;

export const ansoff = `
Do a  ANSOFF MATRIX analysis. 

As an intro, briefly explain what a ANSOFF MATRIXis and what it is used for (purpose). Max 2 sentences. 

Table 1) Ansoff Matrix

For Market Penetration do the following:
Core Capabilities: Based on the company strategy, List as bullets Core Strengths and Resources the company have access to and are a strength relevant for this growth strategy.
Market: Identify 1-3 existing markets the company operates in that are relevant for this growth strategy and briefly describe the characteristics of this market. 
Customer Needs: Identify 1-3 customer needs that are relevant for this growth strategy and briefly describe the characteristics of these needs. 
Competitors: For the identified markets and customer needs, identify 1-3 competitors that are relevant and briefly describe the companies and how they are addressing these markets and needs.
Potential objectives: For this growth strategy define 1-2 objectives that should be considered in order to win in these markets also describing potential metrics and actions for the objectives.
Potential risks: For this growth strategy define 1-2 risks associated with this growth strategy also describing potential mitigating actions to manage these risks. 
Feasibility : Based on the identified internal core capabilities, assess whether this growth strategy is feasible (high, medium, low) and describe why the internal feasibility got this score. Based on the market, customer needs and competitors, assess whether this growth strategy is feasible (high, medium, low) and describe why the external feasibility got this score.  

For Product Development do the following:
Core Capabilities: Based on the company strategy, List as bullets Core Strengths and Resources the company have access to and are a strength relevant for this growth strategy.
Market: Identify 1-3 existing markets the company operates in that are relevant for this growth strategy and briefly describe the characteristics of this market. 
Customer Needs: Identify 1-3 customer needs that are relevant for this growth strategy and briefly describe the characteristics of these needs. 
Competitors: For the identified markets and customer needs, identify 1-3 competitors that are relevant and briefly describe the companies and how they are addressing these markets and needs.
Potential objectives: For this growth strategy define 1-2 objectives that should be considered in order to win in these markets also describing potential metrics and actions for the objectives.
Potential risks: For this growth strategy define 1-2 risks associated with this growth strategy also describing potential mitigating actions to manage these risks. 
Feasibility : Based on the identified internal core capabilities, assess whether this growth strategy is feasible (high, medium, low) and describe why the internal feasibility got this score. Based on the market, customer needs and competitors, assess whether this growth strategy is feasible (high, medium, low) and describe why the external feasibility got this score.  

For Market Development do the following:
Core Capabilities: Based on the company strategy, List as bullets Core Strengths and Resources the company have access to and are a strength relevant for this growth strategy.
Market: Identify 1-3 potential new markets that are relevant for this growth strategy and briefly describe the characteristics of this market.  
Customer Needs: Identify 1-3 customer needs that are relevant for this growth strategy and briefly describe the characteristics of these needs. 
Competitors: For the identified markets and customer needs, identify 1-3 competitors that are relevant and briefly describe the companies and how they are addressing these markets and needs.
Potential objectives: For this growth strategy define 1-2 objectives that should be considered in order to win in these markets also describing potential metrics and actions for the objectives.
Potential risks: For this growth strategy define 1-2 risks associated with this growth strategy also describing potential mitigating actions to manage these risks. 
Feasibility : Based on the identified internal core capabilities, assess whether this growth strategy is feasible (high, medium, low) and describe why the internal feasibility got this score. Based on the market, customer needs and competitors, assess whether this growth strategy is feasible (high, medium, low) and describe why the external feasibility got this score.  

For Diversification do the following:
Core Capabilities: Based on the company strategy, List as bullets Core Strengths and Resources the company have access to and are a strength relevant for this growth strategy.
Market: Identify 1-3 potential new markets that are relevant for this growth strategy and briefly describe the characteristics of this market.  
Customer Needs: Identify 1-3 customer needs that are relevant for this growth strategy and briefly describe the characteristics of these needs. 
Competitors: For the identified markets and customer needs, identify 1-3 competitors that are relevant and briefly describe the companies and how they are addressing these markets and needs.
Potential objectives: For this growth strategy define 1-2 objectives that should be considered in order to win in these markets also describing potential metrics and actions for the objectives.
Potential risks: For this growth strategy define 1-2 risks associated with this growth strategy also describing potential mitigating actions to manage these risks. 
Feasibility : Based on the identified internal core capabilities, assess whether this growth strategy is feasible (high, medium, low) and describe why the internal feasibility got this score. Based on the market, customer needs and competitors, assess whether this growth strategy is feasible (high, medium, low) and describe why the external feasibility got this score.  

Present this in four sections, each with a table. Use these emojis: for Market Penetration 📈🔍, for Product Development 🛠️🆕, for Market Development 🌍🚀, for Diversification 🎲🧩.  

Towards the end, assess which growth strategies are most relevant for this company.
`;

export const blueOcean = `
Do a  BLUE OCEAN analysis. 

As an intro, briefly explain what a blue ocean is and what it is used for (purpose). Max 2 sentences. 

Table 1) Current Market Landscace (“the red ocean”)
Analyze the Current Market Landscape: Map out the key players, customer segments, and the primary factors on which the industry competes (e.g., price, quality, features). Identify areas where competitors are heavily focused, and show where differentiation might be possible.
Present this in a table. Use these emojis: for key players use 👥, customer segments use 👥, primary factors use 🧬, competitive focus use 🔥, differentiation potential use 🌟.  

Table 2) Towards a Unique Value Proposition (“the blue ocean”)
Use the Four Actions Framework (ERRC Grid). To redefine industry boundaries, use the ERRC Grid to apply four actions: Eliminate: Identify which industry factors or features are unnecessary and can be removed. Reduce: Decide which factors can be reduced well below the industry standard. Raise: Determine which factors should be elevated beyond the current industry standard to add value. Create: Identify new factors or features that don’t currently exist in the industry but would create significant value.Present this in a table. Use these emojis: for eliminate use ❌, for reduce use ⬇️, for raise use ⬆️, for create use ✨. 
Summarise the findings in a new, clear and unique value proposition that doesn’t conform to standard industry practices.
`;

export const vrio = `
Do a VRIO analysis.
Create a table that lists the top 15 resources and capabilities of the company. To identify Key Resources and Capabilities, start by listing the unique resources and capabilities the company has based on the strategy and other publicly available information about the company. If there are more than 15 resources identified, choose those that contribute to the company’s competitive advantage. Resources and capabilites can include tangible assets (physical assets like technology, property, and machinery), intangible assets (brand reputation, intellectual property, data, or company culture), human resources (specialized skills, expertise, or leadership qualities of your team) and organizational capabilities (processes, know-how, or systems that enhance productivity).
Then, for each of the 15 selected resources and capability do a VRIO analysis, summarizing in a table where ✔️ means yes and ❌ means no and last column concludes the resource or capability’s contribution to competitive advantage: Type “Sustained Competitive Advantage” if a resource meets all four criteria, it can provide a long-term competitive edge. Type Temporary Competitive Advantage” if the resource meets the criteria for value, rarity, and sometimes imitability, it may provide an advantage, but this advantage is at risk if competitors catch up. Type “Parity” if a resource is valuable but not rare or inimitable, it may only maintain competitive parity, offering no unique edge. Type “Competitive Disadvantage” if a resource that are not valuable offer no competitive advantage and should be reevaluated.
Then, write a list of why each resource or capability was scored in this way and use evidence of why this was scored this way including source. If the argument can be exemplified with what competitors do, then exemplify what competitors do.
At the end, based on the findinga, write a clear customer value proposition statement for the company that sets it apart from its competitors.
`;

export const competitiveStrategies = `
Do an analysis of the generic strategies used in the company’s industry.

List competitive strategies based on Porter’s Generic Strategies and add relationship management and system lock-in (use emojies: for cost leadership use 💰, for differentiation use 💎, for focus (niche strategy) use 🔍, for relationship management use 🤝,  for system lock-in use 🔒) . Then identify the company’s top 10-20 main competitors and categorize them in a table into the four generic strategies. For each company listed, exemplify by showing their value proposition and key differentiator factors.
`;

export const portfolioAnalysis = `
Do a GE/McKinsey analysis. 
Identify the key business units or areas of business the company operates in. 
Then for each business unit do this: 
Create Table 1 Market Attractiveness. Evaluate how appealing a market is for a specific business unit. Assess the following factors in the table: Market Size: The total volume or value of the market. Be specific with numbers and list source. Market Growth Rate: The expected growth of the market over time. Be specific with numbers and list source. Industry Profitability: The average returns and margins within the industry. Be specific with numbers and list source. Competitors: List the 5-10 top competitors in the industry for this business unit and briefly describe how they position themselves. Competitive Intensity: The number and strength of competitors in the market. Be specific with numbers and list source. Technological Development: The level of innovation and technological advancements in the industry. Exemplify with concrete technological developments in the industry and list source. Regulatory Factors: Laws and regulations affecting the industry. Be specific for the industry and business unit and list source. Macroeconomic Trends: Economic conditions impacting the market, such as inflation and unemployment. Be specific with numbers and list source. 
Create Table 2 Business Unit's Competitive Strength. This dimension assesses how well-positioned a business unit is within its market. Assess the following factors in the table: Market Share: The business unit's portion of the total market. Be specific with numbers and list source. Market Share Growth: Changes in market share over time. Be specific with numbers and list source. Product Quality: The level of quality and differentiation in the products or services offered. Exemplify with concrete examples and list source. Distribution Network: The efficiency and reach of distribution channels. Exemplify with the distribution channels. Cost Structure: Cost-effectiveness compared to competitors. Use publicly available financial numbers for the company and it competitors and be specific with numbers and list source. Brand Strength: Customer loyalty and the brand's reputation in the market. Exemplify with the comnpanys brand and compare to other brands relevant in this business unit / part of the market. 
Based on the findings, suggest which business units should be invested in, developed, maintained, or divested and explain why and what.
`;

export const bscStrategyMap = `
Create a strategy map for the company’s strategy
As an intro, briefly explain what a strategy map is and what it is used for (purpose). Max 2 sentences. 
Use only existing information, do not fill in the blanks. Re-write the info provided in the strategy as strategic objectives. Always write objectives by starting with a verb. Pick the most relevant and list max three objectives per category. 

Present the results in a table format with fixed row and column headings to ensure each result is fully detailed. Organize the table as follows:
Columns:
1. BSC Perspective: Main category of information according to the balanced scorecard
2. Strategic objectives. List max 3 objectives per BSC Perspective.
Rows:
For each BSC Perspective, include fixed headings in the rows as follows:
* Society: Findings related to society value, community contribution, environment, diversity, health, ESG, sustainability.
* Financial: Findings related to profit, profitable growth, growth, costs, asset utilisation, existing revenue, new revenue, M&A, value at risk. If quantitative financial results are available use these.
* Customer & Market: Findings related to customer segments, markets, geographies, demand for product and services, customer relationships, brand value, value proposition, uniqueness, competitive advantage, positioning, customer feedback, market share.
* Internal Processes – Operational Excellence: Findings related to operations, processes, supply chain, production, distribution, operational risk.
* Internal Processes – Customer Relationship Management: Findings related to retention, account management, sales, sales pipeline, product and services, market and customer needs.
* Internal Processes – Growth & Innovation: Findings related to growth, innovation, new markets, new channels, new services, new products, research and development, innovation.
* Organizational Learning & Growth: Findings related to organizational enablers (capabilities), talent development, human capital, technology,  information capital, team, organisational capital, culture, values.
Use the following emojis for the fixed column and row headings: for Society use 🌍, for Financial use 💰, for Customer & Market use 💬, for Internal Processes – operational excellence use ⚙️, for internal processes – customer relationship management use 🤝, for internal processes – growth & innovation use 💡, for Organizational Learning & Growth use 🌱.
Then, suggest additional strategic objectives the company should consider (max 5) based on information that is missing, what is typical for the industry or its competitors and that is linked to the above categories with related objective examples. Present the results as a bulleted list.  Then suggest a differentiating value proposition statement and list 3-5 key strategic customer groups or markets the company should target.
`;

export const bscStrategyBoard = `
Create a balanced scorecard strategy board for the company’s strategy
As an intro, briefly explain what a strategy map is and what it is used for (purpose). Max 2 sentences. 
Use only existing information, do not fill in the blanks. Re-write the info provided in the strategy as strategic objectives, metrics and initiatives. Always write objectives by starting with a verb. Always start writing metrics with a unit (e.g. %, £, $, currency, #, ratio, score etc). Pick the most relevant and list max three objectives per category. 
Present the results in a table format with fixed row and column headings to ensure each result is fully detailed. Organize the table as follows:
Columns:
1. BSC Perspective: Main category of information according to the balanced scorecard
2. Strategic objectives. List max 3 objectives per BSC Perspective.
3. Metrics: List 1 lead and one lag metric specifically related to the listed objective.
4. Strategic initiatives: List potential initiatives linked to the identified objective. 
Rows:
For each BSC Perspective, include fixed headings in the rows as follows:
* Society: Findings related to society value, community contribution, environment, diversity, health, ESG, sustainability.
* Financial: Findings related to profit, profitable growth, growth, costs, asset utilisation, existing revenue, new revenue, M&A, value at risk. If quantitative financial results are available use these.
* Customer & Market: Findings related to customer segments, markets, geographies, demand for product and services, customer relationships, brand value, value proposition, uniqueness, competitive advantage, positioning, customer feedback, market share.
* Internal Processes – Operational Excellence: Findings related to operations, processes, supply chain, production, distribution, operational risk.
* Internal Processes – Customer Relationship Management: Findings related to retention, account management, sales, sales pipeline, product and services, market and customer needs.
* Internal Processes – Growth & Innovation: Findings related to growth, innovation, new markets, new channels, new services, new products, research and development, innovation.
* Organizational Learning & Growth: Findings related to organizational enablers (capabilities), talent development, human capital, technology,  information capital, team, organisational capital, culture, values.
Use the following emojis for the fixed column and row headings: for Society use 🌍, for Financial use 💰, for Customer & Market use 💬, for Internal Processes – operational excellence use ⚙️, for internal processes – customer relationship management use 🤝, for internal processes – growth & innovation use 💡, for Organizational Learning & Growth use 🌱.
Then, suggest additional strategic objectives the company should consider (max 5) based on information that is missing, what is typical for the industry or its competitors and that is linked to the above categories with related objective examples. Present the results as a bulleted list.  Then suggest a differentiating value proposition statement and list 3-5 key strategic customer groups or markets the company should target.
`;

export const strategyDiamond = `
Create a strategy diamond 💎 for the company’s strategy
As an intro, briefly explain what a strategy diamond is and what it is used for (purpose). Max 2 sentences. 
Use only existing information, do not fill in the blanks. Re-write the info provided in the strategy as objectives starting with verb, then adjective, then substantive (use 3-7 words, try to be specific). List max three objectives per category. Use tables for each category. Use the following emojis: for arenas use 🌍, for vehicles use 🚀, for differentiators use 🌟 , for staging use  🎯, for economic logic use  📊. 
For the elements use the following as potential topics to structure the strategic objectives: Arenas: Target Markets, Product/Service Offerings, Core Technologies, Vertical Integration, Distribution Channels. Vehicles: Organic Growth, Partnerships, Mergers and Acquisitions, Licensing and Franchising, Strategic Investments. Differentiators: Product/Service Quality, Customer Experience, Brand Image, Innovation, Price/Value. Staging: Pace of Expansion, Resource Allocation, Milestones and Phases, Market Entry Sequence, Adaptability. Economic Logic: Revenue Model, Cost Structure, Pricing Strategy, Profit Margins, Value Creation.
Then, suggest additional strategic objectives the company should consider (max 5) based on information that is missing, what is typical for the industry or its competitors and that is linked to the above categories with related objective examples.
`;

export const businessCanvas = `
Create a business canvas for the company’s strategy
As an intro, briefly explain what Alexander Osterwalder’s business canvas is and what it is used for (purpose). Max 2 sentences. 
Use primarily existing strategy information, secondary information from the company’s annual reports or webpage, do not fill in the blanks. Re-write the info provided in the strategy, annual report or webpage as objectives starting with verb, then adjective, then substantive (use 3-7 words, try to be specific) and map it to the topics in each category in the business canvas. List max three objectives per category. Use tables for each category. Use the following emojis: For Customer Segments use 👥, for Value Propositions use ⭐, for Channels use 🚀, for Customer Relationships use 🤝, for Revenue Streams use 💰, For Key Resources use 🧑‍💼, for Key Activities use ✔️, for Key Partnerships use 🔗, for Cost Structure use 📉.  
For the elements use the following as potential topics to structure the strategic objectives: Customer Segments: Demographics, Psychographics, Geographic Regions, Customer Needs, Buying Behavior. Value Propositions: Unique Features, Quality, Pricing, Brand, Customer Benefits. Channels: Direct Sales, Online Platforms, Retail Locations, Distributors, Customer Support. Customer Relationships: Personalized Service, Self-Service, Community Building, Loyalty Programs, Customer Feedback. Revenue Streams: Sales Revenue, Subscription Fees, Licensing, Advertising, Service Fees. Key Resources: Human Resources, Intellectual Property, Physical Assets, Financial Resources, Technology. Key Activities: Product Development, Marketing, Sales, Customer Service, Distribution. Key Partnerships: Suppliers, Alliances, Joint Ventures, Technology Partners, Distributors. Cost Structure: Fixed Costs, Variable Costs, Operational Costs, Cost of Goods Sold (COGS), Marketing Expenses.
Then, suggest additional strategic objectives the company should consider (max 5) based on information that is missing, what is typical for the industry or its competitors and that is linked to the above categories with related objective examples. 
`;

export const mAndA = `
Do an M&A analysis.

As an intro, briefly explain what an M&A analysis is and what it is used for (purpose). Max 2 sentences. 
Identify potential companies the company can merge with or acquire. For why this company briefly describe for each company strategic fit (how it is aligned to existing strategy), value alignment, and complementary strengths between the companies (how it supplements the company). List at least 10 companies, some from local market, some that are very similar, and some larger international companies. Present the results in a table.
`;

export const valueChain = `
Do a value chain analysis.

As an intro, briefly explain what Porter’s Value Chain is and what it is used for (purpose). Max 2 sentences.
Use primarily existing strategy information and secondary information from the company’s annual reports or webpage to rewrite the info as objectives. Where competitor names are needed, use real competitors in the industry based on current market data.Map it to the topics in each category in the business canvas. List max three objectives per category. Use tables for each category. Use the following emojis: For Inbound Logistics use 📥, for Operations use ⚙️, for Outbound Logistics use 📤, for Marketing and Sales use 📢, for Service use 🤝, for Human Resource Management use 🤲, for Technology Development use 💻, for Procurement use , for Finance use 💰, for Legal use ⚖️, for Strategy use 🎯, for Administration and General Management use 📋..  
For the elements use the following as potential topics to structure the strategic objectives: 
Primary activities in a value chain include Inbound Logistics, where product companies focus on sourcing, receiving, and managing raw material inventory, while service companies prioritize gathering skilled personnel, materials, and equipment for service delivery. Operations involves manufacturing, assembling, and quality control for product companies, while service companies concentrate on service delivery processes, customization, and quality assurance. Outbound Logistics includes warehousing, order processing, and shipping for products, whereas service companies manage scheduling, delivery, and follow-up. Marketing and Sales in product companies emphasize market research, promotion, and pricing strategies; in service companies, it involves brand positioning, client management, and tailored pricing models. Finally, Service involves post-sale support, such as installation and repairs for product companies, and ongoing customer support, feedback collection, and improvements for service companies to maintain satisfaction and loyalty.
Support activities complement these primary functions. Human Resource Management for product companies involves recruiting and training for production and R&D roles, while service companies focus on sourcing skilled service professionals and maintaining high standards. Technology Development includes R&D for products and process improvement, while service companies leverage tech to enhance service quality and efficiency. Procurement in product companies involves acquiring raw materials and equipment, whereas service companies focus on obtaining tools, facilities, and vendor contracts for effective service delivery. Finance covers budgeting, cost control, and revenue tracking for both types of companies. Legal considerations include intellectual property, regulatory compliance, and contracts for products, and labor laws, data privacy, and compliance in service industries. Strategy focuses on market positioning and differentiation for product companies, while service companies emphasize client relationships and scalability. Lastly, Administration and General Management oversee governance and coordination in product companies, while service firms prioritize client experience, quality control, and operational efficiency.
For each activity, written in terms relevant to the company, create a table with this information:
1. Activities: Identify 3-5 sub-activities for Primary and Support Activities and define these in terms relevant to the company. If the company operates in multiple sectors or have different business models, do a Value Chain Analysis for each part of the company (max 4).
2. Value Contribution: Analyze Each Activity for Value Contribution: Evaluate how each activity contributes to customer value or reduces costs. If data is available, use concrete facts or numbers.
3. Competitors: Research competitors in the industry to obtain real company names, use real company names for competitors based on industry context or current market data. Identify competitors of the company or that operates in the same industry sector and markets that is strong in this area. List the company names (max 3).
4. Strength or Weakness: Identify whether this a strength or a weakness. Use the symbol 🟢 for strength and 🔴 for weakness. To score, compare with competitors.
5. Potential Differentiation:  Identify whether this a potential for differentiation, parity or disadvantage. Use the symbol  🚀 for differentiation and write “🚀 Differentiation”, use the symbol ⚖️  for parity and write “⚖️ Parity”, and use the symbol ⚠️  for disadvantage and write “⚠️ Disadvantage” where appropriate. To score, compare with competitors.
6. Strategic Objectives: Identify Opportunities for Optimization and Differentiation and write them as strategic objectives - based on the findings, pinpoint opportunities to reduce costs, improve quality, speed up processes, or enhance customer satisfaction in ways that align with customer needs and preferences. Consider also how technology, process improvements, or strategic investments can add value. Write max 3 objectives per category.
7. Strategic Initiatives: Suggest Strategic Initiatives max 3 related to the strategic objective. This could involve reengineering processes, investing in new technologies, improving employee training, or reshaping the organization’s strategic focus.
8. KPIs: Suggest metrics to Monitor and Measure Performance related to the suggested strategic objectives.
At the end summarise the findings with the most impactful 5 strategic objectives to consider that can enhance operational efficiency, reduce costs, or differentiate the company’s product or service offering in the marketplace.
`;

export const playingToWin = `
Do an analysis based on the cascading choices Playing To Win framework developed by 
A.G. Lafley and Roger Martin.

As an intro, briefly explain what the Playing To Win framework  is and what it is used for (purpose). Max 2 sentences. 
Use primarily existing strategy information, secondary publicly available information from the company’s annual reports or webpage, if there are choices missing, highlight this as Unclear in current strategy and briefly describe what is unclear. Re-write the info provided in the strategy, annual report or webpage as strategic choice and map it to the topics in each category Playing To Win Framework. List max 6 strategic choices per category. Use tables for each category. Use the following emojis: For Winning Aspirations use 🚀, for Where to Play use 📍, for How to Win use 🥇, for Core Capabilities use 💪, for Management Systems use 📊.
For the elements use the following as potential topics to structure the strategic choices: 
Winning Aspiration: This element defines the company’s purpose and long-term vision, including what it wants to achieve and how it envisions success. Factors typically include the company’s mission statement, its ultimate goal in terms of market leadership, the impact it aims to have on its customers and industry, and a clear picture of what winning means to the organization.
Where to Play: This element identifies the specific markets, customer segments, and geographies the company aims to compete in, clarifying its focus. Factors include target customer demographics, regional focus, product or service categories, industry niches, and areas where the company sees the most potential for growth, profitability, or competitive advantage.
How to Win: This part focuses on defining the company’s unique value proposition and competitive advantage in the chosen markets. Factors here include differentiation strategies, pricing approaches, branding efforts, customer relationship management, and the company’s approach to outperforming rivals through quality, innovation, customer experience, or operational efficiency.
Core Capabilities: This element identifies the essential competencies, resources, and operational strengths needed to execute the strategy effectively. Typical factors include skilled personnel, technological resources, unique intellectual property, efficient processes, supply chain strengths, and any specific knowledge or expertise that the company leverages to excel in its market.
Management Systems: This element defines the organizational structure, processes, and metrics required to implement and monitor the strategy. Key factors include performance management systems, KPIs, incentive programs, decision-making frameworks, governance practices, and communication methods that align with the strategy, ensuring consistent progress and adaptability to change.

Create one table that exemplifies 5 to max 10 strategic choices relevant for the company,  written in their terms, by making interconnected, sequential decisions that builds a coherent strategy. 
At the end summarise the findings with the most impactful 5 strategic choices to consider that can guide the company towards a sustainable, competitive advantage.
`;

export const customerJourney = `
Do a customer journey assessment
As an intro, briefly explain what a customer journey is and what it is used for (purpose). Max 2 sentences. 
First, identify 3-5 customer personas based on the company’s business strategy (base the personas on demographics, behaviors, motivations, and goals). Briefly describe each persona with characteristics like age, profession, pain points, preferences, and reasons for choosing your product or service.  
For each persona, create a  customer Customer Journey Map in a table with stage, touchpoints, customer actions, emotions, pain points.  For Stage use 🎬, for Touchpoints use 👇, for Customer Actions use 🏃‍♂️for Emotions use ❤️, and for Pain Points use 💥.
At the end summarise the findings with the most impactful 5 strategic objectives to consider that can improve the customer journeys, avoid drop-offs and establish ways to exceed customer expectations.
`;

export const threeHorizons = `
Do a three horizons analysis based on McKinseys framework three horizons. 

As an intro, briefly explain what the McKinseys Tree horizons framework  is and what it is used for (purpose). Max 2 sentences. 
Use primarily existing strategy information, secondary information from the company’s annual reports or webpage, do not fill in the blanks but if there are choices missing, highlight this as Unclear in current strategy and briefly describe what is unclear. Re-write the info provided in the strategy, annual report or webpage as goals and map them to the three horizons in the framework. For each horizon, list the top most relevant goals and apply the 70/20/10 rule for the horizons, i.e. list 70% of the goals in horizon 1, list 20% of goals in horizon 2 and 10% in horizon 1.  Use tables for each category. Use the following emojis: For horizon 1 use ⛰️, for horizon 2 use 🏔️🏔️, for horizon 3 use 🏔️🏔️🏔️. 
To analyse and structure the strategy and translate them to strategic goals, use the following: 
Horizon 1: Core Business. This encompasses the activities that are most closely aligned to your current business. Most of your immediate revenue making activity will sit in horizon 1. If you were a retailer, this would include the day-to-day goals associated with selling, marketing and serving your product/customers. Your goals in horizon 1 will be mostly around improving margins, bettering existing processes and keeping cash coming in.
Horizon 2: Emerging Opportunities. Emerging opportunities are all about taking what you already have, and extending it out into new areas of revenue-deriving activity. There may be an initial cost associated with your horizon 2 activities, but these investments should return fairly reliably based on them being an extension of your current proven business model. Examples of this could include launching new product lines or expanding your business geographically or into new markets.
Horizon 3: Blue Sky. Your blue sky horizon 3 goals will be all about taking your business in new directions. These may be unproven and potentially unprofitable for a significant period of time. This would encompass things like research projects, pilot programmes or entirely new revenue lines that require significant upfront investment.

Present the findings in a table with horizons listed and strategic objectves for each horizon. 

Finally, summarise how well the current strategy covering the three horizons and if there is a balance in the 70/20/10 rule. Recommend any changes that could be considered for the strategy to better cover the 70/20/10 rule.
`;

export const swot = `
Do a SWOT analysis. 
As an intro, briefly explain what the SWOT framework  is and what it is used for (purpose). Max 2 sentences. 
Use primarily existing strategy information, secondary information from the company’s annual reports or webpage, publicly available information about the external market and customers related to opportunities and threats, do not fill in the blanks. Re-write the info provided in the strategy, annual report or webpage as either strength, weakness, opportunity or threat based on what is most relevant. 
Present the results in a table format with fixed column headings to ensure each result is fully detailed. Organize the table as follows:
Columns:
1. Strengths: internal strengths with brief explanation using positive language related to Internal attributes that give the organization a competitive advantage, typical factors: Strong brand reputation, skilled workforce, proprietary technology, financial stability, loyal customer base, unique resources, efficient processes, high market share, strong supply chain relationships, and effective leadership.
2. Weaknesses: Internal limitations that could hinder the organization’s performance, typical factors: Limited financial resources, outdated technology, poor brand perception, high employee turnover, inefficient processes, weak distribution channels, skill gaps, high operational costs, low product quality, and limited customer base.
3. Opportunities: External factors that could provide growth or competitive advantage, typical factors: Emerging markets, changes in consumer preferences, technological advancements, regulatory changes, market expansion opportunities, strategic partnerships, favorable economic trends, unmet customer needs, industry growth, and social or environmental shifts.
4. Threats: External factors that could negatively impact the organization’s performance, typical factors: New competitors, economic downturns, changing regulations, market saturation, technological disruptions, shifting consumer preferences, supply chain disruptions, rising costs, geopolitical risks, and negative public perception or industry trends.
Use the following emojis for the fixed column and row headings: For strengths use 💪, for weaknesses use ⚠️, for opportunities use 🚀 and for threats use 🛑.
Please ensure each row within a category provides concise, actionable insights.
At the end, suggest 4-8 strategic objectives focused on leverage strengths, minimize weaknesses, pursue opportunities, and prepare for potential threats. Present the results as bulletpoints.
`;

export const bscSwot = `
Do a SWOT analysis with the Balanced Scorecard perspectives. . 
As an intro, briefly explain what the SWOT framework and the balanced scorecard strategy execution system is and what it is used for (purpose). Max 2 sentences. 
Use primarily existing strategy information, secondary information from the company’s annual reports or webpage, publicly available information about the external market and customers related to opportunities and threats, do not fill in the blanks. Re-write the info provided in the strategy, annual report or webpage as either strength, weakness, opportunity or threat based on what is most relevant. 
Present the results in a table format with fixed row and column headings to ensure each result is fully detailed. Organize the table as follows:
Columns:
1. BSC Perspective: Main category of information according to the balanced scorecard
2. Strengths: internal strengths with brief explanation using positive language.
3. Weaknesses: internal weaknesses with brief explanation.
4. Opportunities: external market opportunities with brief explanation.
5. Threats: external market or macro threats with brief explanation.
Rows:
For each BSC Perspective, include fixed headings in the rows as follows:
* Society: Findings related to society value, community contribution, environment, diversity, health, ESG, sustainability.
* Financial: Findings related to profit, profitable growth, growth, costs, asset utilisation, existing revenue, new revenue, M&A, value at risk. If quantitative financial results are available use these.
* Customer & Market: Findings related to customer segments, markets, geographies, demand for product and services, customer relationships, brand value, value proposition, uniqueness, competitive advantage, positioning, customer feedback, market share.
* Internal Processes – Operational Excellence: Findings related to operations, processes, supply chain, production, distribution, operational risk.
* Internal Processes – Customer Relationship Management: Findings related to retention, account management, sales, sales pipeline, product and services, market and customer needs.
* Internal Processes – Growth & Innovation: Findings related to growth, innovation, new markets, new channels, new services, new products, research and development, innovation.
* Organizational Learning & Growth: Findings related to organizational enablers (capabilities), talent development, human capital, technology,  information capital, team, organisational capital, culture, values.
Use the following emojis for the fixed column and row headings: For strengths use 💪, for weaknesses use ⚠️, for opportunities use 🚀 and for threats use 🛑, or Society use 🌍, for Financial use 💰, for Customer & Market use 💬, for Internal Processes – operational excellence use ⚙️, for internal processes – customer relationship management use 🤝, for internal processes – growth & innovation use 💡, for Organizational Learning & Growth use 🌱.
Please ensure each row within a category provides concise, actionable insights."
At the end, suggest 4-8 strategic objectives focused on aligning our internal strengths and weaknesses with external opportunities and threats to achieve strategic goals. Present the results as bulletpoints.
`;

export const mckinsey = `
Do a McKinsey 7s framework analysis.

Organize the strategy information according to the McKinsey 7s framework and if information is missing comment on this. 
Strategy: Define the core focus or vision of the business that differentiates it in the market. Explain how the strategy aligns with market needs and long-term growth goals.
Structure: Describe the organizational structure (centralized or decentralized) and how it supports operational goals, decision-making, and overall efficiency within the company.
Systems: Outline the operational processes, quality controls, and systems in place that ensure efficiency, consistency, and customer satisfaction. Highlight any unique processes that support the company’s objectives.
Shared Values: Identify and describe the core values and beliefs that guide the organization’s culture. Explain how these values impact the company’s mission, relationships, and long-term goals."
Skills: Highlight the essential skills and expertise of the workforce that are critical to the company's success. Focus on competencies that give the organization a competitive advantage."
Staff: Describe the recruitment and retention strategies that the organization employs to build a talented and motivated workforce. Include any specific focus on skills, culture fit, or employee development."
Style: Define the leadership style and management approach within the company. Explain how this style supports the organization’s goals and influences the work culture.

Then, present in a table a summary, following the examples below:

Nokia
Strategy: Narrow down business to network infrastructure and other technologies.
Structure: A centralized organization focused on efficiency and cost control.
Systems: A strong focus on quality control and operational efficiency.
Shared Values: Quality, innovation, and customer satisfaction.
Skills: A highly skilled workforce with a deep understanding of technology and engineering.
Staff: A strong emphasis on hiring and retaining top talent.
Style: Collaborative leadership that aims to achieve continuous improvement.

Pepsi
Strategy: Become a global beverage company with a wide range of products.
Structure: A centralized organization that focuses on global branding and marketing.
Systems: A strong focus on product quality and customer satisfaction.
Shared Values: Optimism, refreshment, and fun.
Skills: A team of highly skilled workforce in marketing and sales.
Staff: Strong emphasis on hiring and retaining motivated and creative employees.
Style: Brand-driven leadership that aims to enhance global expansion.

Tesla
Strategy: Provide disruptive electric vehicle and renewable energy.
Structure: Flat, decentralized organization with a focus on innovation and speed.
Systems: Strong focus on quality control and customer satisfaction.
Shared Values: Innovation, sustainability, and excellence.
Skills: Highly skilled workforce with a deep understanding of technology and engineering.
Staff: Emphasis on hiring and retaining top talent.
Style: Visionary leadership with a focus on disruption.

IKEA
Strategy: Offers affordable and stylish furniture that is easy to assemble.
Structure: A flat and decentralized organization that focuses on employee empowerment.
Systems: Strong focus on cost control and efficient operations.
Shared Values: Affordability, simplicity, and sustainability.
Skills: A team of highly trained workforce in customer service and assembly.
Staff: A strong emphasis on hiring and retaining motivated and skilled employees.
Style: Collaborative leadership that encourages employee engagement.

Apple
Strategy: Product differentiation through innovative design and user experience.
Structure: Operates a flat and decentralized organization structure that focuses on cross-functional collaboration.
Systems: Business maintains a strong focus on quality control and customer satisfaction.
Shared Values: Simplicity, creativity, and perfectionism.
Skills: Employs a highly skilled workforce that maintains an understanding of technology and design.
Staff: Strong emphasis on quality hiring and retaining top talent.
Style: A visionary leader who focuses on innovation.

McDonald's
Strategy: A global fast-food chain with a standardized menu and efficient operations.
Structure: Centralized organization that focuses on efficiency and consistency.
Systems: A strong focus on quality control and operational efficiency.
Shared Values: Service, quality, cleanliness, and value.
Skills: A highly trained workforce in customer service and efficiency.
Staff: A strong emphasis on hiring and retaining motivated and efficient employees.
Style: Hands-on management with continuous improvement.

Conclude with a) how well are the seven elements of the McKinsey 7S Framework aligned in our organization, and b) what specific areas need improvement to enhance our strategic effectiveness.
`;

export const riskFramework = `
Identify relevant risk frameworks for the company.

List the 5 most relevant risk frameworks and draft 1-3 potential strategic objectives based on these frameworks.
`;

export const strategicRisks = `
Identify strategic risks.

Use the provided strategy info and apply the COSO Framework (Committee of Sponsoring Organizations). Do a risk assessment and list risks in a table with strategic themes (same as in the provided strategy info), risk, risk likelihood (high, medium, low), risk impact (high, medium, low), briefly describe the potential risk impact, suggest mitigating action.
`;

export const objectiveRisks = `
Identify risks per objective. 

Do a risk assessment for the objectives, listing the risks in a table with strategic themes, strategic objecrives, risk, risk impact and mitigating action.
`;

export const riskMap = `
Create a strategic risk map.

Sort objectives using Kaplan and Norton's strategy map framework (adding Society as a perspective in the strategy map at the top), and do a risk assessment for the objectives, listing the risks in a table with strategic themes, strategic objectives, risk, risk impact and mitigating action.
`;

export const unsdg = `
Create a list of all the United Nations Sustainability goals and list the company’s current contribution and suggest strategic objective to each of these goals and comment on goals where they don’t have any contribution.
Towards the end based on their contribution, draft a sustainability vision statement, 3 most relevant long term sustainability goals, 3 key strategic sustainability choices they should make, 1 potential sustainaility value proposition, 1 sustainability competitive advantage statement, and lastly list 3 potential strategic sustainability objectives for the next 3 years with 2 suggested metrics for each of these objectives.
`;

export const esg = `
List the company’s current contribution to the Environmental, Social, and Governance (ESG) principles and suggest strategic objective to each of these principles.
Towards the end based on their contribution, draft a sustainability vision statement, 3 long term sustainability goals (one for environment, one for social and one for governance), 3 key strategic sustainability choices they should make, 1 potential sustainability value proposition, 1 sustainability competitive advantage statement, and lastly list 3 potential strategic sustainability objectives for the next 3 years with 2 suggested metrics for each of these objectives. 
`;

export const objectives = `
Suggest a sustainability strategy map based on Kaplan and nortons framework.
Add Society as a perspective in the strategy map at the top.
`;

export const frameworks = `
List the 5 most relevant sustainability frameworks and draft 1-3 potential strategic objectives based on these frameworks. 
`;

export const taxonomy = `
Assess how EU's taxonomy impact their strategy and suggest three strategic objectives for the next 3 years.
`;

export const csrd = `
Assess how CSRD impact their strategy and suggest three strategic objectives for the next 3 years.
`;

export const tcfd = `
Assess how Task Force on Climate-related Financial Disclosures (TCFD) impact their strategy and suggest three strategic objectives for the next 3 years.
`;

export const sbti = `
Assess how Science-Based Targets (SBTi) impact their strategy and suggest three strategic objectives for the next 3 years.
`;

export const gri = `
Assess how Global Reporting Initiative (GRI) impact their strategy and suggest three strategic objectives for the next 3 years.
`;

export const twoByTwoScenario = `
Perform a scenario analysis. 
As an intro, briefly explain what a scenario analysis is and what it is used for (purpose). Max two sentences. 
Analysis Instructions. 
A. Identify Critical Uncertainties:
Do not present the results from this step but use the results in step 2.  
Identify two critical uncertainties that will most influence the company’s future and it strategy. These uncertainties should be high-impact and unpredictable. To identify the two critical uncertainties do a PESTEL analysis. For the PESTEL analysis, seek to answer questions like: Political and Legal: Will regulations tighten or remain lenient? Will governments support innovation or maintain the status quo? Economic: Will market demand grow significantly or stagnate/decline? Social: How will customer behavior evolve? Will preferences shift dramatically or remain stable? Technological: Will technological innovation accelerate or stagnate? How fast will digital transformation progress? Environmental: How will climate change impact stability? Identify two opposing outcomes to the identified uncertainties. 
B. Define the Axes: 
Assign each uncertainty to one axis on a 2x2 matrix. Label the axes with their opposing outcomes. 
C. Label Scenarios:
Label four distinct scenarios by combining uncertainty and opposing outcome. Number the scenario, give the scenario a descriptive and memorable name (use corporate lingo, but do not use offensive language), and write the uncertainty and opposing outcome in (). Present this in a 2x2 matrix. Include 2 emojis for each scenario that illustrates the memorable name. 
D. Summarize findings:
Summarize the findings from the SCENARIO analysis in four tables, one table for each scenario. The four tables should have the following fixed headings:
Columns:
1. Category - The main areas of analysis
2. Key Insights - A concise summary of the primary findings for each category 
For each Category, include fixed headings in the rows as follows:
1. Scenario - the descriptive, memorable name of the scenario including the emoji
2. Description - a vivid, relatable and rich story in the terms of the company descirbed with max 4 sentences. 
3. Stress test - a test of how well the company’s current strategy would fare in this scenario.
4. Opportunities - list 3 opportunities that emerge for the company in this scenario
5. Risks - list 3 risks that emerge for the company in this scenario
6. Actions - suggested 3 actions that would prepare the company for success in this scenario. 
Repeat this for each scenario. 
E. Strategic Insights:
Summarize the insights gained from the scenarios and prioritize the strategic initiatives or contingency plans your organization should develop to remain resilient across all scenarios.
`;

export const futureOpportunities = `
Do a business opportunity analysis. Use externally, publicly available information about the market(s) the company operates in and match it with the company strategy. 
Market 📈: identify and list as bullet points 5 growth opportunities based on growth in the sector the company operates in and that is fairly relevant to the strategy.
Customer 🧑‍🤝‍🧑: identify and list as bullet points 5 growth opportunities based on customer arising needs or changes in customer behavior in the sector the company operates in and that is fairly relevant to the strategy. 
Technology 💻: identify and list as bullet points 5 growth opportunities based on technological innovations in the sector the company operates in and that is fairly relevant to the strategy.

FINANCIAL
a) Overall Financial Analysis
Button: FINANCIAL HEALTH: How do we perform financially? 
Norwegian: FINANSIELL HELSE: Hvordan er våre finansielle resultater? 
1. Do a financial performance 🚥 analysis.
Analyze the financial performance of the company using information from their annual reports, quarterly reports, and other public sources. Assess revenues, costs, profitability, and growth trends and be specific by using numbers from available company information.
2. Do an analysis of financial health 🌡️.
Based on publicly available information, provide an overview of key financial metrics such as EBITDA, net income, revenue growth, and debt ratio for the company. Be specific by using numbers from available company information. What does this indicate about their financial health?
3. Do a P&L Analysis
Evaluate the company's financial P&L performance and identify opportunities for cost reduction or revenue growth. Be specific by using numbers from available company information and suggest concrete opportunities for cost reduction or revenue growth with targets.
4 Do a valuation. 
Conduct an overarching financial valuation of the company and each business area (be specific and include numbers from financial statements or the strategy) based on the strategy and combined with information from publicly available financial statements. 
5. Suggest strategic metrics.
Based on the findings, suggest 2 financial P&L KPIs to improve financial health with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
6. Then briefly summarize the findings and answer the question «How do we perform financially and how well does this support our strategy execution now and in the future?»

b) Financial Competitor Analysis
Button: FINANCIAL BENCHMARK: How do we compare to our competitors?
Norwegian: FINANSIELL BENCHMARK: Hvordan leverer vi sammenlignet med konkurrenter?
1. Do a benchmark of financial performance.
Based on publicly available information and strategy, how does the company’s financial performance compare to their two major competitors Similar to the company (be specific and mention the company or organization´s name) based on key metrics such as revenue, market share, and profit margins? Be specific by using numbers from available company information.
2. Do a benchmark of cost structure. 
Using public information, analyze the company's cost structure compared to its competitors. Be specific by using numbers from available company information. What strategic advantages or disadvantages does this reveal?
3. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs related to revenue, market share or profit margins with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
4. Then briefly summarize the findings and answer the question «How do we compare to our competitors and, based on this, what should we focus on improving financially?»


c) Revenue
Button: REVENUE: Where do we generate the most income? 
Norwegian: INNTEKTER: Hvor får vi de viktigste inntektene våre fra?
1. Analyze revenue streams. 
Based on publicly available information and strategy, which segments, services or products generate the most income, and how has this evolved over time? Be specific by using numbers from available company information combined with info in the strategy.
2. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs that focus on segments, products or services with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
4. Then briefly summarize the findings and answer the question «Where do we generate the most income and what financial metrics should we focus on to ensure growth in segments, products or services going forward?»
`;

export const financialHealth = `
1. Do a financial performance 🚥 analysis.
Analyze the financial performance of the company using information from their annual reports, quarterly reports, and other public sources. Assess revenues, costs, profitability, and growth trends and be specific by using numbers from available company information.
2. Do an analysis of financial health 🌡️.
Based on publicly available information, provide an overview of key financial metrics such as EBITDA, net income, revenue growth, and debt ratio for the company. Be specific by using numbers from available company information. What does this indicate about their financial health?
3. Do a P&L Analysis
Evaluate the company's financial P&L performance and identify opportunities for cost reduction or revenue growth. Be specific by using numbers from available company information and suggest concrete opportunities for cost reduction or revenue growth with targets.
4 Do a valuation. 
Conduct an overarching financial valuation of the company and each business area (be specific and include numbers from financial statements or the strategy) based on the strategy and combined with information from publicly available financial statements. 
5. Suggest strategic metrics.
Based on the findings, suggest 2 financial P&L KPIs to improve financial health with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
6. Then briefly summarize the findings and answer the question «How do we perform financially and how well does this support our strategy execution now and in the future?»
`;

export const financialBenchmark = `
1. Do a benchmark of financial performance.
Based on publicly available information and strategy, how does the company’s financial performance compare to their two major competitors Similar to the company (be specific and mention the company or organization´s name) based on key metrics such as revenue, market share, and profit margins? Be specific by using numbers from available company information.
2. Do a benchmark of cost structure. 
Using public information, analyze the company's cost structure compared to its competitors. Be specific by using numbers from available company information. What strategic advantages or disadvantages does this reveal?
3. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs related to revenue, market share or profit margins with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
4. Then briefly summarize the findings and answer the question «How do we compare to our competitors and, based on this, what should we focus on improving financially?»
c) Revenue
Button: REVENUE: Where do we generate the most income? 
Norwegian: INNTEKTER: Hvor får vi de viktigste inntektene våre fra?
1. Analyze revenue streams. 
Based on publicly available information and strategy, which segments, services or products generate the most income, and how has this evolved over time? Be specific by using numbers from available company information combined with info in the strategy.
2. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs that focus on segments, products or services with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
4. Then briefly summarize the findings and answer the question «Where do we generate the most income and what financial metrics should we focus on to ensure growth in segments, products or services going forward?»
`;

export const revenue = `
1. Analyze revenue streams. 
Based on publicly available information and strategy, which segments, services or products generate the most income, and how has this evolved over time? Be specific by using numbers from available company information combined with info in the strategy.
2. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs that focus on segments, products or services with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
4. Then briefly summarize the findings and answer the question «Where do we generate the most income and what financial metrics should we focus on to ensure growth in segments, products or services going forward?»
`;

export const financialGrowth = `
1. Analyze financial growth potential.
Based on publicly available information and strategy, provide an assessment of the company's historical growth (revenues, margins, earnings) and their ability to sustain or improve this growth in the future given current market conditions. Be specific by using numbers from available company information. 
2. Analyze how macroeconomic factors have impacted financial performance.
Based on publicly available information and strategy, how have macroeconomic factors such as interest rate changes, currency rates and inflation influenced the company's financial performance over the past two years and how it is likely to impact os in the future? Be specific by using numbers from available company information.
3. Analyze the the current financial assumptions, key financial metrics, and financial forecasts of the strategic themes and objectives in the company strategy. Be specific by using numbers from available company information.
4. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
5. Then briefly summarize the findings and answer the question «What is our potential for growth?»
`;

export const financialRisk = `
1. Analyze financial risks.
Based on publicly available information and strategy, identify the primary strategic financial risks for the company based on their debt levels, market exposure, and reliance on specific revenue sources. Be specific by using numbers from available company information.
2. Analyze financial robustness.
Based on publicly available information and strategy, what do public figures indicate about the company's ability to manage economic downturns or crises? Be specific by using numbers from available company information.
3. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
4. Then briefly summarize the finding and answer the question «What is our financial robustness and how well does it support our strategy?»
`;

export const stratex = `
1. Analyze financial performance and strategy.
Based on publicly available information and strategy, how does the company's financial performance (be specific by using numbers) support their strategic objectives, based on publicly available data?
2. Analyze investments for growth.
Based on publicly available information and strategy, how do the company's investments impact their short-term cash flow and long-term growth potential defined in their strategy? Be specific by using numbers from available company information.
3. Analyze capital structure.
Based on publicly available information and strategy, Assess the capital structure in light of the outlined strategy, including future investment levels, borrowing capacity, and expected dividend capacity. Be specific by using numbers from available company information.
3. Suggest strategic metrics.
Based on the findings, suggest 2 financial KPIs related to investments and financial performance with targets for the next three years and link it to an objective in the strategy in a table. The table should have the following fixed headings:
Columns:
* Strategic objective - strategic objective from the company’s strategy, if no relevant strategic objective is identified suggest a new strategic objective and mark it as “new”
* KPI - the suggested metric 
* Target Y1 - suggested target for next year
* Target Y2 - suggested target for year 2
* Target Y3 - suggested target for year 3
Repeat once.
4. Then briefly summarize the findings and answer the question «How does our financial situation support our strategy?»
`;
