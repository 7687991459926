import { forwardRef, type ComponentType, type Ref, type SVGProps } from 'react';
import styled from '@emotion/styled';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Button as AriaButton } from 'react-aria-components';
import type { AriaButtonProps } from 'react-aria';

import { transientOptions } from 'shared/utils/emotion.utils';

export type ButtonVariant = 'contained' | 'outlined' | 'icon' | 'simple';

type ButtonColor = 'primary' | 'mono';
type ButtonFontSize = 'medium' | 'small';
type ButtonFontWeight = 'light' | 'bold';
type ButtonIconPosition = 'start' | 'end';
type ButtonIconSize = 'medium' | 'small';

const StyledButton = styled(AriaButton, transientOptions)<{
  $color: ButtonColor;
  $fontSize: ButtonFontSize;
  $fontWeight: ButtonFontWeight;
  $height: string;
  $icon: ButtonProps['icon'];
  $iconPosition: ButtonIconPosition;
  $iconSize: ButtonProps['iconSize'];
  $variant: ButtonVariant;
}>`
  background: unset;
  border: unset;
  cursor: unset;
  &:focus {
    outline: unset;
  }
  &:active {
    color: unset;
  }

  align-items: center;
  border-radius: 0.75em;
  cursor: pointer;
  display: flex;
  font-size: ${(props) => (props.$fontSize === 'medium' ? '1rem' : '.857rem;')};
  font-weight: ${(props) => (props.$fontWeight === 'bold' ? 700 : 400)};
  gap: 0.5rem;
  height: ${(props) => props.$height};
  justify-content: center;
  text-align: center;
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled) {
    opacity: 0.8;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.primary};
  }

  svg {
    ${(props) =>
      props.$iconSize === 'small'
        ? { height: '1em', width: '1em' }
        : { height: '1.5em', width: '1.5em' }}
  }

  ${(props) => {
    switch (props.$variant) {
      case 'contained':
        return `
          padding: .75em 1.5em;
          background: ${
            props.$color === 'primary'
              ? props.theme.color.primary
              : props.theme.color.white
          };
          color: ${props.theme.color.white};
          &:disabled {
            background: ${props.theme.color.neutral3};
            color: ${props.theme.color.white};

            svg {
              color: ${props.theme.color.white};
            }
          }
        `;
      case 'outlined':
        return `
          padding: .75em 1.5em;
          background: ${props.theme.color.white};
          border: 1px solid ${props.theme.color.strokeDark};
          color: ${props.theme.color.typoPrimary};
          &:disabled {
            border-color: ${props.theme.color.strokeLight};
            color: ${props.theme.color.neutral3};

            svg {
              color: ${props.theme.color.neutral3};
            }
          }
          &[aria-pressed="true"] {
            border-color: ${props.theme.color.black};
          }
        `;
      case 'icon':
        return `
          gap: 0;
          height: 2.5rem;
          width: 2.5rem;
          border: 1px solid ${props.theme.color.strokeDark};
          color: ${
            props.$color === 'primary'
              ? props.theme.color.primary
              : props.theme.color.typoPrimary
          };
          &:disabled {
            border-color: ${props.theme.color.strokeLight};
            color: ${props.theme.color.neutral3};

            svg {
              color: ${props.theme.color.neutral3};
            }
          }
        `;
      case 'simple':
        return `
          color: ${
            props.$color === 'primary'
              ? props.theme.color.primary
              : props.theme.color.typoPrimary
          };
          &:disabled {
            color: ${props.theme.color.neutral3};

            svg {
              color: ${props.theme.color.neutral3};
            }
          }
        `;
    }
  }}

  ${(props) =>
    props.$icon &&
    `flex-direction: ${
      props.$iconPosition === 'start' ? 'row-reverse' : 'row'
    }`}
`;

const ButtonContent = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
`;

const ButtonIcon = styled('div', transientOptions)<{
  $iconSize: ButtonProps['iconSize'];
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
`;

export type ButtonProps = AriaButtonProps & {
  className?: string;
  color?: ButtonColor;
  css?: SerializedStyles;
  fontSize?: ButtonFontSize;
  fontWeight?: ButtonFontWeight;
  form?: string;
  fullWidth?: boolean;
  hasPadding?: boolean;
  height?: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  iconPosition?: ButtonIconPosition;
  iconSize?: ButtonIconSize;
  variant?: ButtonVariant;
};

const Button = (
  {
    variant = 'contained',
    color = 'primary',
    icon: Icon,
    children,
    iconPosition = 'end',
    iconSize = 'medium',
    fontSize = 'medium',
    fontWeight = 'bold',
    height = '3.125rem;',
    hasPadding = true,
    fullWidth,
    ...restProps
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>,
) => (
  <StyledButton
    type={'button'}
    {...restProps}
    $color={color}
    $variant={variant}
    $icon={Icon}
    $iconSize={iconSize}
    $iconPosition={iconPosition}
    $fontSize={fontSize}
    $fontWeight={fontWeight}
    $height={height}
    css={[
      fullWidth && css({ width: '100%' }),
      !hasPadding && css({ paddingTop: 0, paddingBottom: 0 }),
    ]}
    ref={ref}
  >
    <ButtonContent>{children}</ButtonContent>
    {Icon && (
      <ButtonIcon $iconSize={iconSize}>
        <Icon aria-hidden={true} />
      </ButtonIcon>
    )}
  </StyledButton>
);

export default forwardRef<HTMLButtonElement, ButtonProps>(Button);
