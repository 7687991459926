import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import type { Strategy } from 'types.graphql.generated';
import { useDeleteStrategy } from 'strategy/useDeleteStrategy';

type DeleteStrategyConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  strategy: Pick<Strategy, 'id' | 'name'>;
};

const DeleteStrategyConfirmationModal = ({
  strategy,
  onSuccess,
  isOpen,
  onClose,
}: DeleteStrategyConfirmationModalProps) => {
  const { t } = useTranslation();
  const { deleteStrategy } = useDeleteStrategy();

  const handleConfirm = useCallback(
    () => deleteStrategy({ strategy: strategy, onSuccess }),
    [deleteStrategy, onSuccess, strategy],
  );

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      {t('strategy.deleteStrategyConfirmationModal.content')}
    </ConfirmationDialog>
  );
};

export default DeleteStrategyConfirmationModal;
