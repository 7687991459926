import { useTranslation } from 'react-i18next';

import type { MetricScoreFormProps } from 'metric/MetricScoreForm';
import MetricScoreForm from 'metric/MetricScoreForm';
import Heading from 'shared/components/Heading';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';
import Flex from 'shared/components/Flex';
import type { MetricTarget } from 'types.graphql.generated';

import styles from './MetricStatusWizardScore.module.scss';
import FieldValue from '../FieldValue';
import type { MetricStatusWizardMetricFragment } from '../MetricStatusWizard.graphql.generated';

type MetricStatusWizardScoreProps = Pick<
  MetricScoreFormProps,
  | 'id'
  | 'initialValues'
  | 'onSubmit'
  | 'onChange'
  | 'onError'
  | 'validateOnMount'
> & {
  currentTarget?: Pick<MetricTarget, 'targetDate' | 'targetValue'>;
  metric: MetricStatusWizardMetricFragment;
};

const MetricStatusWizardScore = ({
  metric,
  currentTarget,
  ...restProps
}: MetricStatusWizardScoreProps) => {
  const { t } = useTranslation();

  const renderForm = () => (
    <>
      <Heading level={3}>
        {t('metric.metricStatusWizard.steps.score.heading')}
      </Heading>

      <Flex direction={'column'} gap={8}>
        <FieldValue
          label={t('metric.metricStatusWizard.field.metric')}
          value={metric.name}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.description')}
          value={metric.description}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.unitOfMeasure')}
          value={metric.unitOfMeasure}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.endTarget')}
          value={metric.targetValue}
          date={metric.timeLine.endDate}
        />
        {currentTarget && (
          <FieldValue
            label={t('metric.metricStatusWizard.field.periodTarget')}
            value={currentTarget.targetValue}
            date={currentTarget.targetDate}
          />
        )}
        <FieldValue
          label={t('metric.metricStatusWizard.field.previousScore')}
          value={metric.currentMetricStatus?.statusValue}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.previousForecast')}
          value={metric.currentMetricStatus?.forecastValue}
        />
      </Flex>

      <MetricScoreForm {...restProps} className={styles.form} />
    </>
  );

  const renderCardInfo = () => (
    <InfoCard
      title={t('metric.metricStatusWizard.steps.score.infoCard.heading')}
      contentKey={'metric.metricStatusWizard.steps.score.infoCard.content'}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderCardInfo}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default MetricStatusWizardScore;
