import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import StatusForm from 'shared/status/StatusForm';
import type { StatusFormProps } from 'shared/status/StatusForm';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';
import Flex from 'shared/components/Flex';
import type { MetricTarget } from 'types.graphql.generated';

import FieldValue from '../FieldValue';
import type { MetricStatusWizardMetricFragment } from '../MetricStatusWizard.graphql.generated';

type MetricStatusWizardStatusProps = Pick<
  StatusFormProps,
  'id' | 'initialValues' | 'onSubmit' | 'onChange' | 'onError'
> & {
  completeStatus?: boolean;
  currentTarget?: Pick<MetricTarget, 'targetDate' | 'targetValue'>;
  forecastValue: number | null;
  metric: MetricStatusWizardMetricFragment;
  statusDate?: Date | null;
  statusValue: number | null;
};

const MetricStatusWizardStatus = ({
  initialValues,
  onSubmit,
  onError,
  metric,
  id,
  onChange,
  statusDate,
  statusValue,
  currentTarget,
  forecastValue,
  completeStatus,
}: MetricStatusWizardStatusProps) => {
  const { t } = useTranslation();

  const renderForm = () => (
    <StatusForm
      heading={t('metric.metricStatusWizard.steps.status.heading')}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validateOnBlur={true}
      onError={onError}
      id={id}
      completeStatus={completeStatus}
      completeLabel={t('metric.metricStatusWizard.steps.status.completeLabel')}
      showStatusDateField={false}
    >
      <Flex direction={'column'} gap={8}>
        <FieldValue
          label={t('metric.metricStatusWizard.field.metric')}
          value={metric.name}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.description')}
          value={metric.description}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.unitOfMeasure')}
          value={metric.unitOfMeasure}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.endTarget')}
          value={metric.targetValue}
          date={metric.timeLine.endDate}
        />
        {currentTarget && (
          <FieldValue
            label={t('metric.metricStatusWizard.field.periodTarget')}
            value={currentTarget.targetValue}
            date={currentTarget.targetDate}
          />
        )}
        {statusDate && (
          <FieldValue
            label={t('metric.metricStatusWizard.field.statusDate')}
            value={format(statusDate, 'P')}
          />
        )}
        <FieldValue
          label={t('metric.metricStatusWizard.field.currentScore')}
          value={statusValue}
        />
        <FieldValue
          label={t('metric.metricStatusWizard.field.currentForecast')}
          value={forecastValue}
        />
      </Flex>
    </StatusForm>
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t('metric.metricStatusWizard.steps.status.infoCard.heading')}
      contentKey={'metric.metricStatusWizard.steps.status.infoCard.content'}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default MetricStatusWizardStatus;
