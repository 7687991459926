import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import {
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  lastDayOfMonth,
  lastDayOfQuarter,
  lastDayOfYear,
  nextFriday,
} from 'date-fns';

import { useForm } from 'shared/form/Form';
import ButtonMenu from 'shared/components/ButtonMenu/ButtonMenu';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';

import type { MetricFormValues, PeriodicTargetItem } from '../MetricForm';

type Frequency = 'weekly' | 'monthly' | 'quaterly' | 'yearly';

const getPeriodEndingByFrequency = {
  weekly: nextFriday,
  monthly: lastDayOfMonth,
  quaterly: lastDayOfQuarter,
  yearly: lastDayOfYear,
};

const getNextPeriodByFrequency = {
  weekly: addWeeks,
  monthly: addMonths,
  quaterly: addQuarters,
  yearly: addYears,
};

function getDatesByFrequency(
  frequency: Frequency,
  fromDate?: Date | null,
  endDate?: Date | null,
): Date[] {
  if (!fromDate || !endDate) return [];

  const getPeriodEnd = getPeriodEndingByFrequency[frequency];
  const getNextPeriod = getNextPeriodByFrequency[frequency];

  const periodEndDate = getPeriodEnd(fromDate);

  if (periodEndDate > endDate) return [];

  const nextPeriodDate = getNextPeriod(fromDate, 1);

  return [
    periodEndDate,
    ...getDatesByFrequency(frequency, nextPeriodDate, endDate),
  ];
}

const AddTargetsByFrequencyButton = () => {
  const { t } = useTranslation();
  const { values, setValues } = useForm<MetricFormValues>();

  const startDate = values.timeLine.startDate;
  const endDate = values.timeLine.endDate;

  const addTargets = useCallback(
    (newTargets: PeriodicTargetItem[]) => {
      setValues(
        {
          ...values,
          periodicTargets: [...values.periodicTargets, ...newTargets],
        },
        false,
      );
    },
    [setValues, values],
  );

  const addPeriodsByFrequency = useCallback(
    (frequency: Frequency) => {
      const newTargets = getDatesByFrequency(frequency, startDate, endDate).map(
        (targetDate) =>
          ({ targetDate, targetValue: '' } satisfies PeriodicTargetItem),
      );

      addTargets(newTargets);
    },
    [addTargets, endDate, startDate],
  );

  const items = useMemo(
    () => [
      {
        label: t('frequency.weekly'),
        onAction: () => addPeriodsByFrequency('weekly'),
      },
      {
        label: t('frequency.monthly'),
        onAction: () => addPeriodsByFrequency('monthly'),
      },
      {
        label: t('frequency.quaterly'),
        onAction: () => addPeriodsByFrequency('quaterly'),
      },
      {
        label: t('frequency.yearly'),
        onAction: () => addPeriodsByFrequency('yearly'),
      },
    ],
    [addPeriodsByFrequency, t],
  );

  const handleAddSingleTarget = useCallback(
    () => addTargets([{ targetDate: null, targetValue: '' }]),
    [addTargets],
  );

  return (
    <ButtonMenu
      items={items}
      isMenuDisabled={!startDate || !endDate}
      onPress={handleAddSingleTarget}
    >
      <PlusIcon />
      {t('metric.metricForm.periodicTargets.control.add')}
    </ButtonMenu>
  );
};

export default AddTargetsByFrequencyButton;
