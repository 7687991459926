import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import { RichTextField } from 'shared/components/RichTextInput';
import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';
import Flex from 'shared/components/Flex';

type Props = {
  overlayElement: HTMLDivElement | null;
};

const AssessmentFormFields = ({ overlayElement }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex
      direction={'column'}
      grow={1}
      gap={'1rem'}
      css={css({ height: '100%' })}
    >
      <Field
        component={OpenTextInputField}
        name={'title'}
        placeholder={t('assessment.fields.title.placeholder')}
        fontSize={'big'}
        maxLength={100}
        autoFocus={true}
      />
      <Field
        name={'content'}
        component={RichTextField}
        popupContainerParent={overlayElement}
      />
    </Flex>
  );
};

export default AssessmentFormFields;
