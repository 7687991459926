import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash';

import { useStatusFilter } from 'shared/status/useStatusFilter';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useUserFilter } from 'user/useUserFilter';
import { useDateFilter } from 'shared/hooks/useDateFilter';
import { usePriorityFilter } from 'shared/priority/usePriorityFilter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/components/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import { useShowChildrenFilter } from 'shared/components/ShowChildrenFilterButton/useShowChildrenFilter';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/components/CollaboratingTeamsFilter';
import { useBatchedSearchParams } from 'shared/hooks/useBatchedSearchParams';

import type { StrategyObjectivesMetricsContextValue } from './StrategyMetricsProvider.context';
import { StrategyObjectivesMetricsContext } from './StrategyMetricsProvider.context';
import type { StrategyMetricsThemeFragment } from './StrategyMetricsProvider.graphql.generated';
import {
  useStrategyMetricsOrgQuery,
  useStrategyMetricsOrgUnitQuery,
} from './StrategyMetricsProvider.graphql.generated';
import {
  filterObjectivesByStatusIndicator,
  filterObjectivesByUser,
  filterObjectivesByDates,
  getObjectivesOwners,
  filterObjectiveMetricsByPriority,
  filterObjectivesByCollaboratingTeams,
} from './StrategyMetricsProvider.utils';
import type { StrategyMetricsObjective } from './StrategyMetricsProvider.type';

type StrategyMetricsProviderProps = PropsWithChildren<object>;

const StrategyMetricsProvider = ({
  children,
}: StrategyMetricsProviderProps) => {
  const { clearSearchParam } = useBatchedSearchParams();

  const { teamAdapter } = useTeamAdapter();

  const { orgUnit } = useOrgUnit();

  const handleApolloError = useHandleError();

  const { data: dataOrg, loading: isDataLoadingOrg } =
    useStrategyMetricsOrgQuery({
      fetchPolicy: 'cache-and-network',
      skip: !teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const { data: dataOrgUnit, loading: isDataLoadingOrgUnit } =
    useStrategyMetricsOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
      },
      fetchPolicy: 'cache-and-network',
      skip: teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const { statusFilter, setStatusFilter } = useStatusFilter();

  const { userFilter, setUserFilter } = useUserFilter();

  const { dateFilter: startDateFilter, setDateFilter: setStartDateFilter } =
    useDateFilter({
      paramName: 'startDate',
    });

  const { dateFilter: endDateFilter, setDateFilter: setEndDateFilter } =
    useDateFilter({
      paramName: 'endDate',
    });

  const { priorityFilter, setPriorityFilter } = usePriorityFilter({
    priorities,
  });

  const { showCollaboratingTeams, clearShowCollaboratingTeams } =
    useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();
  const { showChildren } = useShowChildrenFilter();

  const clearFilters = useCallback(() => {
    clearSearchParam('status');
    clearSearchParam('user');
    clearSearchParam('startDate');
    clearSearchParam('endDate');
    clearSearchParam('priority');
    clearShowCollaboratingTeams();
  }, [clearSearchParam, clearShowCollaboratingTeams]);

  const contextValue = useMemo<StrategyObjectivesMetricsContextValue>(() => {
    const dataObjectives =
      (teamAdapter.isOrg
        ? dataOrg?.activeOrg
        : dataOrgUnit?.orgUnit
      )?.objectives.edges.map((n) => n.node) ?? [];

    const objectives = showChildren
      ? uniqBy(
          [
            ...dataObjectives,
            ...dataObjectives.flatMap((objective) => objective.childObjectives),
          ],
          (objective) => objective.id,
        )
      : dataObjectives;

    const filteredObjectives = filterObjectivesByCollaboratingTeams(
      filterObjectivesByDates(
        filterObjectivesByDates(
          filterObjectivesByUser(
            filterObjectivesByStatusIndicator(
              filterObjectiveMetricsByPriority(objectives, priorityFilter),
              [
                ...statusFilter,
                showCompleted && ('COMPLETED' as StatusMultiSelectItemId),
              ].filter(Boolean),
            ),
            userFilter,
          ),
          startDateFilter,
          endDateFilter,
        ),
        calendarIntervalStartDate,
        calendarIntervalEndDate,
        calendarCheck,
      ),
      showCollaboratingTeams,
      orgUnit?.id,
    ).filter((objectives) => objectives.metrics.length > 0);

    const users = getObjectivesOwners(objectives);

    const objectivesByTheme = groupObjectivesByTheme<
      StrategyMetricsObjective,
      StrategyMetricsThemeFragment
    >(filteredObjectives);

    const sortedObjectivesByTheme = sortObjectivesByTheme(
      objectivesByTheme,
      [
        ...(dataOrg?.activeOrg.orgUnits ||
          dataOrgUnit?.activeOrg.orgUnits ||
          []),
      ].reverse(),
    );

    return {
      objectives: filteredObjectives,
      objectivesByTheme: sortedObjectivesByTheme,

      isStrategyMetricsLoading: teamAdapter.isOrg
        ? isDataLoadingOrg && !dataOrg
        : isDataLoadingOrgUnit && !dataOrgUnit,

      filters: {
        priorityFilter,
        setPriorityFilter,
        clearFilters,
        userFilter,
        setUserFilter,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        statusIndicatorFilter: statusFilter,
        setStatusIndicatorFilter: setStatusFilter,
      },
      users,
    };
  }, [
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    clearFilters,
    dataOrg,
    dataOrgUnit,
    endDateFilter,
    isDataLoadingOrg,
    isDataLoadingOrgUnit,
    orgUnit?.id,
    priorityFilter,
    setEndDateFilter,
    setPriorityFilter,
    setStartDateFilter,
    setStatusFilter,
    setUserFilter,
    showChildren,
    showCollaboratingTeams,
    showCompleted,
    startDateFilter,
    statusFilter,
    teamAdapter.isOrg,
    userFilter,
  ]);

  const isLoading =
    (teamAdapter.isOrg
      ? !dataOrg && isDataLoadingOrg
      : !dataOrgUnit && isDataLoadingOrgUnit) || isPrioritiesLoading;

  if (isLoading) return <Spinner.Circle />;

  return (
    <StrategyObjectivesMetricsContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesMetricsContext.Provider>
  );
};

export default StrategyMetricsProvider;
