import compact from 'lodash/compact';

import { ReactComponent as ActionIcon } from 'shared/static/icons/icon-check-square.svg';
import { date } from 'shared/services/date.service';
import type {
  Action,
  ActionStatus,
  AuditRecord,
  TimeLine,
} from 'types.graphql.generated';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import type { PriorityFilterValue } from 'shared/priority/usePriorityFilter/usePriorityFilter';
import type { CalendarCheck } from 'shared/components/CalendarControls/CalendarControls.context';

import type {
  ActionsSort,
  ActionWithObjective,
  UserFilter,
  StartDateFilter,
  EndDateFilter,
} from './actions.type';
import type { ActionsObjectiveFragment } from './actions.fragment.graphql.generated';
import type { ActionStatusMultiSelectItemId } from './ActionStatusMultiSelect';
import { testActionStatusAgainstId } from './ActionStatusMultiSelect';

export const getActionIcon = () => ActionIcon;

export const getActionsContext = (pathname: string) =>
  pathname.includes('objectives') ? 'objective' : 'strategy';

export const getActionsWithParentObjective = <
  TObjective extends ActionsObjectiveFragment,
>(
  objectives: Maybe<TObjective[]>,
) =>
  objectives?.reduce<ActionWithObjective[]>((acc, objective) => {
    const actions = objective.actions.map((action) => ({
      ...action,
      objective: {
        __typename: objective.__typename,
        id: objective.id,
        name: objective.name,
      },
    }));

    return [...acc, ...actions];
  }, []);

export const getObjectivesActionsWithParentObjective = <
  TObjective extends ActionsObjectiveFragment,
>(
  objectives: Maybe<TObjective[]>,
) =>
  compact(
    objectives?.map(({ actions, ...objective }) =>
      actions.length
        ? {
            ...objective,
            actions: actions.map(
              (action): ActionWithObjective => ({
                ...action,
                objective: {
                  __typename: objective.__typename,
                  id: objective.id,
                  name: objective.name,
                },
              }),
            ),
          }
        : undefined,
    ),
  );

export const determineIfIsOverdue = <
  TAction extends Pick<Action, 'timeLine'> & {
    currentStatus?: Pick<ActionStatus, 'complete'>;
  },
>(
  action: Maybe<TAction>,
): boolean =>
  !!action?.timeLine.endDate &&
  !action.currentStatus?.complete &&
  date.isAfter(new Date(), date.add(action.timeLine.endDate, { days: 1 }));

export const filterActionsByUser = (
  actions: Maybe<ActionWithObjective[]>,
  filter: UserFilter,
) => {
  if (!filter.length) {
    return actions;
  }
  return actions?.filter((action) =>
    filter.some((value) => value === action.owner?.id),
  );
};

export const filterActionsByPriority = (
  actions: Maybe<ActionWithObjective[]>,
  filter: PriorityFilterValue,
) => {
  if (!filter.length) {
    return actions;
  }
  return actions?.filter((action) =>
    filter.some((value) =>
      value === 'ALL'
        ? true
        : value === 'UNKNOWN'
        ? !action.priority
        : value === action.priority?.number,
    ),
  );
};

export const getActiveActions = <
  TAction extends {
    currentStatus?: Pick<ActionStatus, 'complete' | 'completionRate'>;
  },
>(
  actions: Maybe<TAction[]>,
) =>
  (actions || []).filter(
    (action) =>
      !action.currentStatus?.complete &&
      action.currentStatus?.completionRate &&
      action.currentStatus?.completionRate > 0,
  );

export const getInactiveActions = (actions: Maybe<ActionWithObjective[]>) =>
  (actions || []).filter(
    (action) =>
      !action.currentStatus?.completionRate ||
      action.currentStatus?.completionRate === 0,
  );

export const getDoneActions = <
  TAction extends { currentStatus?: Pick<ActionStatus, 'complete'> },
>(
  actions: Maybe<TAction[]>,
) => actions?.filter((action) => action.currentStatus?.complete);

export const getActionsDoneCount = <
  TAction extends { currentStatus?: Pick<ActionStatus, 'complete'> },
>(
  actions: Maybe<TAction[]>,
) => {
  if (actions) {
    return actions.reduce<number>(
      (acc, cur) => (cur.currentStatus?.complete ? acc + 1 : acc),
      0,
    );
  } else {
    return 0;
  }
};

export const getOverdueActions = (actions: Maybe<ActionWithObjective[]>) =>
  actions?.filter(
    (action) =>
      action.timeLine.endDate &&
      date.isAfter(new Date(), date.add(action.timeLine.endDate, { days: 1 })),
  );

export const sortActionsByDeadlineDesc = (actions: ActionWithObjective[]) =>
  actions.sort((actionA, actionB) =>
    actionA.timeLine.endDate &&
    actionB.timeLine.endDate &&
    date.isAfter(actionA.timeLine.endDate, actionB.timeLine.endDate)
      ? -1
      : 1,
  );

export const sortActionsByDeadlineAsc = (actions: ActionWithObjective[]) =>
  actions.sort((actionA, actionB) =>
    actionA.timeLine.endDate &&
    actionB.timeLine.endDate &&
    date.isAfter(actionA.timeLine.endDate, actionB.timeLine.endDate)
      ? 1
      : -1,
  );

export const sortActionsByCompletionDesc = (actions: ActionWithObjective[]) =>
  actions.sort((actionA, actionB) => {
    if (
      actionA.currentStatus?.completionRate ===
      actionB.currentStatus?.completionRate
    ) {
      return 0;
    }

    return (actionA.currentStatus?.completionRate || 0) >
      (actionB.currentStatus?.completionRate || 0)
      ? 1
      : -1;
  });

export const sortActionsByCompletionAsc = (actions: ActionWithObjective[]) =>
  actions.sort((actionA, actionB) => {
    if (
      actionA.currentStatus?.completionRate ===
      actionB.currentStatus?.completionRate
    ) {
      return 0;
    }

    return (actionA.currentStatus?.completionRate || 0) >
      (actionB.currentStatus?.completionRate || 0)
      ? -1
      : 1;
  });

export const sortActions = (
  actions: Maybe<ActionWithObjective[]>,
  sort: ActionsSort,
) => {
  if (actions) {
    switch (sort) {
      case 'deadlineDesc':
        return sortActionsByDeadlineDesc(actions);
      case 'deadlineAsc':
        return sortActionsByDeadlineAsc(actions);
      case 'completionDesc':
        return sortActionsByCompletionDesc(actions);
      case 'completionAsc':
        return sortActionsByCompletionAsc(actions);
    }
  }
};

export const filterActionsByDates = <
  Action extends {
    auditRecord: Pick<AuditRecord, 'createDateTime'>;
    timeLine: Pick<TimeLine, 'startDate'>;
  },
>(
  actions: Maybe<Action[]>,
  start: StartDateFilter,
  end: EndDateFilter,
  check?: CalendarCheck,
) => {
  if (!start && !end) return actions;

  return actions?.filter((action) =>
    testStrategyItemAgainstInterval(action, { start, end }, check),
  );
};

export const filterActionsByStatus = (
  actions?: Maybe<ActionWithObjective[]>,
  statusFilter?: ActionStatusMultiSelectItemId[],
) =>
  actions?.filter((action) =>
    statusFilter?.some((status) =>
      testActionStatusAgainstId(status, action.currentStatus),
    ),
  );

export const filterActionsByCollaboratingTeams = (
  actions: Maybe<ActionWithObjective[]>,
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  actions?.filter(
    (action) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      action.orgUnit?.id === currentOrgUnitId,
  );
