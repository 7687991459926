import Space from 'shared/components/Space';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import type { UserMultiSelectItemId } from 'user/UserMultiSelect';
import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import { CalendarControlsProvider } from 'shared/components/CalendarControls';
import CompletedFilterProvider from 'shared/components/CompletedFilterButton/CompletedFilterProvider';
import CollaboratingTeamsFilterProvider from 'shared/components/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';

import TeamInsightsOverviewProvider, {
  useTeamInsightsOverview,
} from '../TeamInsightsOverviewProvider';
import TeamInsightsLayout from '../TeamInsightsLayout';
import TeamInsightsOverviewThemes from '../TeamInsightsOverviewThemes';
import TeamInsightsOverviewOperationalItems from '../TeamInsightsOverviewOperationalItems';
import TeamInsightsOverviewChart from '../TeamInsightsOverviewChart';
import TeamInsightsOverviewControls from './TeamInsightsOverviewControls';
import styles from './TeamInsightsOverview.module.scss';
import TeamInsightsOverviewFiltersChipGroup from '../TeamInsightsOverviewFiltersChipGroup';

const TeamInsightsOverview = () => {
  const {
    filters: {
      statusFilter,
      setStatusFilter,
      userFilter,
      setUserFilter,
      priorityFilter,
      setPriorityFilter,
    },
    users,
  } = useTeamInsightsOverview();

  return (
    <UserMultiSelect.Provider
      selectedKeys={userFilter}
      onSelectionChange={(selection) =>
        setUserFilter([...selection] as UserMultiSelectItemId[])
      }
      users={users}
    >
      <StatusMultiSelect.Provider
        selectedKeys={statusFilter}
        onSelectionChange={(selection) =>
          setStatusFilter([...selection] as StatusMultiSelectItemId[])
        }
      >
        <PriorityMultiSelect.Provider
          selectedKeys={priorityFilter}
          onSelectionChange={(selection) =>
            setPriorityFilter([...selection] as PriorityMultiSelectItemId[])
          }
        >
          <TeamInsightsLayout
            controls={<TeamInsightsOverviewControls />}
            subHeader={<TeamInsightsOverviewFiltersChipGroup />}
          >
            <Space direction={'vertical'}>
              <Space
                size={'big'}
                className={styles.themesAndOperationalItemsContainer}
              >
                <TeamInsightsOverviewThemes />
                <Space
                  direction={'vertical'}
                  className={styles.chartAndOperationalItemsContainer}
                >
                  <TeamInsightsOverviewOperationalItems>
                    <TeamInsightsOverviewChart />
                  </TeamInsightsOverviewOperationalItems>
                </Space>
              </Space>
            </Space>
          </TeamInsightsLayout>
        </PriorityMultiSelect.Provider>
      </StatusMultiSelect.Provider>
    </UserMultiSelect.Provider>
  );
};

const TeamInsightsOverviewWithProvider = () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <CollaboratingTeamsFilterProvider storeToSession={true}>
        <TeamInsightsOverviewProvider>
          <TeamInsightsOverview />
        </TeamInsightsOverviewProvider>
      </CollaboratingTeamsFilterProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);

export default TeamInsightsOverviewWithProvider;
