import type { RouteObject } from 'react-router-dom';

import ObjectiveActions from 'actions/ObjectiveActions';
import ObjectiveInitiatives from 'initiative/ObjectiveInitiatives';
import ObjectiveMetrics from 'metric/ObjectiveMetrics';
import MetricOverview from 'metric/MetricOverview/MetricOverview';
import InitiativeOverview from 'initiative/InitiativeOverview/InitiativeOverview';
import ObjectiveProvider from 'objective/ObjectiveProvider';
import ObjectiveLayout from 'objective/ObjectiveLayout';
import ObjectiveOverview from 'objective/ObjectiveOverview';
import ObjectiveInsights from 'objective/ObjectiveInsights';
import ObjectiveRelatedObjectives from 'objective/ObjectiveRelatedObjectives';
import ObjectiveDetails from 'objective/ObjectiveDetails/ObjectiveDetails';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import ActionStatusModalPage from 'actions/ActionStatusModal/ActionStatusModalPage';
import ActionsLayoutProvider from 'actions/ActionsLayout/ActionsLayoutProvider/ActionsLayoutProvider';
import EditActionPage from 'actions/EditAction/EditActionPage';
import ActionProvider from 'actions/ActionProvider';

import EditObjectivePage from './EditObjective/EditObjectivePage';
import CreateObjectiveStatusPage from './CreateObjectiveStatusPage';
import ObjectiveAlignment from './ObjectiveAlignment';

export const objectiveRoutes: RouteObject[] = [
  {
    path: objectiveRoutesPaths.root(),
    element: (
      <ObjectiveProvider>
        <ObjectiveLayout />
      </ObjectiveProvider>
    ),
    children: [
      {
        index: true,
        element: <ObjectiveOverview />,
      },
      {
        path: objectiveRoutesPaths.edit(),
        element: <EditObjectivePage />,
      },
      {
        path: objectiveRoutesPaths.metrics.root(),
        element: <ObjectiveMetrics />,
      },
      {
        path: objectiveRoutesPaths.initiatives.root(),
        element: <ObjectiveInitiatives />,
        children: [
          {
            path: 'list',
          },
          {
            path: 'calendar',
          },
        ],
      },
      {
        path: objectiveRoutesPaths.details(),
        element: <ObjectiveDetails />,
      },
      {
        path: objectiveRoutesPaths.alignment(),
        element: <ObjectiveAlignment />,
      },
      {
        path: objectiveRoutesPaths.relatedObjectives(),
        element: <ObjectiveRelatedObjectives />,
      },
      {
        path: objectiveRoutesPaths.insights.root(),
        element: <ObjectiveInsights />,
      },
      {
        path: objectiveRoutesPaths.insights.submit(),
        element: <CreateObjectiveStatusPage />,
      },
      {
        path: objectiveRoutesPaths.actions.root({}),
        element: <ObjectiveActions />,
        children: [
          {
            path: objectiveRoutesPaths.actions.action.root(),
            element: (
              <ActionsLayoutProvider>
                <ActionStatusModalPage />
              </ActionsLayoutProvider>
            ),
          },
          {
            path: objectiveRoutesPaths.actions.action.edit(),
            element: (
              <ActionsLayoutProvider>
                <ActionProvider>
                  <EditActionPage />
                </ActionProvider>
              </ActionsLayoutProvider>
            ),
          },
        ],
      },
    ],
  },
  {
    path: objectiveRoutesPaths.metrics.metric.root({
      allowNestedRoutes: true,
    }),
    element: <MetricOverview />,
  },
  {
    path: objectiveRoutesPaths.initiatives.initiative.root({
      allowNestedRoutes: true,
    }),
    element: <InitiativeOverview />,
  },
];
