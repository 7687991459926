import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import styled from '@emotion/styled';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import Flex from 'shared/components/Flex';
import Heading from 'shared/components/Heading';
import Spinner from 'shared/spinner/Spinner';
import { getAssessmentTypeTitle } from 'assessment/assessment.utils';
import AssessmentContextMenu from 'assessment/AssessmentContextMenu/AssessmentContextMenu';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';
import UserAvatar from 'user/UserAvatar';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import Layout from 'shared/components/Layout';

import { useViewAssessmentQuery } from './ViewAssessment.graphql.generated';
import { getBreadcrumbItems } from './ViewAssessment.utils';

const Content = styled.div`
  table {
    th {
      padding: 6px;
    }

    td {
      border: 1px solid ${(props) => props.theme.color.strokeMedium};
      padding: 6px;
    }
  }
`;

const ViewAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamSlug } = useTeamSlug();

  const { assessmentId } = useParams<{ assessmentId: string }>();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { data } = useViewAssessmentQuery({
    variables: { assessmentId: assessmentId || '' },
    skip: !assessmentId,
  });

  const breadcrumbsItems = useMemo(
    () => getBreadcrumbItems({ t, activeOrg, orgUnit }),
    [activeOrg, orgUnit, t],
  );

  if (!data) return <Spinner.Circle />;

  const strategyAssessmentsPath = teamRoutesPaths.insights.assessments.root({
    params: { teamSlug },
  });

  return (
    <>
      <Layout.Header
        breadcrumbsItems={breadcrumbsItems}
        topRightNavItem={{
          name: t('assessment.viewAssessment.goBack'),
          to: strategyAssessmentsPath,
        }}
      >
        <Flex justifyContent={'space-between'} alignItems={'center'} grow={1}>
          <Flex gap={8}>
            <TeamAvatar
              orgUnit={data.strategyAssessment.orgUnit}
              size={'small'}
            />
            <Heading level={3} isBold={false} hasMargin={false}>
              <b>{t('assessment.viewAssessment.title')}:</b>{' '}
              {data.strategyAssessment.title ||
                getAssessmentTypeTitle(t, data.strategyAssessment.type)}
            </Heading>
          </Flex>

          <Flex gap={8}>
            <UserAvatar user={data.strategyAssessment.owner} />
            <AssessmentContextMenu
              assessment={data.strategyAssessment}
              onDelete={() => navigate(strategyAssessmentsPath)}
            />
          </Flex>
        </Flex>
      </Layout.Header>

      <Layout.Content hasPadding={false}>
        <Content>
          <Markdown>{data.strategyAssessment.content}</Markdown>
        </Content>
      </Layout.Content>
    </>
  );
};

export default ViewAssessment;
