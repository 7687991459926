import type { ReactNode } from 'react';
import { css } from '@emotion/react';

import Heading from 'shared/components/Heading';

type LayoutHeaderHeadingProps = {
  className?: string;
  content?: string | ReactNode;
  isBold?: boolean;
};

const LayoutHeaderHeading = ({
  content,
  isBold = false,
  className,
}: LayoutHeaderHeadingProps) => (
  <Heading
    level={3}
    isBold={isBold}
    css={css`
      margin: 0;
      flex-grow: 1;
      width: 100%;
    `}
    className={className}
  >
    {content}
  </Heading>
);

export default LayoutHeaderHeading;
