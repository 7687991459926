import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { findIndex, sortBy } from 'lodash';
import { css } from '@emotion/react';

import Dialog from 'shared/components/Dialog';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useUser } from 'user/UserProvider';
import type { ListOperator } from 'types.graphql.generated';

import type {
  CreateTaskObjective,
  OneThingCampaignForCreateTask,
} from '../OneThingCreateTaskButton.types';
import { useCreateOneThingTaskObjectivesQuery } from './CreateOneThingTaskObjectives.graphql.generated';
import ObjectivesSelection from './ObjectivesSelection';

type OrgUnit = { id: string; name?: Maybe<string> };

type Props = {
  campaign: OneThingCampaignForCreateTask;
  isOpen: boolean;
  onClose: () => void;
  onSelect: (objective: CreateTaskObjective) => void;
};

const SelectObjectiveDialog = ({
  campaign,
  isOpen,
  onClose,
  onSelect,
}: Props) => {
  const { t } = useTranslation();
  const { activeOrg } = useActiveOrg();
  const { user } = useUser();

  const [selectedObjective, setSelectedObjective] =
    useState<CreateTaskObjective>();

  const handleApolloError = useHandleError();

  const userOrgUnits: OrgUnit[] = user.orgUnit
    ? [user.orgUnit, ...[...user.orgUnit.parentOrgUnitTree].reverse()]
    : [];

  const orgUnits = campaign.orgUnit
    ? userOrgUnits
        .slice(
          0,
          findIndex(
            userOrgUnits,
            (orgUnit) => orgUnit.id === campaign.orgUnit?.id,
          ),
        )
        .concat(campaign.orgUnit)
    : userOrgUnits;

  const { data } = useCreateOneThingTaskObjectivesQuery({
    skip: !isOpen,
    onError: handleApolloError,
    variables: {
      filter: {
        status: { isComplete: false },
        orgUnitIds: {
          or: [
            campaign.orgUnit
              ? undefined
              : { operator: 'IS_NULL' as ListOperator },
            orgUnits.length === 0
              ? undefined
              : {
                  operator: 'IN' as ListOperator,
                  value: orgUnits.map((orgUnit) => orgUnit.id),
                },
          ].filter(Boolean),
        },
      },
    },
  });

  const handleClose = useCallback(() => {
    setSelectedObjective(undefined);
    onClose();
  }, [onClose]);

  const objectives = sortBy(
    data?.allObjectives.edges.map((edge) => edge.node),
    (objective) => objective.name,
  );

  const team = campaign.orgUnit ? campaign.orgUnit.name : activeOrg.displayName;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      headerPretitle={'1/2'}
      headerTitle={t(
        'toolkit.toolPage.oneThing.createTask.wizard.selectObjective.title',
      )}
      headerSubtitle={t(
        'toolkit.toolPage.oneThing.createTask.wizard.selectObjective.subtitle',
        { campaign: campaign.name, team },
      )}
      confirmLabel={t('next')}
      isConfirmDisabled={!selectedObjective}
      cancelLabel={t('cancel')}
      onConfirm={() => selectedObjective && onSelect(selectedObjective)}
      animateOpen={false}
      animateClose={false}
      closeOnConfirm={false}
    >
      {data ? (
        <ObjectivesSelection
          selectedObjective={selectedObjective}
          setSelectedObjective={setSelectedObjective}
          objectives={objectives}
          orgUnits={orgUnits}
        />
      ) : (
        <div css={css({ marginTop: '5rem' })}>
          <Spinner.Circle />
        </div>
      )}
    </Dialog>
  );
};

export default SelectObjectiveDialog;
