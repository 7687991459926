import { Trans, useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import type { StatusFormProps } from 'shared/status/StatusForm';
import StatusForm from 'shared/status/StatusForm';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import type { Objective } from 'types.graphql.generated';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';

import styles from './ObjectiveStatusWizardStatus.module.scss';

type ObjectiveStatusWizardStatusProps = Pick<
  StatusFormProps,
  'id' | 'initialValues' | 'onSubmit' | 'onError' | 'onChange'
> & {
  completeStatus?: boolean;
  objective: Pick<Objective, 'id' | 'name' | 'description'>;
};

const ObjectiveStatusWizardStatus = ({
  objective,
  ...restProps
}: ObjectiveStatusWizardStatusProps) => {
  const { t } = useTranslation();

  const renderAboveForm = () => (
    <>
      <Heading level={3}>
        {t('objective.objectiveStatusWizard.steps.status.heading')}
      </Heading>
      <Space className={styles.marginBottom}>
        <Text className={styles.paragraph}>
          <Trans
            i18nKey={
              'objective.objectiveStatusWizard.steps.status.objectiveName'
            }
            values={{
              name: objective.name,
            }}
          >
            <Text variant={'emphasis'} />
          </Trans>
        </Text>
      </Space>
      {!!objective.description && (
        <Space className={styles.marginBottom}>
          <Text className={styles.paragraph}>
            <Trans
              i18nKey={
                'objective.objectiveStatusWizard.steps.status.objectiveDescription'
              }
              values={{
                description: objective.description,
              }}
            >
              <Text variant={'emphasis'} />
            </Trans>
          </Text>
        </Space>
      )}
    </>
  );

  const renderForm = () => (
    <>
      {renderAboveForm()}
      <StatusForm
        {...restProps}
        validateOnBlur={true}
        completeLabel={t(
          'objective.objectiveStatusWizard.steps.status.completeLabel',
        )}
        className={styles.form}
        enableReinitialize={true}
        showStatusDateField={true}
      />
    </>
  );

  const renderInfoCardContent = () => (
    <InfoCard
      title={t('objective.objectiveStatusWizard.steps.status.infoCard.heading')}
      contentKey={
        'objective.objectiveStatusWizard.steps.status.infoCard.content'
      }
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCardContent}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default ObjectiveStatusWizardStatus;
