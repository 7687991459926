import FiltersButton from 'shared/components/FiltersButton';

import ActionsLayoutFilters from '../ActionsLayoutFilters';
import { useChipGroupItems } from '../ActionsLayoutFiltersChipGroup/useChipGroupItems';

type Props = {
  clearFilters: () => void;
  showCollaboratingTeamsFilter: boolean;
};

const ActionsFiltersButton = ({
  clearFilters,
  showCollaboratingTeamsFilter,
}: Props) => {
  const { length: selectionCount } = useChipGroupItems();

  return (
    <FiltersButton clearFilters={clearFilters} selectionCount={selectionCount}>
      <ActionsLayoutFilters
        showCollaboratingTeamsFilter={showCollaboratingTeamsFilter}
      />
    </FiltersButton>
  );
};

export default ActionsFiltersButton;
