import { FieldArray } from 'formik';
import { css } from '@emotion/react';

import { useForm } from 'shared/form/Form';
import Flex from 'shared/components/Flex';

import type { MetricFormValues } from '../MetricForm';
import MetricTargetsItemFormFields from './MetricTargetsItemFormFields';
import AddTargetsByFrequencyButton from './AddTargetsByFrequencyButton';

const MetricTargetsFormFields = () => {
  const { values } = useForm<MetricFormValues>();

  return (
    <FieldArray name={'periodicTargets'}>
      {({ remove }) => (
        <Flex direction={'column'}>
          {values.periodicTargets.map((_, index) => (
            <MetricTargetsItemFormFields
              key={index}
              index={index}
              onRemove={remove}
            />
          ))}

          <div css={css({ padding: 20 })}>
            <AddTargetsByFrequencyButton />
          </div>
        </Flex>
      )}
    </FieldArray>
  );
};

export default MetricTargetsFormFields;
