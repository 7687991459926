import type { FetchResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-use';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import type { InitialStep } from 'shared/components/Steps';
import Steps, { useSteps } from 'shared/components/Steps';
import { useToasts } from 'shared/toast/useToasts';
import { useTeamAdapter } from 'team/TeamAdapter';
import { withSteps } from 'shared/components/Steps/withSteps';
import type {
  InitiativeStatus,
  InitiativeStatusInput,
  Milestone,
  MilestoneStatus,
} from 'types.graphql.generated';
import { initializeComments } from 'shared/utils/textItem.utils';
import PreviousInsightsProvider from 'status/PreviousInsightsSidecard/PreviousInsightsProvider';
import Spinner from 'shared/spinner/Spinner';

import InitiativeStatusWizardMilestones from './InitiativeStatusWizardMilestones';
import InitiativeStatusWizardKeyIndicators from './InitiativeStatusWizardKeyIndicators';
import InitiativeStatusWizardStatus from './InitiativeStatusWizardStatus';
import InitiativeStatusWizardComments from './InitiativeStatusWizardComments';
import InitiativeStatusWizardControls from './InitiativeStatusWizardControls';
import {
  resolveAddInitiativeStatusInput,
  resolveKeyIndicatorsInitialValues,
  resolveStatusKeyIndicators,
  resolveStatusMilestonesStatusIndicators,
} from './InitiativeStatusWizard.utils';
import type {
  InitiativeStatusWizardStepId,
  InitiativeStatusWizardValues,
} from './InitiativeStatusWizard.type';
import { useInitiativeStatusWizardQuery } from './InitiativeStatusWizard.graphql.generated';

const steps: Record<InitiativeStatusWizardStepId, InitialStep> = {
  milestones: {
    id: 'milestones',
    nameTranslationKey:
      'initiative.initiativeStatusWizard.steps.milestones.name',
    form: 'initiativeStatusWizardMilestonesForm',
  },
  keyIndicators: {
    id: 'keyIndicators',
    nameTranslationKey:
      'initiative.initiativeStatusWizard.steps.keyIndicators.name',
    form: 'initiativeStatusWizardKeyIndicatorsForm',
  },
  status: {
    id: 'status',
    nameTranslationKey: 'initiative.initiativeStatusWizard.steps.status.name',
    form: 'initiativeStatusWizardKeyStatusForm',
  },
  comments: {
    id: 'comments',
    nameTranslationKey: 'initiative.initiativeStatusWizard.steps.comments.name',
    form: 'initiativeStatusWizardKeyCommentsForm',
  },
};

export type InitiativeStatusForStatusWizard = Pick<
  InitiativeStatus,
  | 'id'
  | 'keyIndicatorOnBudget'
  | 'keyIndicatorOnOutcome'
  | 'keyIndicatorOnTime'
  | 'statusDateTime'
  | 'statusIndicator'
  | 'comment'
  | 'complete'
  | 'successComments'
  | 'challengeComments'
  | 'actionComments'
> & {
  milestoneStatuses: (Pick<
    MilestoneStatus,
    'id' | 'comment' | 'complete' | 'completionRate' | 'statusIndicator'
  > & {
    milestone: Pick<Milestone, 'id'>;
  })[];
};

export type InitiativeStatusWizardProps = {
  buttonLabel: string;
  completeStatus?: boolean;
  headerTitle: string;
  initiativeId: string;
  onSubmit: (input: InitiativeStatusInput) => Promise<FetchResult>;
  status?: InitiativeStatusForStatusWizard;
} & Pick<ModalProps, 'onClose' | 'isOpen'>;

const InitiativeStatusWizard = ({
  initiativeId,
  status,
  isOpen,
  onClose,
  onSubmit,
  completeStatus = false,
  headerTitle,
  buttonLabel,
}: InitiativeStatusWizardProps) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const { addToast } = useToasts();

  const {
    goToNextStep,
    submitSteps,
    setStepReady,
    resetSteps,
    setHiddenSteps,
  } = useSteps();

  const { data } = useInitiativeStatusWizardQuery({
    variables: { initiativeId },
    skip: !isOpen,
  });

  useEffect(() => {
    if (data && data.initiative.milestones.length === 0) {
      setHiddenSteps(['milestones']);
    }
  }, [data, setHiddenSteps]);

  const initialValues: InitiativeStatusWizardValues = {
    status: {
      statusIndicator: status?.statusIndicator.value || '',
      comment: status?.comment || '',
      complete: status?.complete || completeStatus,
      date: status?.statusDateTime || new Date(),
    },
    comments: {
      successes: initializeComments(status?.successComments),
      challenges: initializeComments(status?.challengeComments),
      actions: initializeComments(status?.actionComments),
    },
    milestones: status?.milestoneStatuses.reduce(
      (result, milestoneStatus) => ({
        ...result,
        [milestoneStatus.milestone.id]: {
          comment: milestoneStatus.comment,
          complete: milestoneStatus.complete,
          completionRate: milestoneStatus.completionRate,
          statusIndicator: milestoneStatus.statusIndicator.value,
        },
      }),
      {},
    ),
    keyIndicators: {
      onTime:
        status?.keyIndicatorOnTime === undefined ||
        status?.keyIndicatorOnTime === null
          ? null
          : status.keyIndicatorOnTime
          ? 'true'
          : 'false',
      onBudget:
        status?.keyIndicatorOnBudget === undefined ||
        status?.keyIndicatorOnBudget === null
          ? null
          : status.keyIndicatorOnBudget
          ? 'true'
          : 'false',
      onOutcome:
        status?.keyIndicatorOnOutcome === undefined ||
        status?.keyIndicatorOnOutcome === null
          ? null
          : status.keyIndicatorOnOutcome
          ? 'true'
          : 'false',
    },
  };

  const [values, { set: setValues, setAll: setAllValues }] =
    useMap<InitiativeStatusWizardValues>(initialValues);

  useEffect(() => {
    resetSteps();
    setAllValues(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChangeStatus = useCallback(
    (values: InitiativeStatusWizardValues['status']) =>
      setValues('status', values),
    [setValues],
  );

  const handleChangeComments = useCallback(
    (values: InitiativeStatusWizardValues['comments']) =>
      setValues('comments', values),
    [setValues],
  );

  const handleChangeMilestones = useCallback(
    (values: InitiativeStatusWizardValues['milestones']) =>
      setValues('milestones', values),
    [setValues],
  );

  const handleChangeKeyIndicators = useCallback(
    (values: InitiativeStatusWizardValues['keyIndicators']) =>
      setValues('keyIndicators', values),
    [setValues],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initiative = data?.initiative;

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);

    submitSteps(
      async () => {
        try {
          if (!initiative) return;

          const result = await onSubmit(
            resolveAddInitiativeStatusInput({
              status,
              values,
              initiative,
              teamAdapter,
            }),
          );

          if (!result.errors) {
            addToast({
              children: t('initiative.initiativeStatusWizard.successToast'),
              variant: 'success',
            });
          }
        } finally {
          setIsSubmitting(false);
        }
      },
      () => setIsSubmitting(false),
    );
  }, [
    addToast,
    initiative,
    onSubmit,
    status,
    submitSteps,
    t,
    teamAdapter,
    values,
  ]);

  return (
    <Modal
      heading={headerTitle}
      isOpen={isOpen}
      onClose={onClose}
      size={'full'}
      scrollType={'outer'}
      hasBackground={false}
      renderHeaderContent={() => <Steps.Navigation />}
      renderFooterContent={() => (
        <InitiativeStatusWizardControls
          isLoading={isSubmitting}
          buttonLabel={buttonLabel}
        />
      )}
    >
      {initiative ? (
        <>
          <Steps.Item id={'milestones'}>
            {({ onStepComplete, onStepError }) => (
              <PreviousInsightsProvider
                objectiveId={initiative.objective?.id}
                initiativeId={initiative.id}
              >
                <InitiativeStatusWizardMilestones
                  id={steps.milestones.form}
                  initialValues={values.milestones}
                  onChange={handleChangeMilestones}
                  onSubmit={(_values, _formikHelpers, submitOptions) => {
                    onStepComplete();
                    setStepReady('keyIndicators');
                    if (submitOptions.goToNextStep) {
                      goToNextStep();
                    }
                  }}
                  onError={onStepError}
                  initiative={initiative}
                />
              </PreviousInsightsProvider>
            )}
          </Steps.Item>
          <Steps.Item id={'keyIndicators'}>
            {({ onStepComplete, onStepError }) => (
              <PreviousInsightsProvider
                objectiveId={initiative.objective?.id}
                initiativeId={initiative.id}
              >
                <InitiativeStatusWizardKeyIndicators
                  id={steps.keyIndicators.form}
                  initialValues={resolveKeyIndicatorsInitialValues({
                    values,
                    initiative,
                  })}
                  onChange={handleChangeKeyIndicators}
                  onSubmit={(_values, _formikHelpers, submitOptions) => {
                    onStepComplete();
                    setStepReady('status');
                    if (submitOptions.goToNextStep) {
                      goToNextStep();
                    }
                  }}
                  onError={onStepError}
                  initiative={initiative}
                />
              </PreviousInsightsProvider>
            )}
          </Steps.Item>
          <Steps.Item id={'status'}>
            {({ onStepComplete, onStepError }) => (
              <PreviousInsightsProvider
                objectiveId={initiative.objective?.id}
                initiativeId={initiative.id}
              >
                <InitiativeStatusWizardStatus
                  id={steps.status.form}
                  initialValues={values.status}
                  initiative={initiative}
                  onChange={handleChangeStatus}
                  onSubmit={(_values, _formikHelpers, submitOptions) => {
                    onStepComplete();
                    setStepReady('comments');
                    if (submitOptions.goToNextStep) {
                      goToNextStep();
                    }
                  }}
                  onError={onStepError}
                  milestonesStatusIndicators={resolveStatusMilestonesStatusIndicators(
                    { values },
                  )}
                  keyIndicators={resolveStatusKeyIndicators({
                    initiative,
                    values,
                  })}
                  completeStatus={completeStatus}
                />
              </PreviousInsightsProvider>
            )}
          </Steps.Item>
          <Steps.Item id={'comments'}>
            {({ onStepComplete, onStepError }) => (
              <PreviousInsightsProvider
                objectiveId={initiative.objective?.id}
                initiativeId={initiative.id}
                formId={steps.comments.form}
              >
                <InitiativeStatusWizardComments
                  id={steps.comments.form}
                  initialValues={values.comments}
                  onChange={handleChangeComments}
                  onSubmit={(_values, _formikHelpers, submitOptions) => {
                    onStepComplete();
                    if (submitOptions.submitWizard) {
                      handleSubmit();
                    }
                  }}
                  onError={onStepError}
                  initiative={initiative}
                />
              </PreviousInsightsProvider>
            )}
          </Steps.Item>
        </>
      ) : (
        isOpen && <Spinner.Circle />
      )}
    </Modal>
  );
};

export default withSteps(InitiativeStatusWizard, Object.values(steps));
