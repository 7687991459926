import { useTranslation } from 'react-i18next';

import Tag from 'shared/components/Tag';

const ActionChipOverdue = () => {
  const { t } = useTranslation();

  return (
    <Tag variant={'danger'} size={'tiny'}>
      {t('actions.stats.name.overdue')}
    </Tag>
  );
};

export default ActionChipOverdue;
