import { css } from '@emotion/react';

import { useObjective } from 'objective/ObjectiveProvider';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import ObjectiveStatusContextMenu from 'objective/ObjectiveStatusContextMenu';

import styles from './ObjectiveOverviewInsights.module.scss';
import ObjectiveOverviewInsightsSection from './ObjectiveOverviewInsightsSection';

const ObjectiveOverviewInsights = () => {
  const { objective } = useObjective();

  const canReadStatusComments = canPerformStrategyElementAction(
    objective,
    'READ_STATUS_COMMENTS',
  );

  if (!canReadStatusComments) return null;

  return (
    <article className={styles.container}>
      <ObjectiveOverviewInsightsSection
        context={'successes'}
        comments={objective.currentObjectiveStatus?.successComments}
      />
      <ObjectiveOverviewInsightsSection
        context={'challenges'}
        comments={objective.currentObjectiveStatus?.challengeComments}
      />
      <ObjectiveOverviewInsightsSection
        context={'actions'}
        comments={objective.currentObjectiveStatus?.actionComments}
      >
        {objective.currentObjectiveStatus && (
          <div css={css({ height: 0, marginTop: -45 })}>
            <ObjectiveStatusContextMenu
              objective={objective}
              status={objective.currentObjectiveStatus}
              startEditFromStep={'comments'}
            />
          </div>
        )}
      </ObjectiveOverviewInsightsSection>
    </article>
  );
};

export default ObjectiveOverviewInsights;
