import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';
import {
  TeamMembersFromOrgDocument,
  TeamMembersFromOrgUnitDocument,
} from 'team/TeamMembers/TeamMembersProvider/TeamMembersProvider.graphql.generated';

import { useRemoveOrgUnitMemberMutation } from './RemoveOrgUnitMember.graphql.generated';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  orgUnitId: string;
  userId: string;
};

const RemoveOrgUnitMemberConfirmationModal = ({
  isOpen,
  onClose,
  orgUnitId,
  userId,
}: Props) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const onError = useHandleError();

  const [removeOrgUnitMember] = useRemoveOrgUnitMemberMutation({
    variables: {
      idToUpdate: orgUnitId,
      userIdToRemove: userId,
    },
    onError,
    onCompleted: () =>
      addToast({
        children: t('userMenu.removeFromOrgUnit.success'),
        variant: 'success',
      }),
    refetchQueries: [
      TeamMembersFromOrgDocument,
      TeamMembersFromOrgUnitDocument,
    ],
  });

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={removeOrgUnitMember}
    >
      {t('userMenu.removeFromOrgUnit.confirmation')}
    </ConfirmationDialog>
  );

  return null;
};

export default RemoveOrgUnitMemberConfirmationModal;
