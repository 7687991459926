import type { ComponentType } from 'react';

import type { InitialStep } from './Steps.type';
import Steps from './Steps';

export const withSteps =
  <ComponentProps extends object, StepId extends string>(
    Component: ComponentType<ComponentProps>,
    initialSteps: InitialStep[],
  ) =>
  (props: ComponentProps & { startFromStep?: StepId }) =>
    (
      <Steps initialSteps={initialSteps} startFromStep={props.startFromStep}>
        <Component {...props} />
      </Steps>
    );
