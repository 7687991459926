import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { ModalControlsProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import { ReactComponent as RightArrowIcon } from 'shared/static/icons/icon-arrow-right.svg';
import { ReactComponent as LeftArrowIcon } from 'shared/static/icons/icon-arrow-left.svg';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import { submitForm } from 'shared/form/form.utils';
import { useSteps } from 'shared/components/Steps';
import type { Report } from 'types.graphql.generated';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import useHandleError from 'shared/errors/useHandleError';
import { RequestStatusUpdateFormUpdateReportSubmissionId } from 'report/RequestStatusUpdateForm';
import useSubmission from 'shared/providers/SubmissionProvider/useSubmission';

import ObjectivesSummary from '../InsightReportWizardObjectivesSummary';
import { useInsightReportWizardControlsQuery } from './InsightReportWizardControls.graphql.generated';
import { useInsightsReportWizard } from '../InsightReportWizardProvider/useInsightsReportWizard';

type InsightReportWizardControlsProps = {
  initiativesCount: number;
  metricsCount: number;
  objectivesCount: number;
  reportId?: Report['id'];
};

const InsightReportWizardControls = ({
  reportId,
  metricsCount,
  objectivesCount,
  initiativesCount,
}: InsightReportWizardControlsProps) => {
  const { t } = useTranslation();

  const { activeStep, goToNextStep, goToPreviousStep } = useSteps();

  const handleApolloError = useHandleError();

  const { data } = useInsightReportWizardControlsQuery({
    variables: { reportId: reportId || '' },
    skip: !reportId,
    onError: handleApolloError,
  });

  const [isStatusUpdateConfirmationOpen, setIsStatusUpdateConfirmationOpen] =
    useState(false);

  const { hasSelection, hasStatusRequests } = useInsightsReportWizard();

  const [isSubmittingStatusRequests] = useSubmission(
    RequestStatusUpdateFormUpdateReportSubmissionId,
  );

  const renderObjectivesSummary = () => (
    <ObjectivesSummary
      metricsCount={metricsCount}
      objectivesCount={objectivesCount}
      initiativesCount={initiativesCount}
    />
  );

  const areTherePendingStatusRequests =
    data?.report.reportSelection.reportObjectives.some(
      (reportObjective) =>
        (reportObjective.statusRequest &&
          !reportObjective.statusRequest?.isComplete) ||
        reportObjective.reportMetrics.some(
          (reportMetric) =>
            reportMetric.statusRequest &&
            !reportMetric.statusRequest.isComplete,
        ) ||
        reportObjective.reportInitiatives.some(
          (reportInitiative) =>
            reportInitiative.statusRequest &&
            !reportInitiative.statusRequest.isComplete,
        ) ||
        reportObjective.reportActions.some(
          (reportAction) =>
            reportAction.statusRequest &&
            !reportAction.statusRequest.isComplete,
        ),
    );

  const getButtons = (): ModalControlsProps['buttons'] => {
    if (!activeStep) return [];

    switch (activeStep.id) {
      case 'objectives': {
        return [
          {
            type: 'submit',
            form: 'objectives',
            children: t('report.insightReportWizard.nextStepButton'),
            icon: RightArrowIcon,
            isDisabled: !hasSelection,
          },
        ];
      }
      case 'statusUpdate': {
        return [
          data?.report?.reportStage === 'STATUS_REQUESTED'
            ? {
                children: t('report.insightReportWizard.nextStepButton'),
                icon: RightArrowIcon,
                onPress: () => {
                  if (areTherePendingStatusRequests) {
                    setIsStatusUpdateConfirmationOpen(true);
                  } else {
                    goToNextStep();
                  }
                },
              }
            : hasStatusRequests
            ? [
                {
                  children: t('report.requestStatusUpdateForm.skipButton'),
                  icon: RightArrowIcon,
                  variant: 'outlined',
                  onPress: () => {
                    goToNextStep();
                  },
                },
                {
                  type: 'submit',
                  form: 'statusRequest',
                  children: t('report.requestStatusUpdateForm.submitButton'),
                  icon: SendIcon,
                  isDisabled: isSubmittingStatusRequests,
                },
              ]
            : {
                children: t('report.insightReportWizard.nextStepButton'),
                icon: RightArrowIcon,
                onPress: () => {
                  goToNextStep();
                },
              },
          {
            onPress: () => {
              goToPreviousStep();
            },
            children: t('report.insightReportWizard.previousStepButton'),
            variant: 'outlined',
            iconPosition: 'start',
            icon: LeftArrowIcon,
          },
        ];
      }
      case 'actions': {
        return [
          {
            onPress: () => {
              if (!reportId) {
                return;
              }
              submitForm('actionsForm', {
                updateReportStage: true,
              });
            },
            children: t('report.insightReportWizard.createReportButton'),
            icon: RightArrowIcon,
          },
          {
            onPress: () => {
              submitForm('actionsForm');
              goToPreviousStep();
            },
            children: t('report.insightReportWizard.previousStepButton'),
            variant: 'outlined',
            iconPosition: 'start',
            icon: LeftArrowIcon,
          },
        ];
      }
    }
    return [
      {
        children: t('report.insightReportWizard.nextStepButton'),
        icon: RightArrowIcon,
        onPress: () => {
          submitForm(
            activeStep?.id === 'successes'
              ? 'successesForm'
              : activeStep?.id === 'challenges'
              ? 'challengesForm'
              : 'actionsForm',
          );
          goToNextStep();
        },
      },
      {
        onPress: () => {
          submitForm(
            activeStep?.id === 'successes'
              ? 'successesForm'
              : activeStep?.id === 'challenges'
              ? 'challengesForm'
              : 'actionsForm',
          );
          goToPreviousStep();
        },
        children: t('report.insightReportWizard.previousStepButton'),
        variant: 'outlined',
        iconPosition: 'start',
        icon: LeftArrowIcon,
      },
    ];
  };

  return (
    <>
      <Modal.Controls
        buttons={getButtons()}
        renderBefore={
          activeStep?.id === 'objectives' ? renderObjectivesSummary : undefined
        }
      />

      <ConfirmationDialog
        isOpen={isStatusUpdateConfirmationOpen}
        onConfirm={goToNextStep}
        onClose={() => setIsStatusUpdateConfirmationOpen(false)}
      >
        {t('report.requestStatusUpdateForm.confirmation')}
      </ConfirmationDialog>
    </>
  );
};

export default InsightReportWizardControls;
