import { Trans, useTranslation } from 'react-i18next';

import type { StatusFormProps } from 'shared/status/StatusForm';
import StatusForm from 'shared/status/StatusForm';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import KeyIndicators from 'initiative/KeyIndicators';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';

import styles from './InitiativeStatusWizardStatus.module.scss';
import type { InitiativeStatusWizardInitiativeFragment } from '../InitiativeStatusWizard.graphql.generated';

export type InitiativeStatusWizardStatusProps = Pick<
  StatusFormProps,
  'id' | 'initialValues' | 'onSubmit' | 'onChange' | 'onError'
> & {
  completeStatus?: boolean;
  initiative: InitiativeStatusWizardInitiativeFragment;
  keyIndicators?: {
    keyIndicatorOnBudget?: boolean;
    keyIndicatorOnOutcome?: boolean;
    keyIndicatorOnTime?: boolean;
  };
  milestonesStatusIndicators?: StatusIndicator[];
};

// todo divide InitiativeStatusWizardStatus into smaller components
const InitiativeStatusWizardStatus = ({
  keyIndicators,
  milestonesStatusIndicators,
  initiative,
  ...restProps
}: InitiativeStatusWizardStatusProps) => {
  const { t } = useTranslation();

  const renderAboveForm = () => (
    <>
      <Heading level={3}>
        {t('initiative.initiativeStatusWizard.steps.status.heading')}
      </Heading>
      <Space className={styles.marginBottom}>
        <Text>
          <Trans
            i18nKey={
              'initiative.initiativeStatusWizard.steps.status.initiativeName'
            }
            values={{
              name: initiative.name,
            }}
          >
            <Text variant={'emphasis'} />
          </Trans>
        </Text>
      </Space>
      {!!initiative.description && (
        <Space className={styles.marginBottom}>
          <Text>
            <Trans
              i18nKey={
                'initiative.initiativeStatusWizard.steps.status.initiativeDescription'
              }
              values={{
                description: initiative.description,
              }}
            >
              <Text variant={'emphasis'} />
            </Trans>
          </Text>
        </Space>
      )}
      {milestonesStatusIndicators?.length ? (
        <Space>
          <Text variant={'emphasis'}>
            {t(
              'initiative.initiativeStatusWizard.steps.status.milestoneStatusesLabel',
            )}
          </Text>
          <Space>
            {milestonesStatusIndicators.map(
              (statusIndicator, statusIndicatorIndex) => (
                <StatusMark
                  statusIndicator={statusIndicator}
                  key={statusIndicatorIndex}
                />
              ),
            )}
          </Space>
        </Space>
      ) : null}
      <KeyIndicators {...keyIndicators} className={styles.keyIndicators} />
    </>
  );

  const renderForm = () => (
    <>
      {renderAboveForm()}
      <StatusForm
        {...restProps}
        completeLabel={t(
          'initiative.initiativeStatusWizard.steps.status.completeLabel',
        )}
        className={styles.form}
        showStatusDateField={true}
      />
    </>
  );

  const renderCardInfo = () => (
    <InfoCard
      title={t(
        'initiative.initiativeStatusWizard.steps.status.infoCard.heading',
      )}
      contentKey={
        'initiative.initiativeStatusWizard.steps.status.infoCard.content'
      }
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderCardInfo}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default InitiativeStatusWizardStatus;
