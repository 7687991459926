import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditOrgUnitModalQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type EditOrgUnitModalQuery = { __typename: 'Query', allUsers: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string, email: string, displayName?: string | undefined } }> }, orgUnit: { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, description?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitLead?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined, orgUnitAdmins: Array<{ __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined } };

export type EditOrgUnitMutationVariables = Types.Exact<{
  input: Types.OrgUnitInput;
}>;


export type EditOrgUnitMutation = { __typename: 'Mutation', updateOrgUnit: { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, description?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitLead?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined, orgUnitAdmins: Array<{ __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined } };

export type EditOrgUnitOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, description?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitLead?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined, orgUnitAdmins: Array<{ __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined };

export const EditOrgUnitOrgUnitFragmentDoc = gql`
    fragment editOrgUnitOrgUnit on OrgUnit {
  id
  name
  nameAbbreviated
  description
  backgroundColorAsHex
  orgUnitLead {
    id
    email
    displayName
  }
  orgUnitAdmins {
    id
    email
    displayName
  }
  parentOrgUnit {
    id
    name
  }
}
    `;
export const EditOrgUnitModalDocument = gql`
    query EditOrgUnitModal($id: ID!) {
  allUsers {
    edges {
      node {
        id
        email
        displayName
      }
    }
  }
  orgUnit(id: $id) {
    ...editOrgUnitOrgUnit
  }
}
    ${EditOrgUnitOrgUnitFragmentDoc}`;

/**
 * __useEditOrgUnitModalQuery__
 *
 * To run a query within a React component, call `useEditOrgUnitModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOrgUnitModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOrgUnitModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditOrgUnitModalQuery(baseOptions: Apollo.QueryHookOptions<EditOrgUnitModalQuery, EditOrgUnitModalQueryVariables> & ({ variables: EditOrgUnitModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOrgUnitModalQuery, EditOrgUnitModalQueryVariables>(EditOrgUnitModalDocument, options);
      }
export function useEditOrgUnitModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOrgUnitModalQuery, EditOrgUnitModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOrgUnitModalQuery, EditOrgUnitModalQueryVariables>(EditOrgUnitModalDocument, options);
        }
export function useEditOrgUnitModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EditOrgUnitModalQuery, EditOrgUnitModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditOrgUnitModalQuery, EditOrgUnitModalQueryVariables>(EditOrgUnitModalDocument, options);
        }
export type EditOrgUnitModalQueryHookResult = ReturnType<typeof useEditOrgUnitModalQuery>;
export type EditOrgUnitModalLazyQueryHookResult = ReturnType<typeof useEditOrgUnitModalLazyQuery>;
export type EditOrgUnitModalSuspenseQueryHookResult = ReturnType<typeof useEditOrgUnitModalSuspenseQuery>;
export type EditOrgUnitModalQueryResult = Apollo.QueryResult<EditOrgUnitModalQuery, EditOrgUnitModalQueryVariables>;
export const EditOrgUnitDocument = gql`
    mutation EditOrgUnit($input: OrgUnitInput!) {
  updateOrgUnit(input: $input) {
    ...editOrgUnitOrgUnit
  }
}
    ${EditOrgUnitOrgUnitFragmentDoc}`;
export type EditOrgUnitMutationFn = Apollo.MutationFunction<EditOrgUnitMutation, EditOrgUnitMutationVariables>;

/**
 * __useEditOrgUnitMutation__
 *
 * To run a mutation, you first call `useEditOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgUnitMutation, { data, loading, error }] = useEditOrgUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<EditOrgUnitMutation, EditOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOrgUnitMutation, EditOrgUnitMutationVariables>(EditOrgUnitDocument, options);
      }
export type EditOrgUnitMutationHookResult = ReturnType<typeof useEditOrgUnitMutation>;
export type EditOrgUnitMutationResult = Apollo.MutationResult<EditOrgUnitMutation>;
export type EditOrgUnitMutationOptions = Apollo.BaseMutationOptions<EditOrgUnitMutation, EditOrgUnitMutationVariables>;