import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PromptMessage } from 'ai/AskSophieChat';
import {
  pestel,
  porters,
  customerSegmentation,
  doubleMateriality,
  stakeholderAnalysis,
  ansoff,
  blueOcean,
  vrio,
  competitiveStrategies,
  portfolioAnalysis,
  bscStrategyMap,
  bscStrategyBoard,
  strategyDiamond,
  businessCanvas,
  mAndA,
  valueChain,
  playingToWin,
  customerJourney,
  threeHorizons,
  swot,
  bscSwot,
  mckinsey,
  riskFramework,
  strategicRisks,
  objectiveRisks,
  riskMap,
  unsdg,
  esg,
  objectives,
  frameworks,
  taxonomy,
  csrd,
  tcfd,
  sbti,
  gri,
  twoByTwoScenario,
  futureOpportunities,
  financialHealth,
  financialBenchmark,
  financialGrowth,
  financialRisk,
  stratex,
  revenue,
} from 'strategy/StrategyOverview/prompts';

export default function useAssessmentPromptMessages() {
  const { t } = useTranslation();

  return useMemo<PromptMessage[]>(
    () =>
      [
        {
          buttonLabel: t('ai.strategySummary.button.pestel'),
          mainPrompt: pestel,
          numberOfCharacters: 5000,
          assessmentType: 'PESTEL',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.porters'),
          mainPrompt: porters,
          numberOfCharacters: 5000,
          assessmentType: 'PORTERS_FIVE_FORCES',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.customerSegmentation'),
          mainPrompt: customerSegmentation,
          numberOfCharacters: 5000,
          assessmentType: 'CUSTOMER_SEGMENTATION',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.doubleMateriality'),
          mainPrompt: doubleMateriality,
          numberOfCharacters: 5000,
          assessmentType: 'DOUBLE_MATERIALITY',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.stakeholderAnalysis'),
          mainPrompt: stakeholderAnalysis,
          numberOfCharacters: 5000,
          assessmentType: 'STAKEHOLDER_ANALYSIS',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.ansoff'),
          mainPrompt: ansoff,
          numberOfCharacters: 5000,
          assessmentType: 'ANSOFF',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.blueOcean'),
          mainPrompt: blueOcean,
          numberOfCharacters: 5000,
          assessmentType: 'BLUE_OCEAN',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.vrio'),
          mainPrompt: vrio,
          numberOfCharacters: 5000,
          assessmentType: 'VRIO',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.competitiveStrategies'),
          mainPrompt: competitiveStrategies,
          numberOfCharacters: 5000,
          assessmentType: 'COMPETITIVE_STRATEGIES',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.mAndA'),
          mainPrompt: mAndA,
          numberOfCharacters: 5000,
          assessmentType: 'M_AND_A',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.valueChain'),
          mainPrompt: valueChain,
          numberOfCharacters: 5000,
          assessmentType: 'VALUE_CHAIN',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.playingToWin'),
          mainPrompt: playingToWin,
          numberOfCharacters: 5000,
          assessmentType: 'PLAYING_TO_WIN',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.customerJourney'),
          mainPrompt: customerJourney,
          numberOfCharacters: 5000,
          assessmentType: 'CUSTOMER_JOURNEY',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.threeHorizons'),
          mainPrompt: threeHorizons,
          numberOfCharacters: 5000,
          assessmentType: 'THREE_HORIZONS',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.swot'),
          mainPrompt: swot,
          numberOfCharacters: 5000,
          assessmentType: 'SWOT',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.bscSwot'),
          mainPrompt: bscSwot,
          numberOfCharacters: 5000,
          assessmentType: 'BSC_SWOT',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.portfolioAnalysis'),
          mainPrompt: portfolioAnalysis,
          numberOfCharacters: 5000,
          assessmentType: 'GE_PORTFOLIO_ANALYSIS',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.bscStrategyMap'),
          mainPrompt: bscStrategyMap,
          numberOfCharacters: 5000,
          assessmentType: 'BSC_STRATEGY_MAP',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.bscStrategyBoard'),
          mainPrompt: bscStrategyBoard,
          numberOfCharacters: 5000,
          assessmentType: 'BSC_STRATEGY_BOARD',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.strategyDiamond'),
          mainPrompt: strategyDiamond,
          numberOfCharacters: 5000,
          assessmentType: 'STRATEGY_DIAMOND',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.businessCanvas'),
          mainPrompt: businessCanvas,
          numberOfCharacters: 5000,
          assessmentType: 'BUSINESS_CANVAS',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.mckinsey'),
          mainPrompt: mckinsey,
          numberOfCharacters: 5000,
          assessmentType: 'MCKINSEY_7S',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.riskFramework'),
          mainPrompt: riskFramework,
          numberOfCharacters: 5000,
          assessmentType: 'RISK_FRAMEWORKS',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.strategicRisks'),
          mainPrompt: strategicRisks,
          numberOfCharacters: 5000,
          assessmentType: 'STRATEGIC_RISKS',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.objectiveRisks'),
          mainPrompt: objectiveRisks,
          numberOfCharacters: 5000,
          assessmentType: 'OBJECTIVE_RISKS',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.riskMap'),
          mainPrompt: riskMap,
          numberOfCharacters: 5000,
          assessmentType: 'RISK_MAP',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.unsdg'),
          mainPrompt: unsdg,
          numberOfCharacters: 5000,
          assessmentType: 'UNSDG',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.esg'),
          mainPrompt: esg,
          numberOfCharacters: 5000,
          assessmentType: 'ESG',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.objectives'),
          mainPrompt: objectives,
          numberOfCharacters: 5000,
          assessmentType: 'OBJECTIVES',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.frameworks'),
          mainPrompt: frameworks,
          numberOfCharacters: 5000,
          assessmentType: 'FRAMEWORKS',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.taxonomy'),
          mainPrompt: taxonomy,
          numberOfCharacters: 5000,
          assessmentType: 'TAXONOMY',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.csrd'),
          mainPrompt: csrd,
          numberOfCharacters: 5000,
          assessmentType: 'CSRD',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.tcfd'),
          mainPrompt: tcfd,
          numberOfCharacters: 5000,
          assessmentType: 'TCFD',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.sbti'),
          mainPrompt: sbti,
          numberOfCharacters: 5000,
          assessmentType: 'SB_TI',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.gri'),
          mainPrompt: gri,
          numberOfCharacters: 5000,
          assessmentType: 'GRI',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.futureScenario'),
          mainPrompt: twoByTwoScenario,
          numberOfCharacters: 5000,
          assessmentType: 'TWO_BY_TWO',
          category: 'future',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.futureOpportunities'),
          mainPrompt: futureOpportunities,
          numberOfCharacters: 5000,
          assessmentType: 'FUTURE_OPPORTUNITIES',
          category: 'future',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialHealth'),
          mainPrompt: financialHealth,
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_HEALTH',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialBenchmark'),
          mainPrompt: financialBenchmark,
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_BENCHMARK',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.revenue'),
          mainPrompt: revenue,
          numberOfCharacters: 5000,
          assessmentType: 'REVENUE_ANALYSIS',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialGrowth'),
          mainPrompt: financialGrowth,
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_GROWTH',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialRisk'),
          mainPrompt: financialRisk,
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_RISK',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.stratex'),
          mainPrompt: stratex,
          numberOfCharacters: 5000,
          assessmentType: 'STRATEX',
          category: 'financial',
        } satisfies PromptMessage,
      ].filter(Boolean),
    [t],
  );
}
