import { lazy, Suspense } from 'react';

import type { AssessmentImageProps } from './AssessmentImage';

const AssessmentImage = lazy(() => import('./AssessmentImage'));

const LazyAssessmentImage = (props: AssessmentImageProps) => {
  return (
    <Suspense>
      <AssessmentImage {...props} />
    </Suspense>
  );
};

export default LazyAssessmentImage;
