import { forwardRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { contributionRoutesPaths } from 'contribution/contribution.routing.paths';
import type { UserProfile } from 'user/user.type';
import Avatar from 'shared/components/Avatar';
import type { AvatarProps } from 'shared/components/Avatar';
import { useUser } from 'user/UserProvider/useUser';
import Tooltip from 'shared/components/Tooltip';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

export type UserAvatarProps = Omit<AvatarProps, 'children'> & {
  showTooltip?: boolean;
  user?: Maybe<UserProfile>;
};

const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ user, showTooltip = true, onClick, ...restProps }, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { orgTeamSlug } = useTeamSlug();
    const { user: currentUser } = useUser();

    const handleClick = useCallback(() => {
      if (!user?.id) return;

      navigate(
        user.id === currentUser.id
          ? contributionRoutesPaths.root({ params: { teamSlug: orgTeamSlug } })
          : contributionRoutesPaths.user({
              params: { teamSlug: orgTeamSlug, userId: user.id },
            }),
      );
    }, [currentUser.id, navigate, orgTeamSlug, user?.id]);

    const isInOrg = user?.isInActiveOrg === true;

    const tooltip = isInOrg
      ? showTooltip
        ? user?.displayName || user?.email || user?.id
        : undefined
      : t('user.avatar.notInOrg');

    return (
      <Tooltip content={tooltip} delay={0}>
        <Avatar
          ref={ref}
          photoUrl={user?.photoUrl}
          displayName={user?.displayName}
          email={user?.email}
          onClick={onClick || handleClick}
          isDisabled={user?.isActive === false}
          isLinkDisabled={!isInOrg}
          {...restProps}
        />
      </Tooltip>
    );
  },
);

export default UserAvatar;
