import defaults from 'lodash/defaults';

import type { Step } from './Steps.type';

export const createStep = (params: {
  data?: Omit<Partial<Step>, 'id'>;
  id: Step['id'];
}): Step => {
  const { id, data } = params;
  return {
    id,
    ...defaults(data, {
      name: id,
      isCompleted: false,
      isError: false,
      isActive: false,
      isDisabled: false,
      isHidden: false,
      isReady: true,
      isInProgress: false,
      isFirst: false,
      isLast: false,
    }),
  };
};
