import { uniqBy } from 'lodash';

import { testStatusIndicatorAgainstId } from 'shared/status/StatusMultiSelect';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import { testPriorityAgainstId } from 'shared/priority/PriorityMultiSelect';
import type { CalendarCheck } from 'shared/components/CalendarControls/CalendarControls.context';

import type {
  StrategyBoardStatusFilter,
  StrategyBoardUserFilter,
  StrategyBoardObjective,
  StrategyBoardUser,
  StrategyBoardStartDateFilter,
  StrategyBoardEndDateFilter,
  StrategyBoardPriorityFilter,
} from '../StrategyBoard.type';

export const filterThemesByStatusIndicator = (
  objectives: StrategyBoardObjective[],
  filter: StrategyBoardStatusFilter,
): StrategyBoardObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      filter.some((statusMultiSelectItemId) =>
        testStatusIndicatorAgainstId(
          statusMultiSelectItemId,
          objective.currentObjectiveStatus?.statusIndicator,
        ),
      ),
    initiatives: objective.initiatives.filter((initiative) =>
      filter.some((statusMultiSelectItemId) =>
        testStatusIndicatorAgainstId(
          statusMultiSelectItemId,
          initiative.currentInitiativeStatus?.statusIndicator,
        ),
      ),
    ),
    metrics: objective.metrics.filter((metric) =>
      filter.some((statusMultiSelectItemId) =>
        testStatusIndicatorAgainstId(
          statusMultiSelectItemId,
          metric.currentMetricStatus?.statusIndicator,
        ),
      ),
    ),
  }));

export const filterThemesByPriority = (
  objectives: StrategyBoardObjective[],
  filter: StrategyBoardPriorityFilter,
): StrategyBoardObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      filter.some((priorityMultiSelectItemId) =>
        testPriorityAgainstId(priorityMultiSelectItemId, objective.priority),
      ),
    initiatives: objective.initiatives.filter((initiative) =>
      filter.some((priorityMultiSelectItemId) =>
        testPriorityAgainstId(priorityMultiSelectItemId, initiative.priority),
      ),
    ),
    metrics: objective.metrics.filter((metric) =>
      filter.some((priorityMultiSelectItemId) =>
        testPriorityAgainstId(priorityMultiSelectItemId, metric.priority),
      ),
    ),
  }));

export const filterThemesByUser = (
  objectives: StrategyBoardObjective[],
  filter: StrategyBoardUserFilter,
): StrategyBoardObjective[] => {
  if (!filter.length) {
    return objectives;
  }
  return objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      filter.some((value) => value === objective.owner?.id),
    initiatives: objective.initiatives.filter((initiative) =>
      filter.some((value) => value === initiative.owner?.id),
    ),
    metrics: objective.metrics.filter((metric) =>
      filter.some((value) => value === metric.owner?.id),
    ),
  }));
};

export const filterThemesByCollaboratingTeams = (
  objectives: StrategyBoardObjective[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const filterThemesByDates = (
  objectives: StrategyBoardObjective[],
  start: StrategyBoardStartDateFilter,
  end: StrategyBoardEndDateFilter,
  check?: CalendarCheck,
): StrategyBoardObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testStrategyItemAgainstInterval(objective, { start, end }, check),
    initiatives: objective.initiatives.filter((initiative) =>
      testStrategyItemAgainstInterval(initiative, { start, end }, check),
    ),
    metrics: objective.metrics.filter((metric) =>
      testStrategyItemAgainstInterval(metric, { start, end }, check),
    ),
  }));

export const getObjectivesOwners = (
  objectives: StrategyBoardObjective[],
): StrategyBoardUser[] =>
  uniqBy(
    objectives.flatMap((objective) => {
      return [
        objective.owner,
        ...objective.metrics.map((metric) => metric.owner),
        ...objective.initiatives.map((metric) => metric.owner),
      ].filter(Boolean);
    }),
    (user) => user.id,
  );
